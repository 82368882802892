import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { resolveNewestReadingDate } from './utils/resolve-newest-reading-date';
import { resolveOldestReadingDate } from './utils/resolve-oldest-reading-date';

interface ContextValue {
  oldestReadingDate: Date;
  newestReadingDate: Date;
}

const DateRangeTresholdContext = createContext<ContextValue | undefined>(
  undefined
);

interface DateRangeTresholdProviderProps {
  children: ReactNode;
  oldestReadingDate: Date | null;
  newestReadingDate: Date | null;
}

/**
 * Holds values of oldestreadingDate and newestReadingDate that is used to determine date range filter boundaries
 * Those values will be different for user, patient and practice views
 */
export const DateRangeTresholdProvider: React.FC<
  DateRangeTresholdProviderProps
> = ({ children, oldestReadingDate, newestReadingDate }) => {
  const value = useMemo(
    () => ({
      oldestReadingDate: resolveOldestReadingDate(oldestReadingDate),
      newestReadingDate: resolveNewestReadingDate(newestReadingDate),
    }),
    [newestReadingDate, oldestReadingDate]
  );

  return (
    <DateRangeTresholdContext.Provider value={value}>
      {children}
    </DateRangeTresholdContext.Provider>
  );
};

export const useOldestReadingDate = () => {
  const context = useContext(DateRangeTresholdContext);
  if (context === undefined) {
    throw new Error(
      'useOldestReadingDate must be used within a DateRangeTresholdProvider'
    );
  }
  return context.oldestReadingDate;
};

export const useNewestReadingDate = () => {
  const context = useContext(DateRangeTresholdContext);
  if (context === undefined) {
    throw new Error(
      'useNewestReadingDate must be used within a DateRangeTresholdProvider'
    );
  }
  return context.newestReadingDate;
};
