import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

export const Dl = ({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDListElement>, HTMLDListElement>) => (
  <dl className={clsx('grid grid-cols-2', className)} {...props} />
);

export const Dt = ({
  className,
  ...props
}: DetailedHTMLProps<
  HTMLAttributes<HTMLDataListElement>,
  HTMLDataListElement
>) => <dt className={clsx('font-bold text-gray-400', className)} {...props} />;

export const Dd = ({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <dd
    className={clsx('mb-4 text-gray-500 dark:text-gray-300', className)}
    {...props}
  />
);
