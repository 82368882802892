import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Bowser from 'bowser';
import { ToastType, useToast } from 'shared/common/hooks';
import { sessionStorage, SessionStorageKeys } from 'shared/common/utils';

const { HAS_SEEN_UNSUPPORTED_BROWSER_NOTIFICATION } = SessionStorageKeys;

/**
 * Map of recommended browsers with their minimum required versions.
 */
const MIN_BROWSER_VERSION_MAP = {
  // Business requirement is to support Safari 12+
  safari: 12,

  // Versions below are based on "display: grid" support, as this is vital
  // for our app's layout to be displayed correctly.
  chrome: 57,
  firefox: 52,
  edge: 16,
  opera: 44,
};
const { BROWSER_MAP } = Bowser;

export const UnsupportedBrowserNotifier = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();

  React.useEffect(() => {
    // Only show the notification if the user has not seen it before in the current session
    const hasSeenNotification = sessionStorage.getItem(
      HAS_SEEN_UNSUPPORTED_BROWSER_NOTIFICATION
    );
    if (hasSeenNotification) {
      return;
    }

    const browser = Bowser.getParser(window.navigator.userAgent);
    if (isValidBrowser(browser)) {
      return;
    }

    openToast(
      {
        type: ToastType.WARNING,
        label: t(`You are using an unsupported browser.`),
        content: (
          <div className="mt-1 text-sm text-gray-500">
            <p>
              {t(
                'Your browser is not supported and the app might not work properly. Here are some recommended browsers with minimum versions:'
              )}
            </p>
            <ul className="mt-1 list-disc pl-4">
              {Object.entries(MIN_BROWSER_VERSION_MAP).map(
                ([browser, version]) => (
                  <li key={browser}>
                    {BROWSER_MAP[browser]}: {version}
                  </li>
                )
              )}
            </ul>
          </div>
        ),
      },
      {
        // Don't automatically hide the notification
        duration: Number.POSITIVE_INFINITY,
      }
    );

    sessionStorage.setItem(HAS_SEEN_UNSUPPORTED_BROWSER_NOTIFICATION, 'true');
  }, [t, openToast]);

  return null;
};

const REQUIRED_CSS_FEATURES = ['display: grid'];

const isValidBrowser = (browser: Bowser.Parser.Parser) => {
  // Check if required CSS features are supported
  for (const feature of REQUIRED_CSS_FEATURES) {
    if (!CSS.supports(feature)) {
      return false;
    }
  }

  const isSafari = browser.isBrowser('safari');
  if (isSafari) {
    const minSafariVersion = MIN_BROWSER_VERSION_MAP.safari;
    return browser.satisfies({
      safari: `>=${minSafariVersion}`,
    });
  }

  return true;
};
