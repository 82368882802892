import de from 'date-fns/locale/de';
import el from 'date-fns/locale/el';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt';
import sv from 'date-fns/locale/sv';
import tr from 'date-fns/locale/tr';

import { SupportedLanguageCode } from 'consts/supported-language-code';

import { getUserLanguageCode } from '../get-user-language-code/get-user-language-code';

const langCodeToLocaleMap = {
  [SupportedLanguageCode.DE]: de,
  [SupportedLanguageCode.FR]: fr,
  [SupportedLanguageCode.ES]: es,
  [SupportedLanguageCode.PT]: pt,
  [SupportedLanguageCode.EN]: enUS,
  [SupportedLanguageCode.EL]: el,
  [SupportedLanguageCode.PL]: pl,
  [SupportedLanguageCode.TR]: tr,
  [SupportedLanguageCode.SV]: sv,
  [SupportedLanguageCode.IT]: it,
  [SupportedLanguageCode.NL]: nl,
};

export const getUserLocale = () => {
  const userLanguageCode = getUserLanguageCode();
  return langCodeToLocaleMap[userLanguageCode];
};
