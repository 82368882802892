import { cloneElement, FC, isValidElement } from 'react';
import { useModalActions } from 'shared/common/store';
import { ModalId } from 'shared/common/types';

export const ModalOpen: FC<{ id: ModalId }> = ({ children, id }) => {
  const { openModal } = useModalActions();
  if (!isValidElement(children)) {
    return null;
  }
  return cloneElement(children, {
    onClick: () => {
      openModal(id);
      children.props.onClick?.();
    },
  });
};
