import { AxiosError } from 'axios';
import capitalize from 'lodash/capitalize';
import { ErrorResponseDto } from 'shared/common/types';

import { ApiError } from 'models';

/*
  Should convert error from response like this:
  {"description":"Validation failed","errors":{"current_password":["current_password is not valid."]},"type":"validation_failed"}

  To ApiError object like this:
  {
    message: "Validation failed",
    errors: [
      'Current password is not valid.'
    ],
    isRequestError: true
  }

*/
export const errorMapperInterceptor = async (
  error: AxiosError<ErrorResponseDto>
) => {
  // Sometimes when the request is cancelled response does not have headers
  if (!error.response?.headers) {
    throw error;
  }

  if (error.response?.data?.description) {
    const errorResponse = error.response.data as ErrorResponseDto;

    const message = errorResponse.description;
    const isRequestError =
      error.response.status >= 400 && error.response.status < 500;
    let errors: string[] = [];

    if (error.response.data.errors) {
      errors = Object.values(error.response.data.errors)
        .flat()
        .map((errorString) => capitalize(errorString.replace(/_/, ' ')));
    }

    throw new ApiError({
      message,
      errors,
      isRequestError,
      response: error.response,
    });
  }

  throw error;
};
