import { DomainConfig, ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

export const partitionActiveReadingNameConfigs = ({
  configs,
  active,
}: {
  configs: DomainConfig[];
  active: ReadingName[];
}) => {
  // maintain order of active reading names
  const activeConfigs = active.map((readingName) =>
    getDomainConfigs(readingName)
  );
  const inactiveConfigs = configs.filter(
    (config) => !active.includes(config.readingName)
  );

  return [activeConfigs, inactiveConfigs];
};
