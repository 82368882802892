import { useEffect } from 'react';
import { apiHttpInstance, oauthHttpInstance } from 'shared/common/api';
import { useAuthorizedSupportAccessState } from 'shared/common/store';
import { regionsMetadataStore } from 'shared/region-metadata/store';
import { useOAuthScopesQuery } from 'shared/user/queries';

import { insufficientScopeErrorInterceptor } from 'app/interceptors/insufficient-scope-error-interceptor';

import { attachSupportedUserIdInterceptor } from './attach-supported-user-id-interceptor';
import { expiredSessionHandler } from './expired-session-handler';

const httpClients = [oauthHttpInstance, apiHttpInstance];

export const StatefulInterceptors = () => {
  const { supportedUserId } = useAuthorizedSupportAccessState();
  const { data: scopes } = useOAuthScopesQuery(
    regionsMetadataStore.getCurrentRegionMetadata().properties.oauthClientId
  );

  useEffect(() => {
    let responseInterceptors: number[] = [];
    let requestsInterceptors: number[] = [];

    for (const instance of httpClients) {
      responseInterceptors.push(
        instance.interceptors.response.use(
          (res) => res,
          (error) => {
            if (!scopes) throw error;
            return expiredSessionHandler(error, scopes);
          }
        )
      );

      responseInterceptors.push(
        instance.interceptors.response.use(
          (res) => res,
          (error) => {
            if (!scopes) throw error;
            return insufficientScopeErrorInterceptor(error, instance, scopes);
          }
        )
      );

      requestsInterceptors.push(
        instance.interceptors.request.use(
          attachSupportedUserIdInterceptor(supportedUserId)
        )
      );
    }
  }, [scopes, supportedUserId]);

  return null;
};
