import uniq from 'lodash/uniq';
import { Practice, PracticeType } from 'shared/practice/types';

import { getPracticeTypeDependantLabels } from './get-practice-type-dependant-labels';

export const getPracticeTypeDependantLabelsForMultiplePractices = (
  practices: Practice[]
) => {
  const practicesTypes = uniq(
    practices.map((practice) => practice.practiceType)
  );

  if (practicesTypes.length === 1) {
    return getPracticeTypeDependantLabels(practicesTypes[0]);
  }

  return getPracticeTypeDependantLabels(PracticeType.GROUP);
};

export type PracticeTypeDependantLabelsGetters = ReturnType<
  typeof getPracticeTypeDependantLabelsForMultiplePractices
>;
