import { useQuery } from 'react-query';
import { config } from 'shared/common/config';
import { regionStore } from 'shared/common/store';
import { mapToPreferredUnits, mapToRanges } from 'shared/common/utils';

import { userOauthApi } from '../../api';
import { userQueryKeys } from '../../consts';
import { User, UserDto } from '../../types/user';

export const loggedInUserQueryFactory =
  (dependancies: {
    userOauthApi: typeof userOauthApi;
    fallbackTimezone: string;
  }) =>
  ({ enabled }: { enabled?: boolean } = {}) => {
    const { userOauthApi, fallbackTimezone } = dependancies;

    return useQuery(
      userQueryKeys.loggedInUser(),
      () => userOauthApi.getLoggedInUser(),
      {
        select: (dto) => mapToUser(dto, fallbackTimezone),
        enabled,
      }
    );
  };

export const mapToUser = (
  userDto: UserDto,
  fallbackTimezone: string
): User => ({
  id: userDto.user_id,
  email: userDto.email,
  sex: userDto.sex,
  height: userDto.height,
  mobileNumber: userDto.mobile_number,
  addressLine1: userDto.address_line_1,
  addressLine2: userDto.address_line_2,
  city: userDto.city,
  state: userDto.state,
  countryCode: userDto.country_code,
  dateOfBirth: userDto.date_of_birth ? new Date(userDto.date_of_birth) : null,
  fullName:
    userDto.first_name || userDto.last_name
      ? `${userDto.first_name} ${userDto.last_name}`
      : null,
  firstName: userDto.first_name,
  lastName: userDto.last_name,
  oldestReadingDate: userDto.oldest_reading_timestamp
    ? new Date(userDto.oldest_reading_timestamp)
    : null,
  newestReadingDate: userDto.newest_reading_timestamp
    ? new Date(userDto.newest_reading_timestamp)
    : null,
  roles: userDto.roles,
  ranges: mapToRanges(userDto),
  preferredUnits: mapToPreferredUnits(userDto),
  preferredTimezone: userDto.preferred_timezone || fallbackTimezone,
  gkrType: userDto.glucose_ketone_ratio_type,
  is2faActive: userDto.is_2fa_active,
  shopifyEmail: userDto.shopify_email,
  shopifyAccountStatus: userDto.shopify_account_status,
  shopifyEmailVerifiedAt: userDto.shopify_email_verified_at,
  unverifiedShopifyEmail: userDto.unverified_shopify_email,
  shopifyStoreConfig: userDto.shopify_store_config
    ? {
        id: userDto.shopify_store_config.id,
        name: userDto.shopify_store_config.name,
      }
    : null,
});

export const useLoggedInUserQuery = loggedInUserQueryFactory({
  userOauthApi,
  fallbackTimezone: config.getFallbackTimezone(regionStore.getCurrent()),
});
