import { FC } from 'react';
import { useModalActions } from 'shared/common/store';

import Button, { ButtonProps } from 'components/Button/Button';

import { useModalContext } from './shared/modal.context';

export const ModalCancelButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { closeModal } = useModalActions();
  const { id } = useModalContext();

  return (
    <Button
      theme="ghost"
      type="button"
      {...props}
      onClick={() => closeModal(id)}
    >
      {children}
    </Button>
  );
};
