import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';

export const percentifyObject = <T extends Record<string, number>>(
  obj: T,
  precision = 1
) => {
  if (isEmpty(obj)) {
    return {} as T;
  }

  const sum = Object.values(obj).reduce((a, b) => a + b);

  const percentified = Object.entries(obj).reduce(
    (obj, [key, number]) => ({
      ...obj,
      [key]: round((number / sum) * 100, precision),
    }),
    {} as T
  );

  return percentified;
};

export const percentifyNestedObjects = (
  obj: Record<string, Record<string, number> | null | undefined>
) =>
  Object.entries(obj).reduce(
    (obj, [key, nestedObj]) => ({
      ...obj,
      [key]: nestedObj ? percentifyObject(nestedObj) : {},
    }),
    {} as Record<string, Record<string, number>>
  );
