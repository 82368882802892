import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'shared/common/config';
import { getCookie, sleep } from 'shared/common/utils';

import ServerError from 'components/EmptyStates/ServerError/ServerError';
import OverlayLoading from 'components/OverlayLoading/OverlayLoading';
import { CookieStorageKeys } from 'consts/cookie-storage-keys';

import { SyncAppErrors } from './constants';
import { initializeSyncAppIntegration } from './initialize-sync-app-integration';
import { isSyncApp } from './is-sync-app';
import { syncAppHandlers } from './sync-app-handlers';

const notifySyncAppAboutCookieSettingStatus = (isCookieSet: boolean) => {
  if (!isCookieSet) {
    syncAppHandlers.error(SyncAppErrors.COOKIE_NOT_SET);
  }
};
const isAuthCookieSet = (): boolean =>
  !!getCookie(CookieStorageKeys.ACCESS_TOKEN);
/**
 * It allows dashboard to be initialized only after sync app has passed the token
 */
export const SyncAppGuard: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [didSyncAppSetCookie, setDidSyncAppSetCookie] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(isSyncApp());
  useEffect(() => {
    if (!isSyncApp()) {
      return;
    }

    initializeSyncAppIntegration();

    const executor = async () => {
      setIsLoading(true);
      let isCookieSet = false;
      // we wait for x seconds for sync app to set cookie
      const timeoutInSeconds = config.getSyncAppTimeoutTime();
      for (let i = 0; i < timeoutInSeconds; i++) {
        // every second we check whether the cookie has already been set
        await sleep(1000);
        isCookieSet = isAuthCookieSet();
        // if the cookie has been set we don't need to iterate over the loop anymore
        if (isCookieSet) {
          break;
        }
      }
      setDidSyncAppSetCookie(isCookieSet);
      setIsLoading(false);
      notifySyncAppAboutCookieSettingStatus(isCookieSet);
    };
    executor();
  }, []);

  // if not syncApp then we should not care about this guard
  if (!isSyncApp()) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <OverlayLoading heading={t('Loading')} />;
  }

  return didSyncAppSetCookie ? <>{children}</> : <ServerError />;
};
