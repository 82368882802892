import { CookieStorage } from 'cookie-storage';
import { CookieOptions } from 'cookie-storage/lib/cookie-options';

import { CookieStorageKeys } from 'consts/cookie-storage-keys';

const cookieStorage = new CookieStorage();

export const setCookie = (
  key: CookieStorageKeys | string,
  data: string,
  options?: CookieOptions
): void => {
  cookieStorage.setItem(key, data, options);
};

export const getCookie = <T = any>(
  key: CookieStorageKeys | string
): (T extends object ? T : string) | null => {
  const value = cookieStorage.getItem(key);
  if (value == null) {
    return value;
  }
  try {
    return JSON.parse(value);
  } catch {
    // @ts-ignore it will return either a object or a string
    return value;
  }
};

export const removeCookie = (
  key: CookieStorageKeys,
  options?: CookieOptions
): void => {
  cookieStorage.removeItem(key, options);
};
