import { useQuery } from 'react-query';
import { minutesToMilliseconds } from 'date-fns';

import { practiceApi } from '../api';
import { practiceQueryKeys } from '../consts';
import { usePractice } from '../store';
import { mapToPracticeRangeDescriptionsMap } from '../utils';

export const useGlobalRangeDescriptionsQuery = () => {
  const practice = usePractice();

  return useQuery(
    practiceQueryKeys.globalRangeDescriptions(practice?.id),
    () => practiceApi.getGlobalRangeDescriptions({ practiceId: practice?.id }),
    {
      staleTime: minutesToMilliseconds(5),
      select: mapToPracticeRangeDescriptionsMap,
    }
  );
};
