import React from 'react';
import { Toast } from 'react-hot-toast';

export interface IOpenToastProps {
  type: ToastType;
  label: string;
  content?: React.ReactNode;
}

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface IToastTemplateProps extends IOpenToastProps {
  toastInstance: Toast;
  icon: JSX.Element;
}
