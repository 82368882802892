import { addDays, addHours } from 'date-fns';

import { getFixedIntervalConfig } from 'features/selectable-line-charts/utils/get-fixed-interval-config';
import { AggregationFixedInterval } from 'models';

import { formatZonedDateRange } from '../format-date-range/format-date-range';

export const getFixedIntervalDateRange =
  ({
    timezone,
    startDate,
    endDate,
  }: {
    timezone: string;
    startDate: Date;
    endDate: Date;
  }) =>
  (timestamp: string) => {
    const { aggregationFixedInterval, aggregationFixedIntervalAmount } =
      getFixedIntervalConfig({
        fromDate: startDate,
        toDate: endDate,
      });

    const startTimestamp = new Date(timestamp);
    let endTimestamp: Date;

    if (aggregationFixedInterval === AggregationFixedInterval.HOUR) {
      endTimestamp = addHours(startTimestamp, aggregationFixedIntervalAmount);
    } else if (aggregationFixedInterval === AggregationFixedInterval.DAY) {
      endTimestamp = addDays(startTimestamp, aggregationFixedIntervalAmount);
    } else {
      throw new Error('Invalid aggregationFixedInterval');
    }

    return formatZonedDateRange(timezone)({
      startDate: startTimestamp,
      endDate: endTimestamp,
    });
  };
