import { FC } from 'react';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useModalActions } from 'shared/common/store';

import { useModalContext } from './modal.context';

interface ModalCloseButtonProps {
  className?: string;
}

export const ModalCloseButton: FC<ModalCloseButtonProps> = ({ className }) => {
  const { closeModal } = useModalActions();
  const { id } = useModalContext();

  return (
    <button
      className={clsx(
        'float-right rounded-full border-none bg-ketoneBlue-50 p-2 text-sm font-medium outline-none focus:outline-none focus:ring-2 dark:bg-ketoneBlue-900',
        className
      )}
      aria-labelledby="modal-close-btn-label"
      data-testid="modal-close-btn"
      onClick={() => closeModal(id)}
    >
      <span id="modal-close-btn-label" hidden>
        Close
      </span>
      <XIcon
        aria-hidden="true"
        focusable="false"
        className="h-7 text-ketoneBlue dark:text-ketoneBlue-400"
      />
    </button>
  );
};
