import isEqual from 'lodash/isEqual';

import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

export const getIsDropdownOptionSelected = <T>(
  option: DropdownOptionItem<T>,
  selected: T[] | T | null
) =>
  selected
    ? Array.isArray(selected)
      ? selected.findIndex((s) => isEqual(s, option.value)) !== -1
      : isEqual(selected, option.value)
    : false;
