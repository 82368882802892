import { ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';

export const H1 = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'h1'>) => (
  <h1
    className={clsx(
      'text-2xl font-bold leading-8 text-gray-900 dark:text-gray-50 sm:text-3xl sm:leading-9',
      className
    )}
    {...props}
  >
    {children}
  </h1>
);

export const H2 = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'h2'>) => (
  <h2
    className={clsx(
      'text-xl font-bold leading-8 text-gray-900 dark:text-gray-50 sm:text-2xl sm:leading-9',
      className
    )}
    {...props}
  >
    {children}
  </h2>
);

export const H3 = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'h3'>) => (
  <h3
    className={clsx(
      'text-xl font-semibold leading-7 text-gray-500 dark:text-gray-200',
      className
    )}
    {...props}
  >
    {children}
  </h3>
);

export const H4 = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'h3'>) => (
  <h4
    className={clsx(
      'text-lg font-semibold leading-7 text-gray-500 dark:text-gray-200',
      className
    )}
    {...props}
  >
    {children}
  </h4>
);
