import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import OverlayLoading from 'components/OverlayLoading/OverlayLoading';

import { useHcpPracticesQuery } from '../queries/use-hcp-practices-query';
import { Practice } from '../types/practice';

/**
 * Practices where logged in hcp user is a practitioner
 */
const HcpPracticesContext = createContext<Practice[]>([]);

interface HcpPracticesProviderProps {
  children: ReactNode;
  isLoggedInUserHcp: boolean;
  initialState?: Practice[];
}

export const HcpPracticesProvider: React.FC<HcpPracticesProviderProps> = ({
  children,
  isLoggedInUserHcp,
  initialState,
}) => {
  const { data, isLoading } = useHcpPracticesQuery({
    enabled: isLoggedInUserHcp && !initialState,
  });

  const value = useMemo(() => initialState ?? data ?? [], [data, initialState]);

  if (isLoggedInUserHcp && isLoading) {
    return <OverlayLoading />;
  }

  return (
    <HcpPracticesContext.Provider value={value}>
      {children}
    </HcpPracticesContext.Provider>
  );
};

export const useHcpPractices = () => {
  const context = useContext(HcpPracticesContext);
  if (context === undefined) {
    throw new Error(
      'useHcpPractices must be used within a HcpPracticesProvider'
    );
  }
  return context;
};
