import {
  getItem,
  removeItem,
  setItem,
} from 'shared/common/utils/storages/shared-methods';

export enum SessionStorageKeys {
  IS_REFRESHING_SESSION = 'isRefreshingSession',
  AFTER_LOGIN_REDIRECT_ROUTE = 'afterLoginRedirectRoute',
  CODE_VERIFIER = 'CODE_VERIFIER',
  HAS_SEEN_UNSUPPORTED_BROWSER_NOTIFICATION = 'HAS_SEEN_UNSUPPORTED_BROWSER_NOTIFICATION',
  IS_LOG_ROCKET_SESSION_RECORDED = 'isLgSessionRecorded',
  LOG_ROCKET_STATE = 'lgState',
}

export const sessionStorage = {
  getItem: <T = any>(key: SessionStorageKeys): T | null =>
    getItem(window.sessionStorage, key),
  setItem: (key: SessionStorageKeys, value: unknown) =>
    setItem(window.sessionStorage, key, value),
  removeItem: (key: SessionStorageKeys) =>
    removeItem(window.sessionStorage, key),
};
