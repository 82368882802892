export enum Unit {
  MMHG = 'mmHg',
  MMOLL = 'mmoll',
  MGDL = 'mgdl',
  NO_UNIT = '',
  ACE = 'ACE',
  PPM = 'PPM',
  GRAMS = 'g',
  KCAL = 'kcal',
  CM = 'cm',
  INCH = 'inch',
  KG = 'kg',
  LBS = 'lbs',
  IU = 'iu',
  MCG = 'mcg',
  MG = 'mg',
  BPM = 'bpm',
  MS = 'ms',
  KG_M2 = 'kg/m2',
  S = 'second',
  PMOLL = 'pmoll',
  NMOLL = 'nmoll',
  NGDL = 'ngdl',
  MLUL = 'mlul',
  PERCENTAGE = '%',
  MCMOLL = 'mcmoll',
  GL = 'gl',
  MMOL_MOL = 'mmol_mol',
  MCUL = 'mcul',
  MCUML = 'mcuml',
  MCGDL = 'mcgdl',
  PGML = 'pgml',
  MGL = 'mgl',
  UL = 'ul',
  MEQL = 'meql',
  GDL = 'gdl',
  INSULIN = 'IU',
}

export const getUnitsList = () => Object.values(Unit);
