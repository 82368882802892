import { ReadingName, ReadingNameToUnitMap } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

import { NullableRanges } from '../../types';

/**
 * Resolves the preferred and fallback ranges for each reading type based on the provided units.
 * @param {Object} options - The options object.
 * @param {NullableRanges} options.preferredRanges - The preferred ranges for each reading type.
 * @param {NullableRanges} [options.fallbackPracticePreferredRanges] - The fallback preferred ranges for each reading type.
 * @param {ReadingNameToUnitMap} options.units - The units for each reading type.
 * @returns {Record<ReadingName, [number, number]>} - The resolved ranges for each reading type.
 */
export const resolveRanges = ({
  preferredRanges,
  fallbackPracticePreferredRanges,
  units,
}: {
  preferredRanges: NullableRanges;
  fallbackPracticePreferredRanges?: NullableRanges;
  units: ReadingNameToUnitMap;
}) =>
  getDomainConfigs({ shape: 'regularArray' }).reduce((acc, config) => {
    const unit = units[config.readingName];
    const minRange: number =
      // @ts-ignore
      preferredRanges[config.readingName][unit][0] ??
      // @ts-ignore
      fallbackPracticePreferredRanges?.[config.readingName][unit][0] ??
      config.defaultRanges[unit][0];

    const maxRange: number =
      // @ts-ignore
      preferredRanges[config.readingName][unit][1] ??
      // @ts-ignore
      fallbackPracticePreferredRanges?.[config.readingName][unit][1] ??
      config.defaultRanges[unit][1];

    return {
      ...acc,
      [config.readingName]: [minRange, maxRange],
    };
  }, {} as Record<ReadingName, [number, number]>);
