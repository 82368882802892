import { config } from 'shared/common/config';
import { localStorage } from 'shared/common/utils/storages';

import { LocalStorageKey } from 'consts';

let isNewAppVersion = false;

export const updateAppVersionInLocalStorage = () => {
  const cachedAppVersion = localStorage.getItem<string>(
    LocalStorageKey.APP_VERSION
  );

  isNewAppVersion = cachedAppVersion !== config.getAppVersion();

  if (isNewAppVersion) {
    localStorage.setItem(LocalStorageKey.APP_VERSION, config.getAppVersion());
  }
};

export const getIsNewAppVersion = () => isNewAppVersion;
