import { isEmpty } from 'lodash';
import { Ranges } from 'shared/common/store';
import {
  LastReadingStats,
  ReadingsStatsResponseDto,
} from 'shared/reading/types';
import { ValuesType } from 'utility-types';

import { ReadingName } from 'domain/domain.models';
import { AggregationInterval, StatType } from 'models';

import { mapToReading } from '../map-to-reading/map-to-reading';

export const mapToTotalLastReadingStats = ({
  currentStats,
  ranges,
}: {
  currentStats: ValuesType<
    ReadingsStatsResponseDto<
      ReadingName,
      StatType.LAST_READING,
      AggregationInterval.TOTAL
    >
  >;
  ranges: Ranges;
}) =>
  Object.entries(currentStats.stats).reduce((acc, [name, stats]) => {
    if (isEmpty(stats.last_reading.total.total)) return acc;

    const readingName = name as ReadingName;

    const currentStatsLastReading = stats.last_reading.total.total;

    const currentLastReading = mapToReading(currentStatsLastReading!);

    return {
      ...acc,
      [readingName]: {
        readingName,
        currentLastReading,
        ranges: ranges[readingName],
      },
    };
  }, {} as Record<ReadingName, LastReadingStats>);
