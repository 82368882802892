const SYNC_APP_USER_AGENT = 'KetoMojo-SyncApp';
const IOS_SYNC_APP_USER_AGENT = `ios/${SYNC_APP_USER_AGENT}`;
const ANDROID_SYNC_APP_USER_AGENT = `android/${SYNC_APP_USER_AGENT}`;

export const isIosSyncApp = () =>
  navigator.userAgent.includes(IOS_SYNC_APP_USER_AGENT);

export const isAndroidSyncApp = () =>
  navigator.userAgent.includes(ANDROID_SYNC_APP_USER_AGENT);

export const isSyncApp = () =>
  navigator.userAgent.includes(SYNC_APP_USER_AGENT);
