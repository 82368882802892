/* eslint-disable func-style */

import { PracticeRole, UserRole } from './roles';

export const permissionGuards = {
  enableAuthorizedAccess,
  createPractice,
  removePractice,
  leavePracticeAsHcp,
  promoteHcpToAdminRole,
  demoteHcpFromAdminRole,
  transferPracticeOwnership,
  removeHcpFromPractice,
  manageHcpRequests,
  accessHcpViews,
  accessOldDashboardApiPartnerSettings,
  accessOldDashboardAdminPanel,
} as const;

function removePractice({
  userPracticeRoles,
}: {
  userPracticeRoles: PracticeRole[];
}) {
  return isMatchingRoles([PracticeRole.PRACTICE_OWNER], userPracticeRoles);
}

function createPractice<Practice extends { practiceRoles: PracticeRole[] }>({
  userRoles,
  practices,
}: {
  userRoles: UserRole[];
  practices: Practice[];
}) {
  return (
    isMatchingRoles([UserRole.HCP], userRoles) &&
    practices.some((practice) =>
      isMatchingRoles(
        [PracticeRole.PRACTICE_ADMIN, PracticeRole.PRACTICE_OWNER],
        practice.practiceRoles
      )
    )
  );
}

function leavePracticeAsHcp({
  isCurrentUser,
  userPracticeRoles,
}: {
  isCurrentUser: boolean;
  userPracticeRoles: PracticeRole[];
}) {
  return (
    isCurrentUser &&
    !isMatchingRoles([PracticeRole.PRACTICE_OWNER], userPracticeRoles)
  );
}

function promoteHcpToAdminRole({
  isHcpCurrentUser,
  userPracticeRoles,
  hcpPracticeRoles,
}: {
  userPracticeRoles: PracticeRole[];
  hcpPracticeRoles: PracticeRole[];
  isHcpCurrentUser: boolean;
}) {
  return (
    !isHcpCurrentUser &&
    isMatchingRoles(
      [PracticeRole.PRACTICE_ADMIN, PracticeRole.PRACTICE_OWNER],
      userPracticeRoles
    ) &&
    !isMatchingRoles([PracticeRole.PRACTICE_ADMIN], hcpPracticeRoles)
  );
}

function demoteHcpFromAdminRole({
  isHcpCurrentUser,
  userPracticeRoles,
  hcpPracticeRoles,
}: {
  userPracticeRoles: PracticeRole[];
  hcpPracticeRoles: PracticeRole[];
  isHcpCurrentUser: boolean;
}) {
  return (
    !isHcpCurrentUser &&
    isMatchingRoles([PracticeRole.PRACTICE_OWNER], userPracticeRoles) &&
    isMatchingRoles([PracticeRole.PRACTICE_ADMIN], hcpPracticeRoles)
  );
}

function transferPracticeOwnership({
  userPracticeRoles,
  hcpPracticeRoles,
  isHcpCurrentUser,
}: {
  userPracticeRoles: PracticeRole[];
  hcpPracticeRoles: PracticeRole[];
  isHcpCurrentUser: boolean;
}) {
  return (
    !isHcpCurrentUser &&
    isMatchingRoles([PracticeRole.PRACTICE_OWNER], userPracticeRoles) &&
    isMatchingRoles([PracticeRole.PRACTICE_ADMIN], hcpPracticeRoles)
  );
}

function removeHcpFromPractice({
  userPracticeRoles,
  hcpPracticeRoles,
  isHcpCurrentUser,
}: {
  userPracticeRoles: PracticeRole[];
  hcpPracticeRoles: PracticeRole[];
  isHcpCurrentUser: boolean;
}) {
  if (
    isHcpCurrentUser ||
    isMatchingRoles([PracticeRole.PRACTICE_OWNER], hcpPracticeRoles)
  )
    return false;
  if (isMatchingRoles([PracticeRole.PRACTICE_ADMIN], hcpPracticeRoles)) {
    return isMatchingRoles([PracticeRole.PRACTICE_OWNER], userPracticeRoles);
  }
  if (isMatchingRoles([PracticeRole.HCP], hcpPracticeRoles)) {
    return isMatchingRoles(
      [PracticeRole.PRACTICE_OWNER, PracticeRole.PRACTICE_ADMIN],
      userPracticeRoles
    );
  }
}

/**
 * Approve / Reject / Remove / Reinvite HCP Request
 */
function manageHcpRequests({
  userPracticeRoles,
}: {
  userPracticeRoles: PracticeRole[];
}) {
  return isMatchingRoles(
    [PracticeRole.PRACTICE_OWNER, PracticeRole.PRACTICE_ADMIN],
    userPracticeRoles
  );
}

/**
 * Access all routes with hcp content
 */
function accessHcpViews({ userRoles }: { userRoles: UserRole[] }) {
  return isMatchingRoles(
    [UserRole.HCP, UserRole.EHR_PRACTICE_ADMIN, UserRole.ADMIN],
    userRoles
  );
}

/**
 * Access link to api partner settings on old dashboard
 */
function accessOldDashboardApiPartnerSettings({
  userRoles,
}: {
  userRoles: UserRole[];
}) {
  return isMatchingRoles([UserRole.API_PARTNER_ADMIN], userRoles);
}

/**
 * Access link to admin panel on old dashboard
 */
function accessOldDashboardAdminPanel({
  userRoles,
}: {
  userRoles: UserRole[];
}) {
  return isMatchingRoles(
    [UserRole.ADMIN, UserRole.CUSTOMER_SERVICE],
    userRoles
  );
}

function enableAuthorizedAccess({
  roles,
  is2faActive,
}: {
  roles: string[];
  is2faActive: boolean;
}) {
  return (
    isMatchingRoles([UserRole.ADMIN, UserRole.CUSTOMER_SERVICE], roles) &&
    is2faActive
  );
}

function isMatchingRoles(requiredRoles: string[], userRoles: string[]) {
  if (!userRoles) {
    return false;
  }

  if (Array.isArray(requiredRoles)) {
    return requiredRoles.some((role) => userRoles.includes(role));
  }

  return userRoles.includes(requiredRoles);
}
