/**
 * Expreses role user has in the system.
 */
export enum UserRole {
  STANDARD = 'standard',
  HCP = 'hcp',
  API_PARTNER_ADMIN = 'api_partner_admin',
  EHR_PRACTICE_ADMIN = 'ehr_practice_admin',
  CUSTOMER_SERVICE = 'customer_service',
  ADMIN = 'admin',
}

/**
 * Expreses role user has in the context of given practice.
 */
export enum PracticeRole {
  PATIENT = 'patient',
  PRACTICE_ADMIN = 'practice_admin',
  HCP = 'hcp',
  PRACTICE_OWNER = 'practice_owner',
}
