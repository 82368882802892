import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import pick from 'lodash/pick';
import { sessionStorage, SessionStorageKeys } from 'shared/common/utils';

import { LogrocketDebugModeOverlay } from './components/LogrocketDebugModeOverlay';
import { initializeLogRocket } from './initialize-logrocket';

type State = {
  isLogRocketDebugModeEnabled: boolean;
  isLogRocketSessionWithRedactedPII: boolean;
};

const initialState: State = {
  isLogRocketDebugModeEnabled: false,
  isLogRocketSessionWithRedactedPII: true,
  ...(sessionStorage.getItem(SessionStorageKeys.LOG_ROCKET_STATE) ?? {}),
};

type EnableLogRocketDebugModeAction = {
  type: 'ENABLE_LOGROCKET_DEBUG_MODE';
};

type DisableLogRocketDebugModeAction = {
  type: 'DISABLE_LOGROCKET_DEBUG_MODE';
};

type Actions = EnableLogRocketDebugModeAction | DisableLogRocketDebugModeAction;

const reducer = (state: State, action: Actions) => {
  let newState = state;

  if (action.type === 'ENABLE_LOGROCKET_DEBUG_MODE') {
    newState = {
      ...state,
      isLogRocketDebugModeEnabled: true,
      isLogRocketSessionWithRedactedPII: false,
    };
  }

  if (action.type === 'DISABLE_LOGROCKET_DEBUG_MODE') {
    newState = {
      ...state,
      isLogRocketDebugModeEnabled: false,
      isLogRocketSessionWithRedactedPII: true,
    };
  }

  return newState;
};

export const LogrocketContext = createContext<State>(initialState);

export const LogrocketActionsContext = createContext<{
  enableLogRocketDebugMode: () => void;
  disableLogRocketDebugMode: () => void;
}>({ enableLogRocketDebugMode: () => {}, disableLogRocketDebugMode: () => {} });

export const useLogRocketState = () => useContext(LogrocketContext);
export const useLogRocketActions = () => useContext(LogrocketActionsContext);

export const LogrocketContextProvider: FC<{ initialState?: State }> = ({
  children,
  initialState: initialStateFromProps,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...(initialStateFromProps ? initialStateFromProps : {}),
  });
  const prevStateRef = useRef(state);

  const actions = useMemo(
    () => ({
      enableLogRocketDebugMode: () =>
        dispatch({ type: 'ENABLE_LOGROCKET_DEBUG_MODE' }),
      disableLogRocketDebugMode: () => {
        dispatch({ type: 'DISABLE_LOGROCKET_DEBUG_MODE' });
      },
    }),
    []
  );

  useEffect(() => {
    sessionStorage.setItem(
      SessionStorageKeys.LOG_ROCKET_STATE,
      pick(
        state,
        'isLogRocketDebugModeEnabled',
        'isLogRocketSessionWithRedactedPII'
      )
    );
  }, [state]);

  useEffect(() => {
    if (state.isLogRocketDebugModeEnabled) {
      initializeLogRocket({ withRedactedPII: false });
    }
  }, [state]);

  useEffect(() => {
    if (
      prevStateRef.current.isLogRocketDebugModeEnabled === true &&
      state.isLogRocketDebugModeEnabled === false
    ) {
      window.location.reload();
    }

    prevStateRef.current = state;
  }, [state]);

  return (
    <LogrocketContext.Provider value={state}>
      <LogrocketActionsContext.Provider value={actions}>
        {state.isLogRocketDebugModeEnabled ? (
          <LogrocketDebugModeOverlay
            onDisableDebugMode={() => actions.disableLogRocketDebugMode()}
          >
            {children}
          </LogrocketDebugModeOverlay>
        ) : (
          children
        )}
      </LogrocketActionsContext.Provider>
    </LogrocketContext.Provider>
  );
};
