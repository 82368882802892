/* eslint-disable func-style */
import uniq from 'lodash/uniq';
import { assertUnreachable } from 'shared/common/utils';

import {
  DomainConfigRangesIntersection,
  isReadingName,
  RangesPerReadingName,
  ReadingName,
  ReadingNameWithPreferredUnit,
  UnitForReadingName,
} from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';
import {
  isReadingSampleType,
  isReadingType,
  ReadingSampleType,
  ReadingType,
} from 'models';
import { GkrType } from 'models/gkr-type';
import { LogicalMode } from 'models/LogicalMode';
import { ReadingEntryType } from 'models/reading-entry-type';

import { domainConfig, DomainConfigItem } from './domain.config';

export const getDefaultSampleType = (readingType: ReadingType) => {
  switch (readingType) {
    case ReadingType.GLUCOSE:
    case ReadingType.KETONE:
    case ReadingType.GLUCOSE_KETONE_INDEX:
    case ReadingType.DBR:
    case ReadingType.INSULIN_SERUM:
    case ReadingType.HDL:
    case ReadingType.LDL:
    case ReadingType.TRIGLYCERIDES:
    case ReadingType.HBA1C:
    case ReadingType.APOB:
    case ReadingType.APOAL:
    case ReadingType.T3:
    case ReadingType.FREE_T3:
    case ReadingType.T4:
    case ReadingType.FREE_T4:
    case ReadingType.TSH:
    case ReadingType.CARNITINE:
    case ReadingType.HSCRP:
    case ReadingType.ALANINE_AMINOTRANSFERASE:
    case ReadingType.ALBUMIN:
    case ReadingType.ALKALINE_PHOSPHATASE:
    case ReadingType.ASPARTATE_AMINOTRANSFERASE:
    case ReadingType.BICARBONATE:
    case ReadingType.BILIRUBIN:
    case ReadingType.BLOOD_UREA_NITROGEN:
    case ReadingType.CALCIUM:
    case ReadingType.CHLORIDE:
    case ReadingType.CREATININE:
    case ReadingType.SODIUM:
    case ReadingType.TOTAL_PROTEIN:
    case ReadingType.COPPER:
    case ReadingType.TNFA:
    case ReadingType.IL6:
      return ReadingSampleType.BLOOD;
    //   case ReadingType.HOMA_IR:
    //   case ReadingType.POTASSIUM:

    case ReadingType.WEIGHT:
    case ReadingType.WAIST:
    case ReadingType.BMI:
    case ReadingType.HEART_RATE:
    case ReadingType.HEART_RATE_VARIABILITY:
    case ReadingType.BLOOD_PRESSURE_SYSTOLIC:
    case ReadingType.BLOOD_PRESSURE_DIASTOLIC:
    case ReadingType.VISCERAL_FAT_PERCENTAGE:
    case ReadingType.ABSOLUTE_FAT_PERCENTAGE:
    case ReadingType.MUSCLE_MASS_PERCENTAGE:
    case ReadingType.BONE_MASS:
    case ReadingType.FAT_FREE_MASS:
    case ReadingType.HEIGHT:
    case ReadingType.VISCERAL_FAT:
    case ReadingType.ABSOLUTE_FAT:
    case ReadingType.MUSCLE_MASS:
    case ReadingType.SLEEP_SESSION:
      return ReadingSampleType.MEASUREMENT;

    //   case ReadingType.SLEEP_AWAKE:
    //   case ReadingType.SLEEP_DEEP:
    //   case ReadingType.SLEEP_LIGHT:
    //   case ReadingType.SLEEP_REM:

    case ReadingType.PROTEIN:
    case ReadingType.CARBS:
    case ReadingType.FAT:
    case ReadingType.CALORIES:
    case ReadingType.VITAMIN_D:
    case ReadingType.MAGNESIUM:
    case ReadingType.POTASSIUM:
    case ReadingType.OMEGA_3:
    case ReadingType.OMEGA_3_EPA:
    case ReadingType.OMEGA_3_DHA:
    case ReadingType.ZINC:
    case ReadingType.VITAMIN_B12:
    case ReadingType.SELENIUM:
      return ReadingSampleType.INGESTION;

    case ReadingType.INSULIN_BASAL:
    case ReadingType.INSULIN_BOLUS:
      return ReadingSampleType.MDI;

    default:
      return assertUnreachable(readingType);
  }
};

export const getReadingName = (
  type: ReadingType,
  sampleType = getDefaultSampleType(type)
) => {
  const readingName = `${type}__${sampleType}`;
  return readingName as ReadingName;
};

export const findReadingName = (
  readingType: ReadingType,
  readingSampleType: ReadingSampleType
): ReadingName | null => {
  const readingName = getReadingName(readingType, readingSampleType);
  return isReadingName(readingName) ? readingName : null;
};

/**
 @deprecated use getDomainConfigs instead
 @see getDomainConfigs
**/
export function getDomainConfig(name: ReadingName): DomainConfigItem;
export function getDomainConfig(
  type: ReadingType,
  sampleType?: ReadingSampleType
): DomainConfigItem;
export function getDomainConfig(
  ...args: Array<ReadingType | ReadingSampleType | ReadingName | undefined>
): DomainConfigItem {
  if (isReadingName(args[0])) {
    const readingName = args[0];
    return domainConfig[readingName];
  }

  if (
    isReadingType(args[0]) &&
    (isReadingSampleType(args[1]) || args[1] === undefined)
  ) {
    const type = args[0];
    const sampleType = args[1];
    const readingName = getReadingName(type, sampleType);
    const config = domainConfig[readingName];
    if (!config) {
      throw new Error(
        `No domain config found for "${type}:${sampleType}" pair`
      );
    }
    return config;
  }

  throw new Error(`No domain config found for "${args}"`);
}

export const getDomainConfigBySlug = (slug?: string) =>
  getDomainConfigs({
    include: (config) => config.slug === slug,
    shape: 'regularArray',
  })[0] || null;

export const getDefaultRange = <T extends ReadingName>(
  readingName: T,
  unit: UnitForReadingName<T>
) => {
  const config = getDomainConfig(readingName);
  const ranges = config.defaultRanges as DomainConfigRangesIntersection;
  return ranges[unit];
};

export const getReadingRangeForUnit = <T extends ReadingName>(
  ranges: RangesPerReadingName,
  readingName: T,
  unit: UnitForReadingName<T>
) => (ranges[readingName] as DomainConfigRangesIntersection)[unit];

export const getBoundaryRange = <T extends ReadingName>(
  readingName: T,
  unit: UnitForReadingName<T>
) => {
  const config = getDomainConfig(readingName);
  const ranges = config.boundaryRanges as DomainConfigRangesIntersection;
  return ranges[unit];
};

export const isReadingNameWithPreferredUnit = (
  readingName: ReadingName
): readingName is ReadingNameWithPreferredUnit => {
  const { units } = getDomainConfig(readingName);
  return units.length > 1;
};

export function isContinuousReading(reading: ReadingType): boolean;
export function isContinuousReading(reading: ReadingName): boolean;
export function isContinuousReading(reading: any): boolean {
  return getDomainConfig(reading).entryTypes.includes(
    ReadingEntryType.CONTINUOUS
  );
}

export const getReadingTypes = (readingNames: ReadingName | ReadingName[]) => {
  const list = Array.isArray(readingNames) ? readingNames : [readingNames];
  return uniq(
    list.map((readingName) => getDomainConfigs(readingName).readingType)
  );
};

export const getReadingSampleTypes = (
  readingNames: ReadingName | ReadingName[]
) => {
  const list = Array.isArray(readingNames) ? readingNames : [readingNames];
  return uniq(
    list.map((readingName) => getDomainConfigs(readingName).readingSampleType)
  );
};

export const getReadingTagsFilter = ({
  operator,
  tags,
}: {
  operator?: LogicalMode;
  tags: string[];
}) => {
  if (!operator) {
    return {};
  }
  if (operator === LogicalMode.NOT) {
    return { excludeTags: tags };
  }
  return { tagsOperator: operator, tags };
};

export const getAllReadingNames = ({ gkrType }: { gkrType: GkrType }) =>
  getDomainConfigs({
    shape: 'regularArray',
    exclude: [getExcludedGkrReadingName(gkrType)],
  }).map((config) => config.readingName);

export const getExcludedGkrReadingName = (gkrType: GkrType) =>
  gkrType === GkrType.DBR ? ReadingName.GKI_BLOOD : ReadingName.DBR_BLOOD;

export const isGkrReadingType = (type: ReadingType) =>
  type === ReadingType.GLUCOSE_KETONE_INDEX || type === ReadingType.DBR;

export const isGkrReadingName = (name: ReadingName) =>
  [ReadingName.GKI_BLOOD, ReadingName.DBR_BLOOD].includes(name);

export const isBpReadingName = (name: ReadingName) =>
  [
    ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT,
    ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT,
  ].includes(name);
