import { isReadingType } from 'models';

import { isReadingName, ReadingName } from '../../../domain.models';
import { ReadingTypeAndSample } from '../get-domain-configs.types';

export const isReadingNameArray = (item: any): item is ReadingName[] =>
  !!(Array.isArray(item) && item.length && isReadingName(item[0]));

export const isReadingTypeAndSampleArray = (
  item: any
): item is ReadingTypeAndSample[] =>
  !!(
    Array.isArray(item) &&
    item.length &&
    isReadingType((item[0] as ReadingTypeAndSample).type)
  );
