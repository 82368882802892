import React, { createContext, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { routes } from 'shared/common/routes';

import OverlayLoading from 'components/OverlayLoading/OverlayLoading';

import { useLoggedInUserQuery } from '../queries';
import { User } from '../types';

// Define the shape of your context data
type LoggedInUserContextType = User | undefined;

// Create a context with initial values
const LoggedInUserContext = createContext<LoggedInUserContextType | undefined>(
  undefined
);

// Create a provider component to wrap your app
interface UserProviderProps {
  children: (user: User) => ReactNode;
  initialState?: User;
}

export const LoggedInUserProvider: React.FC<UserProviderProps> = ({
  children,
  initialState,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, isSuccess } = useLoggedInUserQuery({
    enabled: !initialState,
  });

  // for testing only
  if (initialState) {
    return (
      <LoggedInUserContext.Provider value={initialState}>
        {children(initialState)}
      </LoggedInUserContext.Provider>
    );
  }

  if (isLoading) {
    return (
      <OverlayLoading
        heading={t('Please wait')}
        subHeading={t('We are loading your profile')}
      />
    );
  }

  if (isError) {
    return <Redirect to={routes.login} />;
  }

  if (isSuccess) {
    return (
      <LoggedInUserContext.Provider value={data}>
        {children(data)}
      </LoggedInUserContext.Provider>
    );
  }

  return null;
};

export const useLoggedInUser = () => {
  const context = useContext(LoggedInUserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
