import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/common/components';
import { ModalId } from 'shared/common/types';

import { ButtonProps } from 'components/Button/Button';

export interface ConfirmationModalProps {
  title: string;
  id: ModalId;
  onConfirm(): void;
  onCancel?(): void;
  description?: ReactNode;
  theme?: ButtonProps['theme'];
  isPendingState?: boolean;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  id,
  description,
  onCancel,
  onConfirm,
  children,
  theme,
  isPendingState,
}) => {
  const { t } = useTranslation();

  return (
    <Modal id={id}>
      <Modal.Header {...{ title, description }} />

      {children && <Modal.Body>{children}</Modal.Body>}

      <Modal.Footer className="flex gap-4">
        <Modal.CancelButton className="flex-1" onClick={onCancel}>
          {t('Cancel')}
        </Modal.CancelButton>
        <Modal.SubmitButton
          className="flex-1"
          onClick={onConfirm}
          {...{ isLoading: isPendingState, theme }}
        >
          {t('Confirm')}
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};
