import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import LogRocket from 'logrocket';
import { config } from 'shared/common/config';

export const initializeLogRocketFactory =
  (deps: {
    logrockerInstance: typeof LogRocket;
    env: string;
    appVersion: string;
    logrocketAppId: string;
  }) =>
  ({
    withRedactedPII = true,
  }: {
    withRedactedPII?: boolean;
  } = {}) => {
    const { logrockerInstance, env, appVersion, logrocketAppId } = deps;

    logrockerInstance.init(logrocketAppId, {
      release: `${env}: ${appVersion}`,
      network: {
        ...(withRedactedPII
          ? {
              requestSanitizer: sanitizeLogrocketNetworkPIIPayload,
              responseSanitizer: sanitizeLogrocketNetworkPIIPayload,
            }
          : {}),
      },
    });
  };

export const initializeLogRocket = initializeLogRocketFactory({
  logrockerInstance: LogRocket,
  env: config.getEnv(),
  appVersion: config.getAppVersion(),
  logrocketAppId: config.getLogRocketAppId(),
});

export const sanitizeLogrocketNetworkPIIPayload = <T extends { body?: string }>(
  payload: T
) => {
  if (!payload.body) {
    return payload;
  }

  const redactedFieldsList = [
    'first_name',
    'last_name',
    'email',
    'mobile_number',
    'address_line_1',
    'address_line_2',
    'country_code',
    'city',
    'state',
    'sex',
    'height',
    'date_of_birth',
    'shopify_email',
    'unverified_shopify_email',
    'notes',
    'tags',
    'subject',
    'message',
  ];

  let result = payload.body;

  try {
    const parsedBody = JSON.parse(payload.body);
    result = redactLogrocketPayload(parsedBody, redactedFieldsList);
  } catch (error) {}

  payload.body = result;

  return payload;
};

// function that recursively changes each value of the specified keys in payload to 'REDACTED'
const redactLogrocketPayload = (payload: any, keys: string[]): any =>
  transform(payload, (result, value, key) => {
    if (keys.includes(String(key))) {
      result[key] = 'REDACTED';
    } else if (isObject(value)) {
      result[key] = redactLogrocketPayload(value, keys);
    } else {
      result[key] = value;
    }
  });
