import { PracticeRole } from 'shared/common/consts';

export const getPracticeRole = <T extends { practiceRoles: PracticeRole[] }>({
  entityWithPracticeRoles,
}: {
  entityWithPracticeRoles: T;
}) => {
  const rolesPrecedence = {
    [PracticeRole.PRACTICE_OWNER]: 1,
    [PracticeRole.PRACTICE_ADMIN]: 2,
    [PracticeRole.HCP]: 3,
    [PracticeRole.PATIENT]: 4,
  };

  const sortedRoles = [...entityWithPracticeRoles.practiceRoles].sort(
    (a, b) => rolesPrecedence[a] - rolesPrecedence[b]
  );
  const role = sortedRoles[0];

  return role;
};
