import { FC } from 'react';
import clsx from 'clsx';

export const PageContentWrapper: FC<{
  roundedTop?: boolean;
  className?: string;
}> = ({ children, roundedTop = false, className }) => (
  <main
    className={clsx(
      'relative space-y-8 rounded-b-3xl bg-white p-4 dark:bg-gray-900 md:p-8',
      roundedTop && 'rounded-t-3xl',
      className
    )}
  >
    {children}
  </main>
);
