import { PreferredUnits } from 'shared/common/types';
import { DeepNonNullable } from 'utility-types';

import { ReadingNameToUnitMap } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

/**
 * Resolves the preferred units for each reading name based on the user's preferred units and the fallback region's preferred units.
 * @param preferredUnits - The user's preferred units.
 * @param fallbackRegionPreferredUnits - The fallback region's preferred units.
 * @returns An object mapping each reading name to its resolved unit.
 */
export const resolveUnits = (
  preferredUnits: PreferredUnits,
  fallbackRegionPreferredUnits: DeepNonNullable<PreferredUnits>
): ReadingNameToUnitMap =>
  getDomainConfigs({ shape: 'regularArray' }).reduce((acc, config) => {
    const unit = Object.keys(preferredUnits).includes(config.readingName)
      ? preferredUnits[config.readingName as keyof PreferredUnits] ??
        fallbackRegionPreferredUnits[config.readingName as keyof PreferredUnits]
      : config.units[0];

    return {
      ...acc,
      [config.readingName]: unit,
    };
  }, {} as ReadingNameToUnitMap);
