export enum ApiErrorType {
  INSUFFICIENT_SCOPE = 'insufficient_scope',
  UNAUTHORIZED_ACCESS = 'unauthorized_access',
  NOT_FOUND = 'not_found',
  API_ERROR = 'api_error',
  VALIDATION_FAILED = 'validation_failed',
}

export interface ErrorResponseDto {
  description: string;
  type: ApiErrorType;
  errors?: Record<string, string[]>;
}
