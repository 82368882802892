import { routes } from 'shared/common/routes';

import { ReadingName } from 'domain/domain.models';
import { getExcludedGkrReadingName } from 'domain/domain.utils';
import { getReadingItemsConfigs } from 'domain/utils/get-reading-items-configs/get-reading-items-configs';
import { GkrType } from 'models/gkr-type';
import { getTranslatedReadingCategory } from 'models/reading-category';

export const getDetailReadingsLinks = ({
  gkrType,
  practiceId,
  userId,
  patientRoute = routes.hcp.practices.practice.patient.detailedReadings
    .readingNameDetails,
}: {
  gkrType: GkrType;
  practiceId?: string;
  userId?: string;
  patientRoute?: (
    practiceId?: string,
    patientId?: string,
    slug?: string
  ) => string;
}) => {
  const filteredConfigs = getReadingItemsConfigs({
    exclude: [
      getExcludedGkrReadingName(gkrType),
      ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT,
      ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT,
    ],
    shape: 'regularArray',
  });

  const getDetailedReadingsLink = userId
    ? (slug: string) => patientRoute(practiceId, userId, slug)
    : (slug: string) => routes.user.detailedReadings.readingNameDetails(slug);

  return filteredConfigs.map((config) => ({
    value: getDetailedReadingsLink(config.slug),
    label: config.label(),
    category: getTranslatedReadingCategory(config.category),
  }));
};
