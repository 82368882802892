export enum DateRangePeriod {
  HOURS_1 = 'HOURS_1',
  HOURS_3 = 'HOURS_3',
  HOURS_6 = 'HOURS_6',
  HOURS_12 = 'HOURS_12',
  DAILY = 'DAILY',
  DAYS_7 = 'DAYS_7',
  DAYS_28 = 'DAYS_28',
  WEEKS_12 = 'WEEKS_12',
  MONTHS_12 = 'MONTHS_12',
  ALL_TIME = 'ALL_TIME',
  CUSTOM = 'CUSTOM',
}

export const StaticDateRangePeriods = [
  DateRangePeriod.HOURS_1,
  DateRangePeriod.HOURS_3,
  DateRangePeriod.HOURS_6,
  DateRangePeriod.HOURS_12,
  DateRangePeriod.DAILY,
  DateRangePeriod.DAYS_7,
  DateRangePeriod.DAYS_28,
  DateRangePeriod.WEEKS_12,
  DateRangePeriod.MONTHS_12,
] as const;

export type StaticDateRangePeriodsType = typeof StaticDateRangePeriods[number];
