import { ComponentProps, ComponentType, FC, Fragment } from 'react';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import clsx from 'clsx';

import styles from './shared/styles.module.scss';

export const MenuItem: FC = ({ children, ...props }) => (
  <HeadlessUIMenu.Item as={Fragment} {...props}>
    {({ active }) => (
      <div className={clsx(styles.option, active && styles.active)}>
        {children}
      </div>
    )}
  </HeadlessUIMenu.Item>
);

type MenuItemWithIconProps = {
  Icon: ComponentType<any>;
  onClick?: () => void;
  classes?: {
    icon?: string;
  };
} & ComponentProps<typeof HeadlessUIMenu.Item>;

export const MenuItemWithIcon: FC<MenuItemWithIconProps> = ({
  Icon,
  children,
  classes,
  ...props
}) => (
  <HeadlessUIMenu.Item as="div" {...props}>
    {({ active }) => (
      <div
        className={clsx(
          styles.option,
          'space-x-1',
          active && styles.active,
          props.disabled && 'cursor-not-allowed opacity-50'
        )}
      >
        <Icon className={clsx('h-3 w-3', classes?.icon)} />
        {children}
      </div>
    )}
  </HeadlessUIMenu.Item>
);
