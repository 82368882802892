/* eslint-disable func-style */

import round from 'lodash/round';

import { ReadingName } from 'domain/domain.models';
import { Unit } from 'models';

type ConvertFn = (v: number) => number;

/**
 * Creates a converter function that accepts either a number or a range.
 */
const convertFactory = (convertFn: ConvertFn) => {
  function convert(range: [number, number]): [number, number];
  function convert(value: number): number;
  function convert(value: any): any {
    if (Array.isArray(value)) {
      return value.map(convertFn);
    }

    return convertFn(value);
  }

  return convert;
};

export const mgdlToMmoll = convertFactory((v) => round(v / 18, 4));
export const mmollToMgdl = convertFactory((v) => round(v * 18, 2));

export const gramsToKcal = convertFactory((v) => round(v * 4, 2));
export const kcalToGrams = convertFactory((v) => round(v / 4, 4));

export const fatGramsToKcal = convertFactory((v) => round(v * 9, 2));
export const fatKcalToGrams = convertFactory((v) => round(v / 9, 4));

export const cmToInch = convertFactory((v) => round(v * 0.393701, 4));
export const inchToCm = convertFactory((v) => round(v / 0.393701, 3));

export const kgToLbs = convertFactory((v) => round(v * 2.20462, 4));
export const lbsToKg = convertFactory((v) => round(v / 2.20462, 3));

export const iuToMcg = convertFactory((v) => round(v / 40, 4));
export const mcgToIu = convertFactory((v) => round(v * 40, 2));

export const gkiToDbr = convertFactory((v) => round(v * 18, 0));
export const dbrToGki = convertFactory((v) => round(v / 18, 1));

export const pmollToMcul = convertFactory((v) => round(v * 6.945, 4));
export const mculToPmoll = convertFactory((v) => round(v / 6.945, 4));

export const ldlHdlMmollToMgdl = convertFactory((v) => round(v * 38.65, 2));
export const ldlHdlMgdlToMmoll = convertFactory((v) => round(v / 38.65, 2));

export const triglyceridesMmollToMgdl = convertFactory((v) =>
  round(v * 88.57, 2)
);
export const triglyceridesMgdlToMmoll = convertFactory((v) =>
  round(v / 88.57, 2)
);

export const percentageToMmolmol = convertFactory((v) =>
  round(10.93 * v - 23.5, 4)
);
export const mmolmolToPercentage = convertFactory((v) =>
  round((v + 23.5) / 10.93, 4)
);

export const mgdlToGl = convertFactory((v) => round(v * 10, 0));
export const glToMgdl = convertFactory((v) => round(v / 10, 0));

export const nmollToNgdl = convertFactory((v) => round(v / 15.4, 3));
export const ngdlToNmoll = convertFactory((v) => round(v * 15.4, 3));

export const pmollToPgml = convertFactory((v) => round(v / 15.4, 3));
export const pgmlToPmoll = convertFactory((v) => round(v * 15.4, 3));

export const nmollToMcgdl = convertFactory((v) => round(v / 12.87, 4));
export const mcgdlToNmoll = convertFactory((v) => round(v * 12.87, 4));

export const pmollToNgdl = convertFactory((v) => round(v / 12.87, 3));
export const ngdlToPmoll = convertFactory((v) => round(v * 12.87, 3));

export const mlulTomcUml = convertFactory((v) => round(v * 1, 3));
export const mcUmltoMlul = convertFactory((v) => round(v / 1, 3));

export const mcmollToMgdl = convertFactory((v) => round(v * 0.0862, 0));
export const mgdlToMcmoll = convertFactory((v) => round(v / 0.0862, 0));

/**
 * Specific converter is used when a specific reading needs its own converter,
 * such as Fat.
 *
 * In other cases - for general conversion between units - multiple readings
 * can use the same converter.
 */
const hasSpecificConverter = (readingName?: ReadingName) => {
  switch (readingName) {
    case ReadingName.FAT_INGESTION:
      return true;

    default:
      return false;
  }
};

const key = (from: Unit, to: Unit, readingName?: ReadingName) =>
  hasSpecificConverter(readingName)
    ? `${from}_${to}_${readingName}`
    : `${from}_${to}`;

const converters = {
  [key(Unit.MMOLL, Unit.MGDL, ReadingName.LDL_BLOOD)]: ldlHdlMmollToMgdl,
  [key(Unit.MGDL, Unit.MMOLL, ReadingName.LDL_BLOOD)]: ldlHdlMgdlToMmoll,

  [key(Unit.MMOLL, Unit.MGDL, ReadingName.HDL_BLOOD)]: ldlHdlMmollToMgdl,
  [key(Unit.MGDL, Unit.MMOLL, ReadingName.HDL_BLOOD)]: ldlHdlMgdlToMmoll,

  [key(Unit.MMOLL, Unit.MGDL, ReadingName.TRIGLYCERIDES_BLOOD)]:
    triglyceridesMmollToMgdl,
  [key(Unit.MGDL, Unit.MMOLL, ReadingName.TRIGLYCERIDES_BLOOD)]:
    triglyceridesMgdlToMmoll,

  [key(Unit.MGDL, Unit.MMOLL)]: mgdlToMmoll,
  [key(Unit.MMOLL, Unit.MGDL)]: mmollToMgdl,

  [key(Unit.GRAMS, Unit.KCAL)]: gramsToKcal,
  [key(Unit.KCAL, Unit.GRAMS)]: kcalToGrams,

  [key(Unit.GRAMS, Unit.KCAL, ReadingName.FAT_INGESTION)]: fatGramsToKcal,
  [key(Unit.KCAL, Unit.GRAMS, ReadingName.FAT_INGESTION)]: fatKcalToGrams,

  [key(Unit.CM, Unit.INCH)]: cmToInch,
  [key(Unit.INCH, Unit.CM)]: inchToCm,

  [key(Unit.KG, Unit.LBS)]: kgToLbs,
  [key(Unit.LBS, Unit.KG)]: lbsToKg,

  [key(Unit.IU, Unit.MCG)]: iuToMcg,
  [key(Unit.MCG, Unit.IU)]: mcgToIu,

  [key(Unit.PMOLL, Unit.MCUL)]: pmollToMcul,
  [key(Unit.MCUL, Unit.PMOLL)]: mculToPmoll,

  [key(Unit.PERCENTAGE, Unit.MMOL_MOL)]: percentageToMmolmol,
  [key(Unit.MMOL_MOL, Unit.PERCENTAGE)]: mmolmolToPercentage,

  [key(Unit.MGDL, Unit.GL)]: mgdlToGl,
  [key(Unit.GL, Unit.MGDL)]: glToMgdl,

  [key(Unit.NMOLL, Unit.NGDL)]: nmollToNgdl,
  [key(Unit.NGDL, Unit.NMOLL)]: ngdlToNmoll,

  [key(Unit.PMOLL, Unit.PGML)]: pmollToPgml,
  [key(Unit.PGML, Unit.PMOLL)]: pgmlToPmoll,

  [key(Unit.NMOLL, Unit.MCGDL)]: nmollToMcgdl,
  [key(Unit.MCGDL, Unit.NMOLL)]: mcgdlToNmoll,

  [key(Unit.PMOLL, Unit.NGDL)]: pmollToNgdl,
  [key(Unit.NGDL, Unit.PMOLL)]: ngdlToPmoll,

  [key(Unit.MLUL, Unit.MCUML)]: mlulTomcUml,
  [key(Unit.MCUML, Unit.MLUL)]: mcUmltoMlul,

  [key(Unit.MCMOLL, Unit.MGDL)]: mcmollToMgdl,
  [key(Unit.MGDL, Unit.MCMOLL)]: mgdlToMcmoll,
};

export const getConverter = (
  unitFrom: Unit,
  unitTo: Unit,
  readingName?: ReadingName
) => {
  const converterKey = key(unitFrom, unitTo, readingName);
  const converter = converters[converterKey];

  if (!converter) {
    let message = `No converter found for ${unitFrom} to ${unitTo}`;
    if (readingName) message += ` for ${readingName}`;
    throw new Error(message);
  }

  return converter;
};
