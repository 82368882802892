export const getItem = <T>(storage: Storage, key: string): T | null => {
  const value = storage.getItem(key);
  if (value === null) {
    return value;
  }
  try {
    const parsed = JSON.parse(value);
    return parsed as T;
  } catch {
    return value as unknown as T;
  }
};

export const setItem = (
  storage: Storage,
  key: string,
  value: unknown
): void => {
  if (value instanceof Object) {
    storage.setItem(key, JSON.stringify(value));
    return;
  }

  storage.setItem(key, String(value));
};

export const removeItem = (storage: Storage, key: string): void => {
  storage.removeItem(key);
};
