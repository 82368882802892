import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToast } from 'shared/common/hooks';
import { localStorage } from 'shared/common/utils';
import { usePatientQuery } from 'shared/patient/queries';
import { Patient } from 'shared/patient/types';
import { useLoggedInUser } from 'shared/user/store';

import { EPIC_MY_CHART_LOGIN_LINK, LocalStorageKey } from 'consts/index';

import { useUserPractices } from '../store';
import { ExternalEhrPartner } from '../types';

/**
 * This hook runs once per app lifecycle for logged in user.
 * It checks wether user is connected to external ehr partner (f.e. epic).
 * If the user is connected and notification was not displayed, proper notification is displayed once.
 * If the user is not connected and notification was displayed (which means user is no longer connected),
 * proper notification is displayed once.
 */
export const useExternalEhrConnectionNotification = () => {
  const { t } = useTranslation();
  const user = useLoggedInUser();
  const practices = useUserPractices();
  const patientQuery = usePatientQuery({
    practiceId: practices[0]?.id,
    patientUserId: user.id,
  });
  const { openToast } = useToast();

  const handleToastNotification = useCallback(
    (userPatientData: Patient) => {
      const isUserConnectedToExternalEhr = !!userPatientData.externalPatientId;
      const isExternalEhrConnectedNotificationAlreadyDisplayed =
        !!localStorage.getItem(
          LocalStorageKey.EXTERNAL_EHR_CONNECTED_NOTIFICATION_DISPLAYED
        );

      if (
        isUserConnectedToExternalEhr &&
        !isExternalEhrConnectedNotificationAlreadyDisplayed
      ) {
        if (!userPatientData.externalEhrPartnerSystem) return;

        const ehrPartnerProps = getEhrPartnerProps(
          userPatientData.externalEhrPartnerSystem
        );

        openToast(
          {
            type: ToastType.INFO,
            label: t('You are connected to external EHR: {{ ehrName }}', {
              ehrName: ehrPartnerProps.label,
            }),
            content: !!ehrPartnerProps.loginLink && (
              <p className="mt-1 text-sm text-gray-500">
                {t('You can visit your external EHR by clicking ')}
                <a
                  href={ehrPartnerProps.loginLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-ketoneBlue underline"
                >
                  {t('here')}
                </a>
              </p>
            ),
          },
          { duration: 100000 }
        );

        localStorage.setItem(
          LocalStorageKey.EXTERNAL_EHR_CONNECTED_NOTIFICATION_DISPLAYED,
          'true'
        );
        return;
      }

      if (
        !isUserConnectedToExternalEhr &&
        isExternalEhrConnectedNotificationAlreadyDisplayed
      ) {
        openToast(
          {
            type: ToastType.INFO,
            label: t('You’ve been disconnected from external EHR'),
          },
          { duration: 10000 }
        );

        localStorage.removeItem(
          LocalStorageKey.EXTERNAL_EHR_CONNECTED_NOTIFICATION_DISPLAYED
        );
      }
    },
    [openToast, t]
  );

  useEffect(() => {
    if (patientQuery.isSuccess) {
      handleToastNotification(patientQuery.data);
    }
  }, [patientQuery, openToast, t, handleToastNotification]);
};

const getEhrPartnerProps = (ehrPartner: ExternalEhrPartner) => {
  switch (ehrPartner) {
    case 'epic':
      return {
        label: 'Epic',
        loginLink: EPIC_MY_CHART_LOGIN_LINK,
      };
    case 'elation':
      return {
        label: 'Elation',
        loginLink: null,
      };
    case 'athenahealth':
      return {
        label: 'Athena Health',
        loginLink: null,
      };
    default:
      return {
        label: '',
        loginLink: null,
      };
  }
};
