import { FC } from 'react';
import { Dialog } from '@headlessui/react';
import clsx from 'clsx';

interface ModalTitleProps {
  className?: string;
}

export const ModalTitle: FC<ModalTitleProps> = ({ children, className }) => (
  <Dialog.Title
    as="h3"
    className={clsx(
      'self-center text-3xl font-semibold leading-8 text-gray-900 dark:text-gray-50',
      className
    )}
  >
    {children}
  </Dialog.Title>
);
