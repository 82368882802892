/* eslint-disable func-style */

import { domainConfig } from '../../domain.config';
import {
  DomainConfig,
  DomainConfigs,
  isReadingName,
  ReadingName,
} from '../../domain.models';

import { excludeConfigs } from './utils/exclude-configs';
import { includeConfigs } from './utils/include-configs';
import { parseConfigs } from './utils/parse-configs';
import {
  CategorizedArrayShape,
  CategorizedDictionaryShape,
  DomainConfigsShape,
  Predicate,
  RegularArrayShape,
  RegularDictionaryShape,
} from './get-domain-configs.types';

export type GetConfigsOptions<S extends DomainConfigsShape> = {
  exclude?: Predicate;
  include?: Predicate;
  shape?: S;
};

export function getDomainConfigs(): DomainConfigs;
export function getDomainConfigs<T extends ReadingName = ReadingName>(
  options: T
): DomainConfig<T>;
export function getDomainConfigs(
  options: GetConfigsOptions<'regularDictionary'>
): RegularDictionaryShape;
export function getDomainConfigs(
  options: GetConfigsOptions<'regularArray'>
): RegularArrayShape;
export function getDomainConfigs(
  options: GetConfigsOptions<'categorizedArray'>
): CategorizedArrayShape;
export function getDomainConfigs(
  options: GetConfigsOptions<'categorizedDictionary'>
): CategorizedDictionaryShape;
export function getDomainConfigs(
  options?: GetConfigsOptions<DomainConfigsShape> | ReadingName
) {
  let configs = { ...domainConfig } as RegularDictionaryShape;

  if (!options) return configs as DomainConfigs;

  if (isReadingName(options)) return domainConfig[options] as DomainConfig;

  configs = excludeConfigs(configs, options.exclude || []);
  configs = includeConfigs(configs, options.include || []);

  return parseConfigs(configs, options.shape || 'regularDictionary');
}
