// Details about stat types: https://keto-mojo.atlassian.net/browse/BACK-698
export enum StatType {
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  STD = 'std',
  LAST_READING = 'last_reading',
  RANGE = 'range',
  TAGS_LIST = 'tags_list',
  SOURCE_TAGS = 'source_tags',
  SUM = 'sum',
}
