import { config } from 'shared/common/config';
import { regionStore } from 'shared/common/store';
import { getVersionedStorageKey, localStorage } from 'shared/common/utils';
import * as yup from 'yup';

import { LocalStorageKey, UserRegion } from 'consts';
import { ReadingName } from 'domain/domain.models';

import { RegionMetadata } from '../types';

let regionsMetadata: RegionMetadata[];

// regions metadata moved temporarily to json files as part of md-1994
// persisting in ls maintained for testing purposes
if (config.getIsProd()) {
  regionsMetadata = require('./regions-metadata-prod.json');
} else {
  regionsMetadata = require('./regions-metadata-qa.json');
}

export const regionsMetadataStore = {
  save: (metadata: RegionMetadata[]) => {
    localStorage.setItem(
      getVersionedStorageKey(LocalStorageKey.REGIONS_METADATA),
      metadata
    );
  },

  getRegionsMetadata: (): RegionMetadata[] | undefined => {
    const cachedMetadata = localStorage.getItem<RegionMetadata[]>(
      getVersionedStorageKey(LocalStorageKey.REGIONS_METADATA)
    );

    return cachedMetadata || regionsMetadata;
  },
  getCurrentRegionMetadata: (): RegionMetadata => {
    const region = regionStore.getCurrent();

    const cachedMetadata = localStorage.getItem<RegionMetadata[]>(
      getVersionedStorageKey(LocalStorageKey.REGIONS_METADATA)
    );

    const regionMetadata = (cachedMetadata || regionsMetadata).find(
      (metadata) => metadata?.code === region
    );

    try {
      return regionMetadataSchema.validateSync(
        regionMetadata
      ) as RegionMetadata;
    } catch {
      throw new Error('Invalid region metadata');
    }
  },
};

const regionMetadataSchema = yup.object().shape({
  name: yup.string().required(),
  code: yup.string().oneOf(Object.values(UserRegion)).required(),
  oldDashboardUrl: yup.string().required(),
  apiUrls: yup.object().shape({
    authService: yup.string().url().required(),
    apiService: yup.string().url().required(),
  }),
  properties: yup.object().shape({
    oauthClientId: yup.string().required(),
  }),
  preferredUnits: yup.object().shape({
    [ReadingName.GLUCOSE_BLOOD]: yup.string().required(),
    [ReadingName.GLUCOSE_INTERSTITIAL]: yup.string().required(),
    [ReadingName.WEIGHT_MEASUREMENT]: yup.string().required(),
    [ReadingName.WAIST_MEASUREMENT]: yup.string().required(),
    [ReadingName.PROTEIN_INGESTION]: yup.string().required(),
    [ReadingName.FAT_INGESTION]: yup.string().required(),
    [ReadingName.CARBS_INGESTION]: yup.string().required(),
    [ReadingName.VITAMIN_D_INGESTION]: yup.string().required(),
  }),
});
