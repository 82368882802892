import React, { createContext, ReactNode, useContext } from 'react';

import { Practice } from '../types/practice';

const PracticeContext = createContext<Practice | null>(null);

interface PracticeProviderProps {
  children: ReactNode;
  practice: Practice | null;
}

export const PracticeProvider: React.FC<PracticeProviderProps> = ({
  children,
  practice,
}) => (
  <PracticeContext.Provider value={practice}>
    {children}
  </PracticeContext.Provider>
);

/**
 * Should be used in contexts where practice may be null, i.e. user dashboard
 */
export const usePractice = () => {
  const context = useContext(PracticeContext);
  if (context === undefined) {
    throw new Error('usePractice must be used within a PracticeProvider');
  }
  return context;
};

/**
 * Should be used in contexts where practice is always defined, i.e. practice / patient detail
 */
export const useDefinedPractice = () => {
  const context = useContext(PracticeContext);
  if (context === undefined) {
    throw new Error('usePractice must be used within a PracticeProvider');
  }

  if (context === null) {
    throw new Error('Practice must be defined');
  }

  return context;
};
