import { DomainConfig } from 'domain/domain.models';
import { readingCategories, ReadingCategory } from 'models/reading-category';

import { DomainConfigsShape } from '../get-domain-configs.types';

export const parseConfigs = <
  T extends Record<string, { category: ReadingCategory }>
>(
  configs: T,
  shape: DomainConfigsShape
) => {
  if (shape === 'regularArray') {
    return Object.values(configs);
  }

  if (shape === 'categorizedArray' && configs) {
    return readingCategories.map((category) => ({
      category,
      configs: (Object.values(configs) as DomainConfig[]).filter(
        (config) => config.category === category
      ),
    }));
  }

  if (shape === 'categorizedDictionary' && configs) {
    return readingCategories.reduce(
      (acc, category) => ({
        ...acc,
        [category]: (Object.values(configs) as DomainConfig[]).filter(
          (config) => config.category === category
        ),
      }),
      {}
    );
  }

  return configs;
};
