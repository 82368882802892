import { routes } from 'shared/common/routes';
import { regionStore } from 'shared/common/store/region.localstorage';

import { UserRegion } from 'consts';

import { saveAuthToken } from '../auth';

interface ITokenData {
  access_token: string;
  expires_in: number;
  scope: string;
  region: UserRegion;
  redirectUrl?: string;
}

const assertProperTokenDataStructure: (
  data: any
) => asserts data is ITokenData = (data) => {
  const typeOfData = typeof data;
  if (typeOfData !== 'object') {
    throw new Error(
      `Expected data type to be object, got ${typeOfData} instead.`
    );
  }

  const checkTypeOfProp = (propName: keyof ITokenData, desiredType: string) => {
    const typeOfProp = typeof data[propName];
    if (typeOfProp !== desiredType) {
      throw new Error(
        `Expected ${propName} to be ${desiredType}, got ${typeOfProp} instead. ${propName}=${data[propName]}`
      );
    }
  };

  checkTypeOfProp('access_token', 'string');
  checkTypeOfProp('expires_in', 'number');
  checkTypeOfProp('scope', 'string');
  checkTypeOfProp('region', 'string');
};

/**
 * This method is used by Sync Apps to transfer their sessions into dashboard.
 */
export const setAccessToken = (tokenData: ITokenData | any): boolean => {
  assertProperTokenDataStructure(tokenData);

  saveAuthToken(tokenData);
  regionStore.save(tokenData.region);

  const redirectUrl = tokenData.redirectUrl || routes.appDashboardRoute;
  window.location.href = redirectUrl;
  return true;
};
