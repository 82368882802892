import { allColors } from './colors-common';

const { brandColors, baseColors, singleValueColors } = allColors;

export const colors = {
  ...allColors,

  // For backward compatibility, because everywhere we
  // use for example like this: `colors.ketoneBlue`
  ...brandColors,
  ...baseColors,
  ...singleValueColors,
};

export enum ReadingTypeColor {
  bloodPressureDiastolic = 'bloodPressureDiastolic',
  bloodPressureSystolic = 'bloodPressureSystolic',
  bmi = 'bmi',
  calories = 'calories',
  carbsBlue = 'carbsBlue',
  fatPink = 'fatPink',
  gkiGrey = 'gkiGrey',
  glucoseBrown = 'glucoseBrown',
  heartRate = 'heartRate',
  heartRateVariability = 'heartRateVariability',
  ketoneBlue = 'ketoneBlue',
  ketoneBreath = 'ketoneBreath',
  ketoneUrine = 'ketoneUrine',
  magnesium = 'magnesium',
  omega3 = 'omega3',
  omega3dha = 'omega3dha',
  omega3epa = 'omega3epa',
  potassium = 'potassium',
  proteinGreen = 'proteinGreen',
  selenium = 'selenium',
  vitaminB12 = 'vitaminB12',
  vitaminD = 'vitaminD',
  waist = 'waist',
  weight = 'weight',
  zinc = 'zinc',
}
