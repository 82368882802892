import React, { createContext, ReactNode, useContext } from 'react';

import { GkrType } from 'models/gkr-type';

const GkrTypeContext = createContext<GkrType | undefined>(undefined);

interface GkrTypeProviderProps {
  children: ReactNode;
  gkrType: GkrType;
}

export const GkrTypeProvider: React.FC<GkrTypeProviderProps> = ({
  children,
  gkrType,
}) => (
  <GkrTypeContext.Provider value={gkrType}>{children}</GkrTypeContext.Provider>
);

export const useGkrType = () => {
  const context = useContext(GkrTypeContext);
  if (context === undefined) {
    throw new Error('useGkrType must be used within a GkrTypeProvider');
  }
  return context;
};
