import { exposeGlobalFunctions } from './expose-global-functions';
import { isSyncApp } from './is-sync-app';

export const initializeSyncAppIntegration = () => {
  if (!isSyncApp()) {
    return;
  }

  exposeGlobalFunctions();
};
