import { useEffect } from 'react';

export const useOnOutsideClick = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  refs: (Node | null)[]
) => {
  useEffect(() => {
    const handleOutsideOptionsClick = (e: MouseEvent) => {
      if (refs.some((ref) => ref?.contains(e.target as Node))) return;
      setOpen(false);
    };

    window.addEventListener('mousedown', handleOutsideOptionsClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideOptionsClick);
    };
  }, [refs, setOpen]);
};
