export const getIsPastStatsQueryDisabled = ({
  currentEnd,
  currentStart,
  pastStart,
  pastEnd,
}: {
  currentStart: Date;
  currentEnd: Date;
  pastStart: Date;
  pastEnd: Date;
}) => {
  if (
    currentStart.getTime() === pastStart.getTime() &&
    currentEnd.getTime() === pastEnd.getTime()
  ) {
    return true;
  }
};
