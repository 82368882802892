import { ReactNode, VFC } from 'react';
import clsx from 'clsx';

import { ModalCloseButton } from './shared/modal-close-button';
import { ModalDescription } from './shared/modal-description';
import { ModalTitle } from './shared/modal-title';

interface ModalHeaderProps {
  title: ReactNode;
  className?: string;
  description?: ReactNode;
}

export const ModalHeader: VFC<ModalHeaderProps> = ({
  className,
  title,
  description,
}) => (
  <header className={clsx('mb-12', className)}>
    <div className="mb-5 flex items-start justify-between">
      <ModalTitle>{title}</ModalTitle>
      <ModalCloseButton className="ml-4" />
    </div>
    {description && <ModalDescription>{description}</ModalDescription>}
  </header>
);
