import {
  ReactNode,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import isEqual from 'lodash/isEqual';
import { getDataPrivateAttr } from 'shared/common/utils';

import { DropdownList } from 'components/forms-v2/shared/components/dropdown-list/DropdownList';
import { DropdownWrapper } from 'components/forms-v2/shared/components/dropdown-wrapper/DropdownWrapper';
import { InputErrorMessage } from 'components/forms-v2/shared/components/input-error-message/InputErrorMessage';
import { InputLabel } from 'components/forms-v2/shared/components/input-label/InputLabel';
import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

import { DropdownButton } from '../../shared/components/dropdown-button/DropdownButton';

import { getSelectLabel } from './utils/get-select-label';

import styles from './Select.module.scss';

type SharedProps<T> = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  'disabled' | 'required' | 'onChange' | 'value'
> & {
  options: DropdownOptionItem<T>[];
  label?: string;
  error?: string;
  'data-testid'?: string;
  'data-private'?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  icon?: IconComponent;
  withSearch?: boolean;
  withCategories?: boolean;
  placeholder?: string;
  className?: string;
  withFocusOnMount?: boolean;
  renderAdditionalListContent?: () => JSX.Element;
  hint?: ReactNode;
  textTooltip?: string;
  maxSelectedCount?: number;
  withSelectAllOption?: boolean;
};

export type SelectProps<T> = SharedProps<T> &
  (
    | {
        onChange: (value: T[]) => void;
        value?: T[] | null;
        isMultiselect: true;
      }
    | {
        onChange: (value: T) => void;
        value?: T | null;
        isMultiselect?: false;
      }
  );

export const Select = <T,>({
  options,
  onChange,
  isDisabled,
  className,
  error,
  isRequired,
  label,
  id,
  value = null,
  withSearch,
  placeholder,
  isMultiselect,
  withCategories,
  withSelectAllOption = true,
  hint,
  textTooltip,
  icon,
  renderAdditionalListContent,
  ...props
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    update: updatePopper,
  } = usePopper(dropdownButtonRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useEffect(() => {
    // update options position when value changes
    updatePopper?.();
  }, [value, updatePopper]);

  useEffect(() => {
    // close options when clicking outside options, input or selected values
    const handleOutsideOptionsClick = (e: MouseEvent) => {
      if (
        dropdownRef.current?.contains(e.target as Node) ||
        dropdownButtonRef.current?.contains(e.target as Node)
      )
        return;

      setIsOpen(false);
    };

    window.addEventListener('mousedown', handleOutsideOptionsClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideOptionsClick);
    };
  });

  const handleToggleSelectAll = () => {
    if (!isMultiselect) return;

    const isAllOptionsSelected =
      value && Array.isArray(value) && value.length === options.length;
    if (isAllOptionsSelected) {
      onChange([]);
    } else {
      onChange(options.map((o) => o.value));
    }
  };

  const handleOptionItemSelect = (selected: T) => {
    if (!isMultiselect && (!value || !Array.isArray(value))) {
      // is single value selected
      onChange(selected);

      setIsOpen(false);

      return;
    }

    if (isMultiselect && !value) {
      // no multiselect values selected yet
      return onChange([selected]);
    }

    if (isMultiselect && value) {
      // some multiselect values aleady selected
      const typedSelected = value as T[];
      const selectedItemIdx = typedSelected.findIndex((s: T) =>
        isEqual(selected, s)
      );

      const newValue =
        selectedItemIdx === -1
          ? [...typedSelected, selected]
          : typedSelected.filter((s) => !isEqual(selected, s));

      onChange(newValue);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') return setIsOpen(false);
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Popover
      className={className}
      data-testid={props['data-testid']}
      onBlur={(e: any) => props.onBlur?.(e)}
    >
      {!!label && (
        <InputLabel
          htmlFor={id}
          text={label}
          textClassname={styles.label}
          isRequired={isRequired}
          data-testid={`${props['data-testid']}__label`}
          textTooltip={textTooltip}
        />
      )}
      {!!hint && <div className={styles.hint}>{hint}</div>}
      <DropdownButton
        ref={dropdownButtonRef}
        onClick={() => setIsOpen((v) => !v)}
        {...getDataPrivateAttr(props['data-private'])}
        data-testid={`${props['data-testid']}__button`}
        {...{
          isMultiselect,
          placeholder,
          value: getSelectLabel(options, value),
          isOpen,
          icon,
          isDisabled,
          id,
          isError: !!error,
        }}
      />
      {isOpen && (
        <Popover.Panel
          ref={setPopperElement}
          className="z-10"
          static
          style={{
            ...popperStyles.popper,
            minWidth: dropdownButtonRef.current?.scrollWidth,
            maxWidth: (dropdownButtonRef.current?.scrollWidth ?? 0) * 2,
          }}
          {...popperAttributes.popper}
        >
          <DropdownWrapper ref={dropdownRef}>
            <DropdownList
              {...{
                isMultiselect,
                placeholder,
                options,
                selected: value,
                withSearch,
                handleOptionItemSelect,
                withCategories,
                withSelectAllOption,
                renderAdditionalListContent,
                withFocusOnMount: props.withFocusOnMount,
                maxSelectedCount: props.maxSelectedCount,
                'data-testid': props['data-testid'],
                'data-private': props['data-private'],
              }}
              onSelect={handleOptionItemSelect}
              onToggleSelectAll={handleToggleSelectAll}
            />
          </DropdownWrapper>
        </Popover.Panel>
      )}
      {error && (
        <InputErrorMessage
          data-testid={`${props['data-testid']}-error`}
          className={styles.errorMessage}
        >
          {error}
        </InputErrorMessage>
      )}
    </Popover>
  );
};
