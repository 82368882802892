import { CSSProperties, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './DropdownWrapper.module.scss';

export const DropdownWrapper = forwardRef<
  HTMLDivElement,
  {
    children?: ReactNode;
    className?: string;
    'data-testid'?: string;
    style?: CSSProperties;
  }
>(({ children, className, style, ...props }, ref) => (
  <div
    ref={ref}
    data-testid={props['data-testid']}
    className={clsx(styles.wrapper, className)}
    tabIndex={0}
    style={style}
  >
    {children}
  </div>
));
