/* eslint-disable func-style */
import { config } from 'shared/common/config';

import {
  CookieStorageKeys,
  VersionedCookieStorageKey,
} from 'consts/cookie-storage-keys';
import {
  LocalStorageKey,
  VersionedLocalStorageKey,
} from 'consts/local-storage-key';

export function getVersionedStorageKey(
  key: LocalStorageKey,
  variant?: string
): VersionedLocalStorageKey;
export function getVersionedStorageKey(
  key: CookieStorageKeys,
  variant?: string
): VersionedCookieStorageKey;
export function getVersionedStorageKey(
  key: LocalStorageKey | CookieStorageKeys,
  variant?: string
): VersionedLocalStorageKey | VersionedCookieStorageKey {
  if (key in LocalStorageKey) {
    return `${key}_${
      variant ?? ''
    }_${config.getAppVersion()}` as VersionedLocalStorageKey;
  } else {
    return `${key}_${
      variant ?? ''
    }_${config.getAppVersion()}` as VersionedCookieStorageKey;
  }
}
