import { Link } from 'react-router-dom';
import { regionsMetadataStore } from 'shared/region-metadata/store';
import { useLoggedInUser } from 'shared/user/store';

import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';
import { getBottomMenuItems } from 'features/navbar/components/TopNav/utils/get-bottom-menu-items';

interface AppFooterNavigationLinksProps {
  linkClassName?: string;
}

export const AppFooterNavigationLinks = ({
  linkClassName,
}: AppFooterNavigationLinksProps) => {
  const user = useLoggedInUser();
  const regionMetadata = regionsMetadataStore.getCurrentRegionMetadata();

  const options = getBottomMenuItems({
    userRoles: user.roles,
    oldDashboardUrl: regionMetadata.oldDashboardUrl,
  });

  const renderLinks = (option: DropdownOptionItem<string>) => {
    const [linkType, link] = option.value.split(' ');

    if (linkType === 'internalLink') {
      return <Link to={link}>{option.label}</Link>;
    }

    if (linkType === 'externalLink') {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {option.label}
        </a>
      );
    }
  };

  return (
    <>
      {options.map((option) => (
        <li key={option.value} className={linkClassName}>
          {renderLinks(option)}
        </li>
      ))}
    </>
  );
};
