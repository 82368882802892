import { FC } from 'react';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { useMenu } from '../shared/menu.context';

import styles from './menu-trigger.module.scss';

interface MenuTriggerProps {
  className?: string;
  disabled?: boolean;
}

export const MenuTrigger: FC<MenuTriggerProps> = ({
  children,
  className,
  disabled = false,
}) => {
  const { isOpen } = useMenu();

  return (
    <HeadlessUIMenu.Button
      disabled={disabled}
      className={clsx(styles.button, className)}
    >
      <span className={styles.content}>{children}</span>
      <span className={styles.arrowsWrapper}>
        <ChevronDownIcon
          aria-hidden="true"
          className={clsx(isOpen && 'rotate-180')}
        />
      </span>
    </HeadlessUIMenu.Button>
  );
};
