export const partnerQueryKeys = {
  all: ['partners'] as const,
  lists: () => [...partnerQueryKeys.all, 'list'] as const,
  list: (filters?: object) =>
    [...partnerQueryKeys.lists(), { filters }] as const,
  details: () => [...partnerQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...partnerQueryKeys.details(), id] as const,
};

export const partnersConnectionStatusQueryKeys = {
  all: ['partners-connection-status'] as const,
  lists: () => [...partnersConnectionStatusQueryKeys.all, 'list'] as const,
  list: (filters?: object) =>
    [...partnersConnectionStatusQueryKeys.lists(), { filters }] as const,
  details: () => [...partnersConnectionStatusQueryKeys.all, 'detail'] as const,
  detail: (id: string) =>
    [...partnersConnectionStatusQueryKeys.details(), id] as const,
};
