import i18next from 'i18next';

export enum PracticeReportType {
  TIME_IN_RANGE = 'time-in-range',
  AVERAGE_VALUES = 'average-values',
  LAST_READINGS = 'last-readings',
}

export const getPracticeReportTypeLabel = (type: PracticeReportType) => {
  const map = {
    [PracticeReportType.TIME_IN_RANGE]: i18next.t('Time in Range'),
    [PracticeReportType.AVERAGE_VALUES]: i18next.t('Averages'),
    [PracticeReportType.LAST_READINGS]: i18next.t('Last Readings'),
  };
  return map[type];
};
