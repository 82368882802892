import { TimeUnit } from 'chart.js';
import { differenceInDays } from 'date-fns';

export const getLineChartTimeUnit = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): TimeUnit => {
  const diff = differenceInDays(endDate, startDate);

  if (diff <= 7) {
    return 'hour';
  }

  if (diff <= 31) {
    return 'day';
  }

  if (diff <= 12 * 7 + 1) {
    return 'week';
  }

  if (diff <= 366) {
    return 'month';
  }

  if (diff <= 2 * 366) {
    return 'quarter';
  }

  return 'year';
};
