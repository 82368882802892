import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

export const ChatUnreadIndicator = ({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) => (
  <span
    className={clsx(
      'inline-block h-2.5 w-2.5 rounded-full bg-red-600',
      className
    )}
    {...props}
  />
);
