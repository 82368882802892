import { READINGS_END_DATE_THRESHOLD } from 'consts';

export const resolveNewestReadingDateFactory =
  (deps: { readingsEndDateTreshold: Date }) =>
  (newestReadingDate: Date | null): Date => {
    if (newestReadingDate && newestReadingDate <= new Date()) {
      return new Date();
    } else if (
      newestReadingDate &&
      newestReadingDate < deps.readingsEndDateTreshold
    ) {
      return newestReadingDate;
    } else {
      return deps.readingsEndDateTreshold;
    }
  };

export const resolveNewestReadingDate = resolveNewestReadingDateFactory({
  readingsEndDateTreshold: READINGS_END_DATE_THRESHOLD,
});
