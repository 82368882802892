import round from 'lodash/round';

export const calcTrendPercentageChange = ({
  current,
  previous,
}: {
  current: number;
  previous?: number;
}) => {
  // It's not possible to calculate the change, when there is no previous value
  if (previous === undefined) {
    return null;
  }

  // We can't calculate the change, when previous value was zero, but we treat
  // such case as NO change
  if (previous === 0) {
    return 0;
  }

  return round(current / previous - 1, 2);
};
