import { Practice } from 'shared/practice/types';

export const formatPracticeAddress = (
  practice: Pick<
    Practice,
    'street' | 'streetNumber' | 'town' | 'state' | 'country' | 'zipCode'
  >
) => {
  const address = [
    practice.street,
    practice.streetNumber,
    practice.town,
    practice.zipCode,
    practice.state,
    practice.country,
  ]
    .filter(Boolean)
    .join(', ');

  return address.trim() ? address : '-';
};
