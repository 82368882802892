import clsx from 'clsx';

import { colors } from 'consts';

const sizes = {
  4: 'w-4 h-4',
  5: 'w-5 h-5',
  8: 'w-8 h-8',
  12: 'w-12 h-12',
};

interface IProps {
  size?: keyof typeof sizes;
  className?: string;
}

const Spinner = ({ className, size = 8 }: IProps) => (
  <div
    className={clsx(
      'animate-spin rounded-full border-4 border-gray-200',
      sizes[size],
      className
    )}
    style={{ borderTopColor: colors.ketoneBlue[500] }}
  />
);

export default Spinner;
