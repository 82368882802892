import { get } from 'lodash';
import {
  NullableRanges,
  PreferredUnits,
  PreferredUnitsDto,
  RangesDto,
} from 'shared/common/types';
import { ExternalEhrPartner } from 'shared/practice/types/practice';

interface PatientBaseDto {
  ehr_patient_id: string;
  ehr_practice: {
    ehr_system: {
      ehr_partner_system: ExternalEhrPartner;
    };
  };
}

export enum PatientSource {
  PATIENT_LIST = 'patient_list',
  PATIENT_REQUEST_LIST = 'patient_request_list',
}

// shape returned to user (when user requests patients of practice he's patient himself in)
export interface UserPatientDto extends PatientBaseDto {
  user: {
    user_id: string;
    first_name: string;
    last_name: string;
    oldest_reading_timestamp: Nullable<string>;
    newest_reading_timestamp: Nullable<string>;
    roles: string[];
    preferred_timezone: Nullable<string>;
  } & PreferredUnitsDto &
    RangesDto;
}

// shape returned to hcp user (when hcp requests patients of his practice)
export interface HcpPatientDto extends PatientBaseDto {
  external_patient_id: Nullable<string>;
  ehr_patient_request: {
    id: string;
  };
  patient_confirmation_obtained: boolean;
  send_data_to_external_ehr: boolean;
  patient_status: PatientStatusDto;
  patient_source: PatientSource;
  notes: string;
  tags: string[];
  display_in_shared_practice: boolean;
  user: {
    user_id: string;
    first_name: string;
    last_name: string;
    email: string;
    oldest_reading_timestamp: Nullable<string>;
    newest_reading_timestamp: Nullable<string>;
    roles: string[];
    preferred_timezone: Nullable<string>;
    date_of_birth: Nullable<string>;
    address_line_1: string | null;
    address_line_2: string | null;
    city: string | null;
    country_code: string | null;
    height: string | null;
  } & PreferredUnitsDto &
    RangesDto;
}

export const isPatientDto = (
  patient: PatientOrRequestDto
): patient is PatientDto => patient.hasOwnProperty('ehr_patient_id');

export const isHcpPatientOrRequestDto = (
  patient: PatientOrRequestDto
): patient is HcpPatientDto | PatientRequestDto =>
  patient.hasOwnProperty('ehr_patient_request');

export const isHcpPatientDto = (
  patient: PatientOrRequestDto
): patient is HcpPatientDto =>
  patient.hasOwnProperty('ehr_patient_request') &&
  get(patient, 'patient_source') === PatientSource.PATIENT_LIST;

export type PatientDto = UserPatientDto | HcpPatientDto;
export type PatientOrRequestDto = PatientDto | PatientRequestDto;

export interface PatientRequestDto {
  ehr_patient_request: {
    id: string;
  };
  ehr_practice: unknown;
  patient_status: PatientStatusDto;
  patient_source: PatientSource;
  user: {
    user_id: string;
    date_of_birth: Nullable<string>;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export enum PatientStatus {
  APPROVED = 'approved',
  INVITED = 'invited',
  PENDING_APPROVAL = 'pending_approval',
  REJECTED = 'rejected',
  INVITATION_REJECTED = 'invitation_rejected',
  EXPIRED = 'expired',
  EHR_CONNECTED = 'ehr_connected',
}

type PatientStatusDto =
  | PatientStatus.APPROVED
  | PatientStatus.REJECTED
  | PatientStatus.EXPIRED
  | PatientStatus.INVITED
  | PatientStatus.INVITATION_REJECTED
  | PatientStatus.PENDING_APPROVAL;

export type PatientBase = {
  type: 'patient';
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  notes: string;
  tags: string[];
  dateOfBirth: Nullable<Date>;
  status: PatientStatus;
  isPatientCurrentUser: boolean;
  displayInSharedPractice: boolean;
  sendDataToExternalEhr: boolean;
  externalPatientId: Nullable<string>;
  ranges: NullableRanges;
  preferredUnits: PreferredUnits;
  oldestReadingDate: Nullable<Date>;
  newestReadingDate: Nullable<Date>;
  preferredTimezone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryCode: string;
  height: string;
  externalEhrPartnerSystem: Nullable<ExternalEhrPartner>;
};

export type Patient = Omit<
  PatientBase,
  'id' | 'type' | 'isPatientCurrentUser' | 'sendDataToExternalEhr'
>;

export type PatientRequestBase = Pick<
  PatientBase,
  'id' | 'fullName' | 'email' | 'dateOfBirth' | 'status' | 'preferredTimezone'
> & {
  type: 'patientRequest';
  userId: string;
};

export type InvitePatientsDto = {
  action: 'invite';
  data: {
    patients: {
      user: {
        email: string;
        first_name?: string;
        last_name?: string;
      };
      external_patient_id?: string;
    }[];
  };
};
