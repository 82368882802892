import React, { FC, Fragment, ReactElement, ReactNode, useState } from 'react';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import clsx from 'clsx';
import { MIN_MEDIA_QUERIES, useWatchMediaQuery } from 'shared/common/hooks';

import { DesktopMenuItems } from '../shared/desktop-menu-items';
import { MenuProvider } from '../shared/menu.context';
import { MobileMenuItems } from '../shared/mobile-menu-items';

import { getCategorizedMenuItems } from './categorized-menu.utils';

import styles from '../shared/styles.module.scss';

interface CategorizedMenuProps {
  trigger: React.ReactNode;
  children: ReactElement<
    {
      category: string;
      children: ReactNode;
    },
    typeof CategorizedMenuItem
  >[];
  classes?: {
    menu?: string;
    items?: string;
  };
  dataTestId?: string;
}

export const CategorizedMenu: FC<CategorizedMenuProps> = ({
  children,
  trigger,
  classes,
  dataTestId,
}) => {
  const isMobile = !useWatchMediaQuery(MIN_MEDIA_QUERIES.MD);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );

  const categorizedMenuItems = getCategorizedMenuItems(children);

  const items = Object.entries(categorizedMenuItems).map(
    ([category, elements]) => (
      <Fragment key={category}>
        <h5 className={styles.categoryHeading}>{category}</h5>
        {elements}
      </Fragment>
    )
  );

  return (
    <HeadlessUIMenu
      as="div"
      className={clsx(styles.menu, classes?.menu)}
      data-testid={dataTestId}
    >
      {({ open, close }) => (
        <MenuProvider isOpen={open}>
          <div ref={setTargetElement}>{trigger}</div>
          {isMobile ? (
            <MobileMenuItems {...{ close, isOpen: open, classes }}>
              {items}
            </MobileMenuItems>
          ) : (
            <DesktopMenuItems {...{ classes, targetElement }}>
              {items}
            </DesktopMenuItems>
          )}
        </MenuProvider>
      )}
    </HeadlessUIMenu>
  );
};

export const CategorizedMenuItem: FC<{
  category: string;
  onClick: () => void;
  className?: string;
}> = ({ children, onClick, className }) => (
  <HeadlessUIMenu.Item as={Fragment}>
    {({ active }) => (
      <div
        onClick={onClick}
        className={clsx(styles.option, className, active && styles.active)}
      >
        {children}
      </div>
    )}
  </HeadlessUIMenu.Item>
);
