import { format as formatDateFns } from 'date-fns';

import { getUserLocale } from '../get-user-locale/get-user-locale';

export enum DateFormat {
  LOCALIZED_DATE = 'PP',
  LOCALIZED_DATE_TIME = 'PP p',
  LOCALIZED_TIME = 'p',
  MONTH_YEAR = 'MMMM yyyy',
  MONTH = 'MMMM',
  WEEK_YEAR = "'W'I y",
  YEAR = 'yyyy',
  ISO = "yyyy-MM-dd'T'HH:mm:ssXXX",
}

export const WEEK_START_DAY = 1;

/**
 * This util should be used to format any date, that is not timezone dependant.
 * It takes into account used locale for formatting
 */
export const formatDate = (
  date: Date | number,
  format: string = DateFormat.LOCALIZED_DATE,
  options?: DateFnsFormatOptions
) => {
  const locale = getUserLocale();
  return formatDateFns(date, format, {
    locale,
    weekStartsOn: WEEK_START_DAY,
    ...options,
  });
};

type DateFnsFormatOptions = Parameters<typeof formatDateFns>[2];
