import { ReadingName } from 'domain/domain.models';

import { TrendChangeState, TrendType } from '../../../types';
import { getTrendChangeState } from '../get-trend-change-state/get-trend-change-state';

/**
 * Calculate trend type for values other than time in range
 */
export const getTrendType = ({
  currentValue,
  previousValue,
  range,
}: {
  readingName: ReadingName;
  currentValue: number;
  previousValue?: number;
  range: [number, number];
}): TrendType => {
  if (previousValue === undefined) {
    return TrendType.NO_PREVIOUS_DATA;
  }

  const trendChangeState = getTrendChangeState({
    currentValue,
    previousValue,
    range,
  });

  if (trendChangeState === TrendChangeState.REMAINS_WITHIN_RANGE) {
    return TrendType.NEUTRAL;
  } else if (
    trendChangeState === TrendChangeState.MOVES_INTO_RANGE ||
    trendChangeState === TrendChangeState.MOVES_TOWARDS_RANGE
  ) {
    return TrendType.POSITIVE;
  } else if (
    trendChangeState === TrendChangeState.MOVES_OUTSIDE_RANGE ||
    trendChangeState === TrendChangeState.MOVES_FURTHER_AWAY_FROM_RANGE ||
    trendChangeState === TrendChangeState.REMAINS_OUTSIDE_RANGE
  ) {
    return TrendType.NEGATIVE;
  }

  throw new Error('Unexpected trend state');
};
