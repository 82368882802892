import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { useOnOutsideClick } from 'shared/common/hooks';

import styles from './popover.module.scss';

interface PopoverProps {
  renderTrigger: ({
    open,
    ref,
  }: {
    open: () => void;
    ref: RefObject<any>;
  }) => React.ReactNode;
  children:
    | React.ReactNode
    | (({ open }: { open: () => void }) => React.ReactNode);
  popperOptions?: Parameters<typeof usePopper>[2];
  popoverContainerClasses?: string;
  ['data-testid']?: string;
}
export const Popover: FC<PopoverProps> = ({
  children,
  renderTrigger,
  popoverContainerClasses,
  popperOptions,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipContainer] = useState(() => document.createElement('div'));

  const triggerRef = useRef<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  useOnOutsideClick(setIsOpen, [triggerRef.current, popperElement]);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    triggerRef.current,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
      ...(popperOptions ? popperOptions : {}),
    }
  );

  useEffect(() => {
    document.body.appendChild(tooltipContainer);
    return () => {
      document.body.removeChild(tooltipContainer);
    };
  }, [tooltipContainer]);

  return (
    <>
      {renderTrigger({ open: () => setIsOpen((v) => !v), ref: triggerRef })}
      {isOpen &&
        createPortal(
          <div
            ref={setPopperElement}
            className={clsx(styles.wrapper, popoverContainerClasses)}
            style={{
              ...popperStyles.popper,
            }}
            {...popperAttributes.popper}
            data-testid={props['data-testid']}
          >
            {typeof children === 'function'
              ? children({
                  open: () => setIsOpen((v) => !v),
                })
              : children}
          </div>,
          tooltipContainer
        )}
    </>
  );
};
