import { routes } from 'shared/common/routes';

import { SearchParams } from 'consts/search-params';

export const redirectToAuthorizedSupportAccessRoute = (
  supportedUserId: string
) => {
  const currentOrigin = window.location.origin;
  const newUrl = `${currentOrigin}${
    routes.authorizedSupportAccessLanding
  }?${new URLSearchParams({
    [SearchParams.USER_ID]: supportedUserId,
  }).toString()}`;
  window.location.href = newUrl;
};
