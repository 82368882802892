import React, { createContext, ReactNode, useContext } from 'react';

import { ReadingName } from 'domain/domain.models';

const RangesContext = createContext<Ranges | undefined>(undefined);

export type Ranges = Record<ReadingName, [number, number]>;
interface RangesProviderProps {
  children: ReactNode;
  ranges: Ranges;
}

export const RangesProvider: React.FC<RangesProviderProps> = ({
  children,
  ranges,
}) => (
  <RangesContext.Provider value={ranges}>{children}</RangesContext.Provider>
);

export const useRanges = () => {
  const context = useContext(RangesContext);
  if (context === undefined) {
    throw new Error('useRanges must be used within a RangesProvider');
  }
  return context;
};
