import { getTimeDifferenceInDays } from 'shared/common/utils';

import { AggregationInterval } from 'models';

/**
 * Calculates the appropriate time aggregation interval based on the end date and duration.
 * @param endDate - The end date of the time range.
 * @param duration - The duration of the time range.
 * @returns The time aggregation interval.
 */
export const getTimeAggregationInterval = (
  endDate: Date,
  duration: Duration
) => {
  const diffInDays = getTimeDifferenceInDays(endDate, duration);
  if (diffInDays <= 31) {
    return AggregationInterval.DAY;
  }
  // weekly aggregation up until 12 weeks
  if (diffInDays <= 12 * 7) {
    return AggregationInterval.WEEK;
    // monthly aggregation up until 2 years
  } else if (diffInDays <= 2 * 365) {
    return AggregationInterval.MONTH;
  } else {
    // above 2 years - yearly aggregation
    return AggregationInterval.YEAR;
  }
};

export type TimeAggregationInterval = ReturnType<
  typeof getTimeAggregationInterval
>;
