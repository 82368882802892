import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useScrollRestorer = () => {
  const { pathname, hash } = useLocation();
  const { action } = useHistory();
  useEffect(() => {
    if (hash || action !== 'PUSH') {
      return;
    }
    window.scrollTo(0, 0);
  }, [hash, action, pathname]);
};
