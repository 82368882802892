import { add, sub } from 'date-fns';
import { DateRangeFilterState } from 'shared/common/store';
import { DateRangePeriod } from 'shared/common/types';

import { maxOutDate, zeroOutDate } from '../date-utils';

export const getPastTimeRange = (
  timeRangeFilterState: DateRangeFilterState
) => {
  const { startDate, endDate, period, duration } = timeRangeFilterState;

  let pastStartDate = startDate;
  let pastEndDate = endDate;

  if (period === DateRangePeriod.ALL_TIME) {
    return { pastStartDate, pastEndDate };
  }

  if (
    period === DateRangePeriod.HOURS_1 ||
    period === DateRangePeriod.HOURS_3 ||
    period === DateRangePeriod.HOURS_6 ||
    period === DateRangePeriod.HOURS_12 ||
    period === DateRangePeriod.CUSTOM
  ) {
    pastEndDate = sub(startDate, { minutes: 1 });
    pastStartDate = sub(add(pastEndDate, { minutes: 1 }), duration);
  } else {
    pastEndDate = maxOutDate(sub(startDate, { days: 1 }));
    pastStartDate = zeroOutDate(sub(pastEndDate, duration));
  }

  return { pastStartDate, pastEndDate };
};
