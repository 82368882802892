import { FC } from 'react';
import clsx from 'clsx';

import { InputMessage, InputMessageProps } from '../input-message/InputMessage';

import styles from './InputErrorMessage.module.scss';

export const InputErrorMessage: FC<InputMessageProps> = ({
  children,
  className,
  ...props
}) => (
  <InputMessage
    className={clsx(styles.wrapper, className)}
    data-testid={props['data-testid']}
  >
    {children}
  </InputMessage>
);
