import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import OverlayLoading from 'components/OverlayLoading/OverlayLoading';

import { useUserPracticesQuery } from '../queries';
import { Practice } from '../types';

/**
 * Practices where logged in user is patient
 */
const UserPracticesContext = createContext<Practice[]>([]);

interface UserPracticesProviderProps {
  children: (practices: Practice[]) => ReactNode;
  initialState?: Practice[];
}

export const UserPracticesProvider: React.FC<UserPracticesProviderProps> = ({
  children,
  initialState,
}) => {
  const { data, isLoading } = useUserPracticesQuery({
    enabled: !initialState,
  });

  const value = useMemo(() => initialState ?? data ?? [], [data, initialState]);

  if (isLoading) {
    return <OverlayLoading />;
  }

  return (
    <UserPracticesContext.Provider value={value}>
      {children(value)}
    </UserPracticesContext.Provider>
  );
};

export const useUserPractices = () => {
  const context = useContext(UserPracticesContext);
  if (context === undefined) {
    throw new Error(
      'useUserPractices must be used within a UserPracticesProvider'
    );
  }
  return context;
};
