export const userQueryKeys = {
  all: [{ scope: 'user' }] as const,
  loggedInUser: () => [{ ...userQueryKeys.all[0], type: 'logged-in' }] as const,
  dexcomConnectionStatus: () =>
    [{ ...userQueryKeys.all[0], type: 'dexcom-connection-status' }] as const,
  partnerConnectionStatus: () =>
    [{ ...userQueryKeys.all[0], type: 'partner-connection-status' }] as const,
  oAuthScopes: () =>
    [{ ...userQueryKeys.all[0], type: 'oauth-scopes' }] as const,
};
