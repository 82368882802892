import { isEmpty } from 'lodash';
import { ReadingsStatsResponseDto } from 'shared/reading/types';
import { ValuesType } from 'utility-types';

import { ReadingName } from 'domain/domain.models';
import { AggregationInterval, StatType } from 'models';

import { getReadingsPercentagesInRanges } from '../get-readings-percentages-in-ranges/get-readings-percentages-in-ranges';

export const mapToTimeInRangeStats = (
  currentStats: ValuesType<
    ReadingsStatsResponseDto<
      ReadingName,
      StatType.RANGE,
      AggregationInterval.TOTAL
    >
  >,
  pastStats?: ValuesType<
    ReadingsStatsResponseDto<
      ReadingName,
      StatType.RANGE,
      AggregationInterval.TOTAL
    >
  >
) =>
  Object.entries(currentStats.stats).reduce(
    (acc, [name, rangeStats]) => {
      if (isEmpty(rangeStats.range.total.total)) return acc;

      const readingName = name as ReadingName;

      const ranges = mapStatsToRanges(rangeStats.range.total.total);

      const [
        currentBelowRangePercent,
        currentInRangePercent,
        currentAboveRangePercent,
      ] = getReadingsPercentagesInRanges(rangeStats.range.total.total);

      let pastBelowRangePercent, pastInRangePercent, pastAboveRangePercent;
      const pastTimeinRangeStats =
        pastStats?.stats[readingName].range.total?.total;

      if (pastTimeinRangeStats) {
        const percentagesInRanges =
          getReadingsPercentagesInRanges(pastTimeinRangeStats);
        pastBelowRangePercent = percentagesInRanges[0];
        pastInRangePercent = percentagesInRanges[1];
        pastAboveRangePercent = percentagesInRanges[2];
      }

      return {
        ...acc,
        [readingName]: {
          readingName,
          currentInRangePercent,
          currentBelowRangePercent,
          currentAboveRangePercent,
          pastInRangePercent,
          pastBelowRangePercent,
          pastAboveRangePercent,
          ranges,
        },
      };
    },
    {} as Record<
      ReadingName,
      {
        readingName: ReadingName;
        currentInRangePercent: number;
        pastInRangePercent?: number;
        currentBelowRangePercent: number;
        pastBelowRangePercent?: number;
        currentAboveRangePercent: number;
        pastAboveRangePercent?: number;
        ranges: [number, number];
      }
    >
  );

const mapStatsToRanges = (
  rangesRecord?: Record<string, number> | null
): [number, number] => {
  const rangesKeyRegexp = /^[^*]*-[^*]*$/;

  let ranges: number[] = [];

  if (rangesRecord) {
    ranges = Object.keys(rangesRecord)
      .find((key) => rangesKeyRegexp.test(key))
      ?.split('-')
      .map(Number) as number[];
  } else {
    ranges = [0, 0];
  }

  return ranges as [number, number];
};
