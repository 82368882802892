import { CellProps, Column } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { ColumnDef } from '@tanstack/react-table';

export const expanderColumn: Column<any> = {
  accessor: () => {},
  id: 'expander',
  disableSortBy: true,
  Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    <span
      {...getToggleAllRowsExpandedProps()}
      className="inline-block h-5 w-5"
      data-testid="table-row-expand-all-trigger"
    >
      {isAllRowsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </span>
  ),
  Cell: ({ row }: React.PropsWithChildren<CellProps<any, string>>) => (
    <span
      {...row.getToggleRowExpandedProps()}
      className="inline-block h-5 w-5"
      data-testid="table-row-expand-trigger"
    >
      {row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </span>
  ),
  headerCellClassName: 'w-2 !px-2',
  bodyCellClassName: 'w-2 !px-2',
  ExpandedCell: () => null,
} as const;

export const expanderMobileColumn: Column<any> = {
  accessor: () => {},
  id: 'expander',
  disableSortBy: true,
  Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    <span
      {...getToggleAllRowsExpandedProps()}
      className="inline-block h-5 w-5"
      data-testid="table-row-expand-all-trigger"
    >
      {isAllRowsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </span>
  ),
  Cell: ({ row }: React.PropsWithChildren<CellProps<any, string>>) => (
    <span
      className="inline-block h-5 w-5"
      data-testid="table-row-expand-trigger"
    >
      {row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </span>
  ),
  headerCellClassName: 'w-2 !px-2',
  bodyCellClassName: 'w-2 !px-2',
  ExpandedCell: () => null,
} as const;

export const getExpanderColumn = <T,>(): ColumnDef<T> =>
  ({
    accessorKey: '',
    id: 'expander',
    enableSorting: false,
    header: (props) => (
      <button
        onClick={() => props.table.toggleAllRowsExpanded()}
        className="inline-block h-7 w-7 md:h-5 md:w-5"
        data-testid="table-row-expand-all-trigger"
      >
        {props.table.getIsAllRowsExpanded() ? (
          <ChevronUpIcon />
        ) : (
          <ChevronDownIcon />
        )}
      </button>
    ),
    cell: ({ row }) => (
      <button
        onClick={(e) => {
          e.stopPropagation();
          row.toggleExpanded();
        }}
        className="inline-block h-7 w-7 md:h-5 md:w-5"
        data-testid="table-row-expand-all-trigger"
      >
        {row.getIsExpanded() ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </button>
    ),
    meta: {
      classes: {
        header: 'w-10 !pt-4 text-left',
        cell: '!pt-6',
      },
    },
  } as const);
