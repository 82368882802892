import {
  Action,
  createActionsHook,
  createContainer,
  createHook,
  createStateHook,
  createStore,
} from 'react-sweet-state';
import { ModalId } from 'shared/common/types';

type State = {
  activeModals: ModalId[];
};
type Actions = typeof actions;

const initialState: State = {
  activeModals: [],
};

const actions = {
  openModal:
    (id: ModalId): Action<State> =>
    ({ setState, getState }) => {
      setState({
        activeModals: [...getState().activeModals, id],
      });
    },
  closeModal:
    (id: ModalId): Action<State> =>
    ({ setState, getState }) => {
      setState({
        activeModals: getState().activeModals.filter((modal) => modal !== id),
      });
    },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
});

export const useModalActions = createActionsHook(Store);
export const useModalState = createStateHook(Store);
export const useModal = createHook(Store);
export const ModalContainer = createContainer(Store, {
  onInit:
    () =>
    ({ setState }, { initialState }: { initialState?: State } = {}) => {
      if (initialState) {
        setState(initialState);
      }
    },
});
