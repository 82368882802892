import { FC } from 'react';
import clsx from 'clsx';

import styles from './InputMessage.module.scss';

export interface InputMessageProps {
  'data-testid'?: string;
  className?: string;
}

export const InputMessage: FC<InputMessageProps> = ({
  children,
  className,
  ...props
}) => (
  <p
    data-testid={props['data-testid']}
    aria-live="assertive"
    aria-relevant="all"
    className={clsx(styles.wrapper, className)}
  >
    {children}
  </p>
);
