import { FC, useState } from 'react';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import clsx from 'clsx';
import { MIN_MEDIA_QUERIES, useWatchMediaQuery } from 'shared/common/hooks';

import { DesktopMenuItems } from './shared/desktop-menu-items';
import { MenuProvider } from './shared/menu.context';
import { MobileMenuItems } from './shared/mobile-menu-items';

import styles from './shared/styles.module.scss';

interface MenuProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  classes?: {
    menu?: string;
    items?: string;
  };
}
export const Menu: FC<MenuProps> = ({ children, trigger, classes }) => {
  const isMobile = !useWatchMediaQuery(MIN_MEDIA_QUERIES.MD);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );

  return (
    <HeadlessUIMenu as="div" className={clsx(styles.menu, classes?.menu)}>
      {({ open, close }) => (
        <MenuProvider isOpen={open}>
          <div ref={setTargetElement}>{trigger}</div>
          {isMobile ? (
            <MobileMenuItems {...{ close, isOpen: open, classes }}>
              {children}
            </MobileMenuItems>
          ) : (
            <DesktopMenuItems {...{ classes, targetElement }}>
              {children}
            </DesktopMenuItems>
          )}
        </MenuProvider>
      )}
    </HeadlessUIMenu>
  );
};
