import { config } from 'shared/common/config';

const allowedCodeVerifierChars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const getCodeVerifier = (): string => {
  const array = new Int8Array(config.getOauthCodeVerifierLength());
  return Array.from(window.crypto.getRandomValues(array))
    .map(
      (number) =>
        allowedCodeVerifierChars[
          Math.abs(number) % (allowedCodeVerifierChars.length - 1)
        ]
    )
    .join('');
};
