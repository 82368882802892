import { useCallback } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { useDateRangeFilterState, useTimezone } from 'shared/common/store';
import { DateFormat } from 'shared/common/utils';
import { getTimeAggregationInterval } from 'shared/reading/utils';

import { AggregationInterval } from 'models';

const dateFormats = {
  [AggregationInterval.MONTH]: DateFormat.MONTH_YEAR,
  [AggregationInterval.WEEK]: DateFormat.WEEK_YEAR,
  [AggregationInterval.YEAR]: DateFormat.YEAR,
  [AggregationInterval.DAY]: DateFormat.LOCALIZED_DATE,
};

/**
  This hook is used for displaying formatted dates on charts (where time axis is not used).
  It applies format adjusted for selected aggregation interval, timezone and locale
 */
export const useChartDateFormatterForCurrentTimeInterval = () => {
  // labels displayed in charts should take into account current user timezone
  const userTimezone = useTimezone();
  const { endDate, duration } = useDateRangeFilterState();
  const aggregationInterval = getTimeAggregationInterval(endDate, duration);

  return useCallback(
    // if Date object is passed, it should be already formatted using utcToZonedTime
    (date: string | Date) =>
      formatInTimeZone(date, userTimezone, dateFormats[aggregationInterval]),
    [aggregationInterval, userTimezone]
  );
};
