import clsx from 'clsx';

export type Themes =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'ghost'
  | 'primaryLight'
  | 'blue';

export const THEME_CLASSES = {
  primary: {
    background: clsx(
      `bg-ketoneBlue transition hover:bg-ketoneBlue-600 dark:bg-ketoneBlue-400 dark:hover:bg-ketoneBlue`,
      `disabled:cursor-not-allowed disabled:bg-ketoneBlue disabled:opacity-50`
    ),
    textColor: 'text-ketoneBlue-50 dark:text-ketoneBlue-900',
  },
  primaryLight: {
    background: clsx(
      `bg-ketoneBlue-50 transition hover:bg-ketoneBlue-100`,
      `disabled:cursor-not-allowed disabled:bg-ketoneBlue-100 disabled:opacity-50`
    ),
    textColor: 'text-ketoneBlue',
  },
  blue: {
    background: clsx(
      `bg-blue-50 transition hover:bg-blue-100 dark:bg-blue-800 dark:hover:bg-blue-700`,
      `disabled:cursor-not-allowed disabled:bg-blue-100 disabled:opacity-50 dark:disabled:bg-blue-900`
    ),
    textColor: 'text-blue-500 dark:text-blue-200',
  },
  secondary: {
    background: clsx(
      'border border-ketoneBlue bg-ketoneBlue-50 transition hover:bg-ketoneBlue-100 dark:border-ketoneBlue-400 dark:bg-ketoneBlue-900 dark:hover:bg-ketoneBlue-800',
      `disabled:cursor-not-allowed disabled:bg-ketoneBlue-50 disabled:opacity-50 dark:disabled:bg-ketoneBlue-900`
    ),
    textColor: 'text-ketoneBlue dark:text-ketoneBlue-400',
  },
  danger: {
    background: clsx(
      'bg-red-600 transition hover:bg-red-700',
      `disabled:cursor-not-allowed disabled:bg-red-600 disabled:opacity-50`
    ),
    textColor: 'text-white',
  },
  ghost: {
    background: clsx(
      'bg-transparent transition hover:bg-ketoneBlue-100 dark:hover:bg-ketoneBlue-800',
      `disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50 dark:disabled:bg-transparent`
    ),
    textColor: 'text-ketoneBlue dark:text-ketoneBlue-400',
  },
};
