import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

export const getFilteredDropdownOptions = <T>(
  query: string,
  options: DropdownOptionItem<T>[]
) =>
  query === ''
    ? options
    : options.filter((o) =>
        (o.label as string)
          .toLowerCase()
          .replace(EMPTY_SPACE_REG_EXP, '')
          .includes(query.toLowerCase().replace(EMPTY_SPACE_REG_EXP, ''))
      );

const EMPTY_SPACE_REG_EXP = /\s+/g;
