import { isIosSyncApp } from './is-sync-app';
import { setAccessToken } from './set-access-token';
import { setLanguage } from './set-language';
import { syncAppHandlers } from './sync-app-handlers';

/**
 * This function is used to expose some internal logic on a global object so that sync app can use it.
 */
export const exposeGlobalFunctions = () => {
  window.ketoMobile = {
    setAccessToken,
    setLanguage,
  };

  syncAppHandlers.contentLoaded();
  // TODO(MD-905): Temporary fix
  // iOS sync app ignores contentLoaded message and we need to manually trigger refresh session so that they would call setAccessToken with proper data
  // I've called sessionStorage directly here as this is only temporary solution and I don't want to pollute the code
  const didCallRefreshSessionBefore = sessionStorage.getItem(
    'didCallRefreshSession'
  );
  if (isIosSyncApp() && didCallRefreshSessionBefore !== 'true') {
    sessionStorage.setItem('didCallRefreshSession', 'true');
    syncAppHandlers.refreshSession();
  }
};
