import { TrendChangeState } from '../../../types';

export const getTrendChangeState = ({
  currentValue,
  previousValue,
  range,
}: {
  currentValue: number;
  previousValue: number;
  range: [number, number];
}) => {
  const rangeMin = range[0];
  const rangeMax = range[1];

  const isCurrentInTargetRange =
    currentValue >= rangeMin && currentValue <= rangeMax;
  const isPreviousInTargetRange =
    previousValue >= rangeMin && previousValue <= rangeMax;

  if (isCurrentInTargetRange && isPreviousInTargetRange) {
    return TrendChangeState.REMAINS_WITHIN_RANGE;
  }

  if (
    isPreviousInTargetRange &&
    (currentValue < rangeMin || currentValue > rangeMax)
  ) {
    return TrendChangeState.MOVES_OUTSIDE_RANGE;
  }

  if (
    isCurrentInTargetRange &&
    (previousValue < rangeMin || previousValue > rangeMax)
  ) {
    return TrendChangeState.MOVES_INTO_RANGE;
  }

  if (
    (!isCurrentInTargetRange &&
      currentValue < previousValue &&
      previousValue > rangeMax) ||
    (currentValue > previousValue && previousValue < rangeMin)
  ) {
    return TrendChangeState.MOVES_TOWARDS_RANGE;
  }

  if (
    (!isCurrentInTargetRange &&
      previousValue < rangeMin &&
      currentValue < previousValue) ||
    (previousValue > rangeMax && currentValue > previousValue)
  ) {
    return TrendChangeState.MOVES_FURTHER_AWAY_FROM_RANGE;
  }

  if (
    (!isCurrentInTargetRange &&
      previousValue < rangeMin &&
      currentValue === previousValue) ||
    (previousValue > rangeMax && currentValue === previousValue)
  ) {
    return TrendChangeState.REMAINS_OUTSIDE_RANGE;
  }

  throw new Error('Unexpected trend change state');
};
