import { getOldDashboardRoutes } from 'shared/common/utils';
import { Practice } from 'shared/practice/types';

export const getPracticeUserSignupUrl = ({
  oldDashboardUrl,
  practice,
}: {
  oldDashboardUrl: string;
  practice: Practice;
}) => getOldDashboardRoutes(oldDashboardUrl).register() + practice.slug;

export const getPracticeHcpSignupUrl = ({
  oldDashboardUrl,
  practice,
}: {
  oldDashboardUrl: string;
  practice: Practice;
}) => getOldDashboardRoutes(oldDashboardUrl).hcpRegister() + practice.id;
