import React, { VFC } from 'react';
import clsx from 'clsx';
import i18next from 'i18next';

import { Tooltip } from '../../atoms';

export interface OnlineStatusIconProps {
  size?: string;
  className?: string;
}

export const OnlineStatusIcon: VFC<OnlineStatusIconProps> = ({
  size = '10px',
  className,
}) => (
  <Tooltip content={i18next.t('Online')} onHover>
    <svg
      className={clsx('rounded-[50%] fill-green-400', className)}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        maxWidth: size,
        maxHeight: size,
      }}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" />
    </svg>
  </Tooltip>
);
