import compact from 'lodash/compact';

import { ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

export const getDetailedReadingsLink =
  ({
    practiceId,
    patientUserId,
    baseRoutes,
  }: {
    baseRoutes: {
      readingNameDetails: (
        practiceId?: string,
        userId?: string,
        slug?: string
      ) => string;
      macrosDetails: (practiceId?: string, userId?: string) => string;
      bloodPressureDetails: (practiceId?: string, userId?: string) => string;
    };
    practiceId?: string;
    patientUserId?: string;
  }) =>
  (readingName: ReadingName) => {
    if (
      readingName === ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT ||
      readingName === ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT
    ) {
      return baseRoutes.bloodPressureDetails(practiceId, patientUserId);
    }

    if (
      readingName === ReadingName.CARBS_INGESTION ||
      readingName === ReadingName.PROTEIN_INGESTION ||
      readingName === ReadingName.FAT_INGESTION
    ) {
      return baseRoutes.macrosDetails(practiceId, patientUserId);
    }

    const slug = getDomainConfigs(readingName).slug;
    return baseRoutes.readingNameDetails(
      ...compact([practiceId, patientUserId, slug])
    );
  };
