import isEmpty from 'lodash/isEmpty';
import {
  PracticeRangeDescription,
  PracticeRangeDescriptionsMap,
} from 'shared/practice/types';

import { SupportedLanguageCode } from 'consts/supported-language-code';
import { ReadingName } from 'domain/domain.models';
import { Unit } from 'models';

export const getPracticeRangeDescription = ({
  descriptions,
  readingName,
  unit,
  value,
  language,
}: {
  descriptions: PracticeRangeDescriptionsMap;
  readingName: ReadingName;
  unit: Unit;
  value: number;
  language: SupportedLanguageCode;
}) => {
  let readingNameDescriptions: PracticeRangeDescription[] | undefined =
    descriptions[readingName];

  if (readingName === ReadingName.GLUCOSE_INTERSTITIAL) {
    readingNameDescriptions = descriptions[ReadingName.GLUCOSE_BLOOD];
  }

  if (!readingNameDescriptions) return null;

  const unitDescriptions = readingNameDescriptions.filter(
    (d) => d.unit === unit
  );

  if (isEmpty(unitDescriptions)) return null;

  const valueDescription = unitDescriptions.filter(
    ({ min, max, maxInclusive, minInclusive }) => {
      if (minInclusive && maxInclusive) {
        return value >= min && value <= max;
      }

      if (!minInclusive && maxInclusive) {
        return value > min && value <= max;
      }

      if (minInclusive && !maxInclusive) {
        return value >= min && value < max;
      }

      if (!minInclusive && !maxInclusive) {
        return value > min && value < max;
      }

      return false;
    }
  )[0];

  if (!valueDescription) return null;

  const defaultDescription = valueDescription.descriptions.find(
    (d) => d.language === valueDescription.defaultLang
  );

  const languageMatchingDescription = valueDescription.descriptions.find(
    (d) => d.language === language
  );

  return languageMatchingDescription ?? defaultDescription ?? null;
};
