import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { Toggle } from 'components/Toggle/Toggle';

import { Tooltip } from '../../atoms';

interface AdvancedDashboardReportToggleProps {
  isAdvancedMode: boolean;
  onToggle: (isAdvancedMode: boolean) => void;
  className?: string;
}

export const AdvancedDashboardReportToggle: VFC<
  AdvancedDashboardReportToggleProps
> = ({ isAdvancedMode, onToggle, className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        'flex w-full items-center gap-1 rounded-lg bg-coolGray-100 p-2.5 dark:bg-coolGray-800 md:w-max',
        className
      )}
    >
      <Tooltip
        content={t(
          'In Advanced Mode you can see additional trends, charts and metrics.'
        )}
        classes={{
          wrapper: 'flex items-center',
        }}
      >
        <InformationCircleIcon className="h-6 w-6 text-coolGray-400 dark:text-coolGray-100" />
      </Tooltip>
      <span className="flex-grow text-sm font-medium leading-5 text-coolGray-400 dark:text-coolGray-100">
        {t('Advanced Mode')}
      </span>
      <Toggle
        testId="advanced-mode-toggle"
        checked={isAdvancedMode}
        onChange={onToggle}
      />
    </div>
  );
};
