import { AxiosRequestConfig } from 'axios';
import { config } from 'shared/common/config';

import {
  CLIENT_APPLICATION_HEADER_NAME,
  CLIENT_VERSION_HEADER_NAME,
} from 'consts';

const clientIdInterceptor = async (request: AxiosRequestConfig) => {
  request.headers[CLIENT_APPLICATION_HEADER_NAME] = config.getApplicationName();
  request.headers[CLIENT_VERSION_HEADER_NAME] = config.getAppVersion();

  return request;
};

export default clientIdInterceptor;
