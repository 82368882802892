import { colors } from 'consts';

const addVarsToHTML = (vars: { key: string; value: string }[]) => {
  const styleTag = document.createElement('style');
  styleTag.innerText =
    vars.reduce(
      (text, { key, value }) => text + `--${key}:${value};`,
      ':root {'
    ) + '}';

  document.head.appendChild(styleTag);
};

const TAILWIND_DEFAULT_SHADE_NAME = 'DEFAULT';

// todo: do it as a webpack script which will build extra css
export const initializeCssVariables = (): void => {
  // let's do it after the app initialized
  const propsToAdd: { key: string; value: string }[] = [];
  setTimeout(() => {
    Object.entries(colors).forEach(([key, value]) => {
      if (typeof value === 'string') {
        propsToAdd.push({ key, value });
      } else {
        const shades = value as object;
        Object.entries(shades).forEach(([shadeName, shadeValue]) => {
          shadeName === TAILWIND_DEFAULT_SHADE_NAME
            ? propsToAdd.push({ key, value: shadeValue })
            : propsToAdd.push({
                key: `${key}-${shadeName}`,
                value: shadeValue,
              });
        });
      }
    });

    addVarsToHTML(propsToAdd);
  });
};
