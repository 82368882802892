import { isToday, isYesterday } from 'date-fns';
import { format as tzFormat } from 'date-fns-tz';
import i18next from 'i18next';

import { DateFormat, WEEK_START_DAY } from '../date-utils/format-date';
import { getUserLocale } from '../get-user-locale/get-user-locale';

import { getZonedTimestamp } from './get-zoned-timestamp';

export const formatZonedTimestampFactory =
  ({ locale }: { locale: Locale }) =>
  (timeZone: string, dateFormat: DateFormat = DateFormat.LOCALIZED_DATE) =>
  (timestamp: string) => {
    const { zonedDate, showTimezone, timezone } = getZonedTimestamp({
      timestamp,
      timezone: timeZone,
    });

    const formatOptions: Parameters<typeof tzFormat>[2] = {
      locale,
      timeZone: timezone,
      weekStartsOn: WEEK_START_DAY,
    };

    const formattedTimestamp = isToday(zonedDate)
      ? `${i18next.t('Today')}, ${tzFormat(
          zonedDate,
          `${DateFormat.LOCALIZED_TIME} ${showTimezone ? 'OO' : ''}`,
          formatOptions
        )}`
      : isYesterday(zonedDate)
      ? `${i18next.t('Yesterday')}, ${tzFormat(
          zonedDate,
          `${DateFormat.LOCALIZED_TIME} ${showTimezone ? 'OO' : ''}`,
          formatOptions
        )}`
      : tzFormat(
          zonedDate,
          `${dateFormat} ${showTimezone ? 'OO' : ''}`,
          formatOptions
        );

    return formattedTimestamp.trim();
  };

export const formatZonedTimestamp = formatZonedTimestampFactory({
  locale: getUserLocale(),
});
