import { Reading } from 'shared/reading/types';

import { MeterType } from '../../../../models/meter-type';

/**
 * Taidoc Meter's serial number starts with "4279"
 * https://keto-mojo.atlassian.net/browse/MD-1357
 */
const TAIDOC_METER_SERIAL_NUMBER_PREFIX = '4279';

interface TaidocMeterReading {
  meterType: MeterType.BT_METER;
  serialNumber: `${typeof TAIDOC_METER_SERIAL_NUMBER_PREFIX}${string}`;
}

export const isTaidocMeterReading = (
  reading: Pick<Reading, 'meterType' | 'serialNumber'>
): reading is TaidocMeterReading =>
  reading.meterType === MeterType.BT_METER &&
  reading.serialNumber?.slice(0, 4) === TAIDOC_METER_SERIAL_NUMBER_PREFIX;
