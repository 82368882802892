import { useCallback, useMemo } from 'react';
import toast, { ToastOptions } from 'react-hot-toast';
import { MIN_MEDIA_QUERIES, useWatchMediaQuery } from 'shared/common/hooks';

import { IOpenToastProps, ToastType } from './toast-types';
import {
  errorIcon,
  infoIcon,
  successIcon,
  ToastTemplate,
  warningIcon,
} from './ToastTemplate';

export const useToast = () => {
  const isMd = useWatchMediaQuery(MIN_MEDIA_QUERIES.MD);
  const position = useMemo(() => (isMd ? 'top-right' : 'top-center'), [isMd]);

  const selectToastIcon = (toastType: ToastType) => {
    switch (toastType) {
      case ToastType.SUCCESS:
        return successIcon;

      case ToastType.ERROR:
        return errorIcon;

      case ToastType.WARNING:
        return warningIcon;

      default:
        return infoIcon;
    }
  };

  const openToast = useCallback(
    (props: IOpenToastProps, config: ToastOptions = {}) => {
      const icon = selectToastIcon(props.type);

      const toastId = toast.custom(
        (t) => <ToastTemplate toastInstance={t} {...props} icon={icon} />,
        {
          duration: 2000,
          position,
          ...config,
        }
      );

      return { removeToast: () => toast.remove(toastId) };
    },
    [position]
  );

  return {
    openToast,
  };
};
