// if from_date and to_date query params are provided, this hook sets the filters as custom calendar range with those dates
import { useEffect } from 'react';
import { useUrlSearchParams } from 'shared/common/hooks';
import { useDateRangeFilterActions } from 'shared/common/store';
import { DateRangePeriod } from 'shared/common/types';

export const useSetTimeRangeFiltersFromQueryParams = () => {
  const { setDateFilter } = useDateRangeFilterActions();
  const searchQuery = useUrlSearchParams();

  const startDateString = searchQuery.get('from_date');
  const endDateString = searchQuery.get('to_date');

  useEffect(() => {
    if (!startDateString || !endDateString) return;

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    if (!startDate || !endDate) return;

    setDateFilter({
      period: DateRangePeriod.CUSTOM,
      startDate,
      endDate,
    });
  }, [setDateFilter, startDateString, endDateString]);
};
