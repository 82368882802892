import React from 'react';
import clsx from 'clsx';
import { getDataPrivateAttr } from 'shared/common/utils';

import { Checkbox } from 'components/forms-v2/inputs/checkbox/Checkbox';

import { DropdownOptionItem } from '../../forms.types';

import styles from './OptionItem.module.scss';

export interface OptionItemProps<T> extends DropdownOptionItem<T> {
  isSelected?: boolean;
  isMultiselect?: boolean;
  className?: string;
  'data-testid'?: string;
  'data-private'?: boolean;
}

export const OptionItem = <T,>({
  label,
  className,
  icon: Icon,
  isMultiselect = false,
  isSelected = false,
  labelComponent,
  onSelect,
  ...props
}: OptionItemProps<T>) => (
  <div
    className={clsx(
      styles.option,
      isSelected && !isMultiselect && styles.selected,
      className
    )}
    role="option"
    tabIndex={0}
    aria-selected={isSelected}
    data-testid={`${props['data-testid']}__option`}
    {...getDataPrivateAttr(props['data-private'])}
  >
    {isMultiselect && <Checkbox isChecked={isSelected} />}
    {Icon && (
      <div className={styles.iconWrapper}>
        <Icon aria-hidden="true" />
      </div>
    )}
    {labelComponent ?? label}
  </div>
);
