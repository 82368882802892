import i18next from 'i18next';
import replace from 'lodash/replace';
import {
  getFormattedPercentages,
  getFormattedUnit,
  getFormattedValue,
} from 'shared/common/utils';

import { ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';
import { StatType, Unit } from 'models';

import { TrendChangeState } from '../../../types';
import { getStatTypeLabel } from '../../get-stat-type-label/get-stat-type-label';
import { getTrendChangeState } from '../get-trend-change-state/get-trend-change-state';

/**
 * Get tooltip content for trend for values other than time in range
 */
export const getTrendTooltipContent = ({
  readingName,
  currentValue,
  previousValue,
  unit,
  range,
  statType,
}: {
  statType: SupportedStatTypes;
  unit: Unit;
  readingName: ReadingName;
  currentValue?: number;
  previousValue?: number;
  range: [number, number];
}): string => {
  let tooltip = '';

  const { label: getReadingNameLabel } = getDomainConfigs(readingName);

  const formattedUnit = getFormattedUnit(unit);

  if (currentValue === undefined) {
    tooltip += i18next.t(
      `There were no {{ readingNameLabel }} readings during selected time period.`,
      {
        readingNameLabel: getReadingNameLabel(),
      }
    );

    return removeExcessWhitespace(tooltip);
  }

  const formattedCurrentValue = getFormattedValue({
    readingName,
    value: currentValue,
    unit,
  });

  if (previousValue !== undefined) {
    tooltip = addTrendInfoToTooltip({
      tooltip,
      statType,
      readingNameLabel: getReadingNameLabel(),
      currentValue,
      previousValue,
      range,
    });

    tooltip = addValueChangeInfoToTooltip({
      tooltip,
      formattedUnit,
      currentValue,
      previousValue,
      readingName,
      unit,
    });
  } else {
    tooltip += i18next.t(
      `{{ statTypeLabel }} {{ readingNameLabel }} has been {{ formattedCurrentValue }} {{ formattedUnit }} during selected time period. Trend could not be calculated, as there was no data during previous time period to compare it to. `,
      {
        statTypeLabel: getStatTypeLabel({ statType, isCapitalized: true }),
        readingNameLabel: getReadingNameLabel(),
        formattedCurrentValue,
        formattedUnit,
      }
    );
  }

  return removeExcessWhitespace(tooltip);
};

const addValueChangeInfoToTooltip = ({
  tooltip,
  formattedUnit,
  previousValue,
  currentValue,
  readingName,
  unit,
}: {
  tooltip: string;
  formattedUnit: string;
  currentValue: number;
  previousValue: number;
  readingName: ReadingName;
  unit: Unit;
}) => {
  const percentageChange = currentValue / previousValue - 1;
  const formattedPreviousValue = getFormattedValue({
    readingName,
    value: previousValue,
    unit,
  });

  const formattedCurrentValue = getFormattedValue({
    readingName,
    value: currentValue,
    unit,
  });

  const isNoChange = percentageChange === 0;
  const isBothValueZero = isNaN(percentageChange);
  const isPreviousValueZero = percentageChange === Infinity;

  if (isNoChange || isBothValueZero) {
    tooltip += i18next.t(
      'It remained at {{ formattedCurrentValue }} {{ formattedUnit }}. ',
      {
        formattedCurrentValue,
        formattedUnit,
      }
    );
  } else if (isPreviousValueZero) {
    tooltip += i18next.t(
      'It was {{ formattedPreviousValue }} {{ formattedUnit }} and it has increased to {{ formattedCurrentValue }} {{ formattedUnit }}. ',
      {
        formattedCurrentValue,
        formattedPreviousValue,
        formattedUnit,
      }
    );
  } else {
    const changeType =
      percentageChange > 0 ? i18next.t('increased') : i18next.t('decreased');
    const formattedPercentageChange = getFormattedPercentages(
      Math.abs(percentageChange),
      {
        decimals: 1,
        trailingZeros: false,
      }
    );

    tooltip += i18next.t(
      'It was {{ formattedPreviousValue }} {{ formattedUnit }} and it has {{ changeType }} by {{ formattedPercentageChange }} to {{ formattedCurrentValue }} {{ formattedUnit }}. ',
      {
        formattedCurrentValue,
        formattedPreviousValue,
        changeType,
        formattedPercentageChange,
        formattedUnit,
      }
    );
  }

  return removeExcessWhitespace(tooltip);
};

const addTrendInfoToTooltip = ({
  tooltip,
  statType,
  readingNameLabel,
  currentValue,
  previousValue,
  range,
}: {
  currentValue: number;
  previousValue: number;
  range: [number, number];
  tooltip: string;
  statType: SupportedStatTypes;
  readingNameLabel: string;
}) => {
  const trendChangeState = getTrendChangeState({
    currentValue,
    previousValue,
    range,
  });

  tooltip += i18next.t(
    `{{ statTypeLabel }} {{ readingNameLabel }} level has {{ trendChangeStateLabel }} during selected time period, when compared to previous time period. `,
    {
      statTypeLabel: getStatTypeLabel({ statType, isCapitalized: true }),
      readingNameLabel,
      trendChangeStateLabel: getTrendChangeStateLabel(trendChangeState),
    }
  );
  return removeExcessWhitespace(tooltip);
};

const getTrendChangeStateLabel = (trendChangeState: TrendChangeState) => {
  const labels = {
    [TrendChangeState.REMAINS_WITHIN_RANGE]: i18next.t(
      'remained within target range'
    ),
    [TrendChangeState.MOVES_OUTSIDE_RANGE]: i18next.t(
      'moved outside of target range'
    ),
    [TrendChangeState.MOVES_INTO_RANGE]: i18next.t('moved within target range'),
    [TrendChangeState.MOVES_TOWARDS_RANGE]: i18next.t(
      'moved closer toward target range'
    ),
    [TrendChangeState.MOVES_FURTHER_AWAY_FROM_RANGE]: i18next.t(
      'moved further away from target range'
    ),
    [TrendChangeState.REMAINS_OUTSIDE_RANGE]: i18next.t(
      'remained outside target range'
    ),
  } as const;

  return labels[trendChangeState];
};

const removeExcessWhitespace = (value: string) =>
  replace(value.trim(), /\s+/g, ' ');

type SupportedStatTypes =
  | StatType.AVG
  | StatType.MIN
  | StatType.MAX
  | StatType.STD;
