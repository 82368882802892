import { CookieOptions } from 'cookie-storage/lib/cookie-options';
import { config } from 'shared/common/config';
import { removeCookie, setCookie } from 'shared/common/utils';
import { NewAuthTokenResponse } from 'shared/user/types';

import { CookieStorageKeys } from 'consts/cookie-storage-keys';

const domain = window.location.hostname;
const staticCookieOptions: CookieOptions = {
  domain,
  path: '/',
  sameSite: 'Lax',
  secure: config.getIsAppDeployed(),
};

const getCookieOptions = ({
  tokenExpiryTimeInMs,
}: {
  tokenExpiryTimeInMs: number;
}) => {
  const cookieOptions: CookieOptions = {
    ...staticCookieOptions,
    expires: new Date(new Date().valueOf() + tokenExpiryTimeInMs),
  };

  return cookieOptions;
};

export const saveAuthToken = (
  tokenData: Pick<NewAuthTokenResponse, 'expires_in' | 'access_token'>
) => {
  const tokenExpiryTimeInMs = tokenData.expires_in * 1000;
  const cookieOptions = getCookieOptions({ tokenExpiryTimeInMs });
  setCookie(
    CookieStorageKeys.ACCESS_TOKEN,
    tokenData.access_token,
    cookieOptions
  );
};

export const saveRefreshToken = (
  tokenData: Pick<NewAuthTokenResponse, 'expires_in' | 'refresh_token'>
) => {
  const tokenExpiryTimeInMs = tokenData.expires_in * 1000 * 2;
  const cookieOptions = getCookieOptions({ tokenExpiryTimeInMs });
  setCookie(
    CookieStorageKeys.REFRESH_TOKEN,
    tokenData.refresh_token,
    cookieOptions
  );
};

export const saveAuthCookies = (tokenData: NewAuthTokenResponse) => {
  saveAuthToken(tokenData);
  saveRefreshToken(tokenData);
};

export const removeAuthCookies = () => {
  removeCookie(CookieStorageKeys.REFRESH_TOKEN, staticCookieOptions);
  removeCookie(CookieStorageKeys.ACCESS_TOKEN, staticCookieOptions);
};
