import { UserRegion } from 'consts';
import { Environment } from 'consts/environment';

import pkg from '../../../../package.json';

export const config = {
  getApplicationName: (): string => process.env.REACT_APP_APPLICATION_NAME!,
  getAllRegionsMetadataUrl: (): string =>
    process.env.REACT_APP_ALL_REGIONS_METADATA_URL!,
  // Rollbar Access Token
  getRollbarAccessToken: () =>
    process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN as string,
  // Client Id for the gtag.js (Google Analytics)
  getGaMeasurementId: (): string => process.env.REACT_APP_GA_MEASUREMENT_ID!,
  getOauthCodeVerifierLength: (): number =>
    Number(process.env.REACT_APP_OAUTH_CODE_VERIFIER_LENGTH!),
  getSupportEmail: (): string => process.env.REACT_APP_SUPPORT_EMAIL!,
  getDefaultRegion: (): UserRegion =>
    process.env.REACT_APP_DEFAULT_REGION! as UserRegion,
  getIsProd: () => process.env.REACT_APP_ENV! === Environment.PRODUCTION,
  getIsStaging: () => process.env.REACT_APP_ENV! === Environment.STAGING,
  getIsQa: () => process.env.REACT_APP_ENV! === Environment.QA,
  getEnv: () => process.env.REACT_APP_ENV! as Environment,
  getIsAppDeployed: () => process.env.NODE_ENV === 'production',
  getSyncAppTimeoutTime: (): number =>
    Number(process.env.REACT_APP_SYNC_APP_TIMEOUT_TIME!),
  getLogRocketAppId: (): string => process.env.REACT_APP_LOG_ROCKET_APP_ID!,
  getIsLogRocketSessionRecordingEnabled: (): boolean =>
    Boolean(Number(process.env.REACT_APP_ENABLE_LOGROCKET_SESSION_RECORDING!)),
  getAppVersion: () => pkg.version,
  getChatApiKey: (region: UserRegion): string | undefined => {
    if (region === UserRegion.QA) return process.env.REACT_APP_QA_CHAT_API_KEY!;
    if (region === UserRegion.STAGING)
      return process.env.REACT_APP_STAGING_CHAT_API_KEY!;
    if (region === UserRegion.US) return process.env.REACT_APP_US_CHAT_API_KEY!;
    if (region === UserRegion.EU) return process.env.REACT_APP_EU_CHAT_API_KEY!;
    if (region === UserRegion.CA) return process.env.REACT_APP_CA_CHAT_API_KEY!;
  },
  getFallbackTimezone: (region: UserRegion) => {
    // those timezones correspond to the defaults set on the BE
    if (region === UserRegion.US) return 'US/Pacific';
    if (region === UserRegion.EU) return 'Europe/Zurich';
    if (region === UserRegion.CA) return 'Canada/Eastern';
    return 'GMT';
  },
};
