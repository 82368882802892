import { VFC } from 'react';
import { config } from 'shared/common/config';
import { getMailtoLink } from 'shared/common/utils';

interface SupportLinkProps {
  mailSubject: string;
}

export const SupportLink: VFC<SupportLinkProps> = ({ mailSubject }) => {
  const supportFormLink = getMailtoLink(config.getSupportEmail(), {
    subject: mailSubject,
  });

  return (
    <a
      className="link inline-block"
      href={supportFormLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {config.getSupportEmail()}
    </a>
  );
};
