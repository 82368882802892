import i18next from 'i18next';
import startCase from 'lodash/startCase';
import { PracticeType } from 'shared/practice/types';

const practiceTypeToSingularPracticeLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practice'),
  [PracticeType.COACHING_GROUP]: i18next.t('coaching group'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('support group'),
  [PracticeType.GROUP]: i18next.t('group'),
});

const practiceTypeToSingularPracticeAdminLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practice admin'),
  [PracticeType.COACHING_GROUP]: i18next.t('coach admin'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('group admin'),
  [PracticeType.GROUP]: i18next.t('admin'),
});

const practiceTypeToSingularPracticeOwnerLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practice owner'),
  [PracticeType.COACHING_GROUP]: i18next.t('coaching group owner'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('support group owner'),
  [PracticeType.GROUP]: i18next.t('owner'),
});

const practiceTypeToSingularHcpLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practitioner'),
  [PracticeType.COACHING_GROUP]: i18next.t('coach'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('group leader'),
  [PracticeType.GROUP]: i18next.t('leader'),
});

const practiceTypeToSingularPatientLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('patient'),
  [PracticeType.COACHING_GROUP]: i18next.t('client'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('participant'),
  [PracticeType.GROUP]: i18next.t('member'),
});

const practiceTypeToPluralPracticeLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practices'),
  [PracticeType.COACHING_GROUP]: i18next.t('coaching groups'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('support groups'),
  [PracticeType.GROUP]: i18next.t('groups'),
});

const practiceTypeToPluralPracticeOwnerLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practice owners'),
  [PracticeType.COACHING_GROUP]: i18next.t('coaching group owners'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('support group owners'),
  [PracticeType.GROUP]: i18next.t('owners'),
});

const practiceTypeToPluralPracticeAdminLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practice admins'),
  [PracticeType.COACHING_GROUP]: i18next.t('coach admins'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('group admins'),
  [PracticeType.GROUP]: i18next.t('admins'),
});

const practiceTypeToPluralHcpLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('practitioners'),
  [PracticeType.COACHING_GROUP]: i18next.t('coaches'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('group leaders'),
  [PracticeType.GROUP]: i18next.t('leaders'),
});

const practiceTypeToPluralPatientLabel = () => ({
  [PracticeType.PRACTICE]: i18next.t('patients'),
  [PracticeType.COACHING_GROUP]: i18next.t('clients'),
  [PracticeType.SUPPORT_GROUP]: i18next.t('participants'),
  [PracticeType.GROUP]: i18next.t('members'),
});

export const getPracticeTypeDependantLabels: GetPracticeTypeDependantLabelsFn =
  (type) => {
    const getPracticeLabel: LabelFn = ({
      isPlural,
      titleCase,
      capitalCase,
    } = {}) => {
      const label = isPlural
        ? practiceTypeToPluralPracticeLabel()[type]
        : practiceTypeToSingularPracticeLabel()[type];

      return getCaseFormattedLabel({ label, titleCase, capitalCase });
    };

    const getPracticeOwnerLabel: LabelFn = ({
      isPlural,
      titleCase,
      capitalCase,
    } = {}) => {
      const label = isPlural
        ? practiceTypeToPluralPracticeOwnerLabel()[type]
        : practiceTypeToSingularPracticeOwnerLabel()[type];

      return getCaseFormattedLabel({ label, titleCase, capitalCase });
    };

    const getPracticeAdminLabel: LabelFn = ({
      isPlural,
      titleCase,
      capitalCase,
    } = {}) => {
      const label = isPlural
        ? practiceTypeToPluralPracticeAdminLabel()[type]
        : practiceTypeToSingularPracticeAdminLabel()[type];

      return getCaseFormattedLabel({ label, titleCase, capitalCase });
    };

    const getHcpLabel: LabelFn = ({
      isPlural,
      titleCase,
      capitalCase,
    } = {}) => {
      const label = isPlural
        ? practiceTypeToPluralHcpLabel()[type]
        : practiceTypeToSingularHcpLabel()[type];

      return getCaseFormattedLabel({ label, titleCase, capitalCase });
    };

    const getPatientLabel: LabelFn = ({
      isPlural,
      titleCase,
      capitalCase,
    } = {}) => {
      const label = isPlural
        ? practiceTypeToPluralPatientLabel()[type]
        : practiceTypeToSingularPatientLabel()[type];

      return getCaseFormattedLabel({ label, titleCase, capitalCase });
    };

    return {
      getPracticeLabel,
      getPracticeAdminLabel,
      getPracticeOwnerLabel,
      getHcpLabel,
      getPatientLabel,
    };
  };

const getCaseFormattedLabel = ({
  capitalCase = false,
  titleCase = false,
  label,
}: {
  capitalCase?: boolean;
  titleCase?: boolean;
  label: string;
}) => startCase(label);

/*
  capitalCase and titleCase are obsolete now, as due to requirement change, all labels are now title case
*/
type LabelFn = (params?: {
  capitalCase?: boolean;
  titleCase?: boolean;
  isPlural?: boolean;
}) => string;

declare type GetPracticeTypeDependantLabelsFn = (type: PracticeType) => {
  getPracticeLabel: LabelFn;
  getPracticeAdminLabel: LabelFn;
  getPracticeOwnerLabel: LabelFn;
  getHcpLabel: LabelFn;
  getPatientLabel: LabelFn;
};
