import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import axios from 'axios';
import clsx from 'clsx';
import useLocalStorageState from 'use-local-storage-state';

import Button from 'components/Button/Button';
import { LocalStorageKey } from 'consts';

import { Policy, useGetPolicyLink } from '../shared/use-get-policy-link';

import styles from './cookie-notice.module.scss';

export const CookieNotice = () => {
  const { t } = useTranslation();
  const getPolicyLink = useGetPolicyLink();
  const [thirdPartyCookieConsent, setThirdPartyCookieConsent] =
    useLocalStorageState(LocalStorageKey.COOKIE_CONSENT);

  const displayNotice =
    thirdPartyCookieConsent !== true && thirdPartyCookieConsent !== false;

  const { data, isLoading } = useQuery({
    queryKey: 'cookie-policy',
    queryFn: async () => {
      const url = getPolicyLink(Policy.COOKIE_POLICY_NO_MARKUP);
      const res = await axios.get<{ content: string }>(url);
      return res.data.content;
    },
    cacheTime: Infinity,
    enabled: displayNotice,
  });

  if (!displayNotice) return null;

  return (
    <>
      <div className="fixed inset-0 z-40 bg-black opacity-25" />
      <div
        role="alertdialog"
        className="fixed inset-x-0 bottom-0 z-50 w-full bg-white p-4 dark:bg-black dark:text-white"
      >
        <Disclosure>
          {({ open }) => (
            <>
              <div className="mb-4 border-b p-4">
                <h1 className="text-xl font-bold">{t('Notice')}</h1>

                <Disclosure.Button
                  className="flex w-full items-center text-left"
                  aria-label={t('Toggle cookie policy visibility')}
                >
                  <span>
                    {t(
                      'We and selected partners use cookies or similar technologies as specified in our cookie policy'
                    )}
                  </span>
                  <span className="min-w-5 ml-1 h-5 w-5">
                    {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </span>
                </Disclosure.Button>
              </div>

              <Disclosure.Panel>
                <div className="mb-4 max-h-80 overflow-x-auto">
                  {isLoading || !data ? (
                    <Skeleton count={10} />
                  ) : (
                    <div
                      className={clsx(
                        'prose max-w-full rounded bg-gray-100 p-4 text-sm',
                        styles.content
                      )}
                      dangerouslySetInnerHTML={{ __html: data }}
                    />
                  )}
                </div>
              </Disclosure.Panel>

              <div className="flex justify-end space-x-2">
                <Button
                  theme="secondary"
                  onClick={() => setThirdPartyCookieConsent(false)}
                >
                  {t('Deny')}
                </Button>
                <Button onClick={() => setThirdPartyCookieConsent(true)}>
                  {t('Accept')}
                </Button>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
