import { SessionStorageKeys } from 'shared/common/utils';

export const initializeLogrocketConditionally = ({
  isSyncApp,
  isAppDeployed,
  isNewAppVersion,
  isLoggingGloballyEnabled,
  initializeLogRocket,
}: {
  isSyncApp: boolean;
  // not in dev mode
  isAppDeployed: boolean;
  isNewAppVersion: boolean;
  isLoggingGloballyEnabled: boolean;
  initializeLogRocket: () => void;
}) => {
  const isLoggingEnabledCachedValue = sessionStorage.getItem(
    SessionStorageKeys.IS_LOG_ROCKET_SESSION_RECORDED
  );

  const isLoggingEnabled =
    isAppDeployed &&
    (isLoggingEnabledCachedValue ||
      isLoggingGloballyEnabled ||
      isNewAppVersion);

  if (isLoggingEnabled) {
    sessionStorage.setItem(
      SessionStorageKeys.IS_LOG_ROCKET_SESSION_RECORDED,
      'true'
    );
  }

  if (isLoggingEnabled && isSyncApp) {
    initializeLogrocketOnSyncApp(initializeLogRocket);
  } else if (isLoggingEnabled) {
    initializeLogRocket();
  }
};

const initializeLogrocketOnSyncApp = (initializeLogRocket: () => void) => {
  const eventHandler = () => {
    initializeLogRocket();

    window.removeEventListener('touchstart', eventHandler);
  };

  window.addEventListener('touchstart', eventHandler);
};
