import { useQuery } from 'react-query';
import { config } from 'shared/common/config';
import { regionStore } from 'shared/common/store';
import { mapToPreferredUnits, mapToRanges } from 'shared/common/utils';

import { patientApi } from '../../api';
import { patientQueryKeys } from '../../consts';
import {
  isHcpPatientDto,
  Patient,
  PatientDto,
  PatientStatus,
} from '../../types';

export const patientQueryFactory =
  (dependancies: { patientApi: typeof patientApi; fallbackTimezone: string }) =>
  (params: { practiceId?: string; patientUserId?: string }) => {
    const { patientApi, fallbackTimezone } = dependancies;

    return useQuery(
      patientQueryKeys.detail({
        practiceId: params.practiceId!,
        patientUserId: params.patientUserId!,
      }),
      () =>
        patientApi.getPatient({
          practiceId: params.practiceId!,
          patientUserId: params.patientUserId!,
        }),
      {
        select: (dto) => mapToUser(dto, fallbackTimezone),
        enabled: !!params.patientUserId && !!params.practiceId,
      }
    );
  };

const mapToUser = (dto: PatientDto, fallbackTimezone: string): Patient => {
  let patient: Patient = {
    userId: dto.user.user_id,
    fullName:
      dto.user.first_name || dto.user.last_name
        ? `${dto.user.first_name} ${dto.user.last_name}`
        : '',
    firstName: dto.user.first_name,
    lastName: dto.user.last_name,
    oldestReadingDate: dto.user.oldest_reading_timestamp
      ? new Date(dto.user.oldest_reading_timestamp)
      : null,
    newestReadingDate: dto.user.newest_reading_timestamp
      ? new Date(dto.user.newest_reading_timestamp)
      : null,
    ranges: mapToRanges(dto.user),
    preferredUnits: mapToPreferredUnits(dto.user),
    preferredTimezone: dto.user.preferred_timezone || fallbackTimezone,
    email: '',
    dateOfBirth: null,
    notes: '',
    tags: [],
    addressLine1: '',
    addressLine2: '',
    city: '',
    countryCode: '',
    height: '',
    status: PatientStatus.APPROVED,
    displayInSharedPractice: false,
    externalEhrPartnerSystem: dto.ehr_practice.ehr_system.ehr_partner_system,
    externalPatientId: '',
  };

  if (isHcpPatientDto(dto)) {
    patient = {
      ...patient,
      email: dto.user.email,
      dateOfBirth: dto.user.date_of_birth
        ? new Date(dto.user.date_of_birth)
        : null,
      notes: dto.notes,
      tags: dto.tags,
      addressLine1: dto.user.address_line_1 ?? '',
      addressLine2: dto.user.address_line_2 ?? '',
      city: dto.user.city ?? '',
      countryCode: dto.user.country_code ?? '',
      height: dto.user.height ?? '',
      status: dto.patient_status,
      displayInSharedPractice: dto.display_in_shared_practice,
      externalPatientId: dto.external_patient_id,
    };
  }

  return patient;
};

export const usePatientQuery = patientQueryFactory({
  patientApi,
  fallbackTimezone: config.getFallbackTimezone(regionStore.getCurrent()),
});
