import { ReadingName } from 'domain/domain.models';

import { PreferredUnits, PreferredUnitsDto } from '../../types/preferred-units';

export const mapToPreferredUnits = (
  dto: PreferredUnitsDto
): PreferredUnits => ({
  [ReadingName.GLUCOSE_BLOOD]: dto.default_glucose_unit,
  [ReadingName.GLUCOSE_INTERSTITIAL]: dto.default_glucose_unit,
  [ReadingName.WEIGHT_MEASUREMENT]: dto.default_weight_unit,
  [ReadingName.WAIST_MEASUREMENT]: dto.default_waist_unit,
  [ReadingName.PROTEIN_INGESTION]: dto.default_protein_unit,
  [ReadingName.FAT_INGESTION]: dto.default_fat_unit,
  [ReadingName.CARBS_INGESTION]: dto.default_carbohydrates_unit,
  [ReadingName.VITAMIN_D_INGESTION]: dto.default_vitamin_d_unit,
});
