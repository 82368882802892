import maximum from 'lodash/max';

/**
 * For given reading range, calculates new threshold range, which is 10% wider than the original range.
 * Used mainly for assigning warning color palette to values in threshold range.
 */
export const getReadingThresholdRange = (range: [number, number]) => {
  const threshold = 0.1;
  const [min, max] = range;
  const rangeDiff = max - min;
  const rangeThreshold = rangeDiff * threshold;
  const lowerThreshold = maximum([min - rangeThreshold, 0]) as number;
  const upperThreshold = max + rangeThreshold;

  return [lowerThreshold, upperThreshold];
};
