import type {
  ReadingsParams,
  ReadingsStatsParams,
} from '../types/readings-request-params';

export const readingQueryKeys = {
  all: ['readings'] as const,
  lists: () => [...readingQueryKeys.all, 'list'] as const,
  list: (params: ReadingsParams) =>
    [...readingQueryKeys.lists(), params] as const,
  count: (params: ReadingsParams) =>
    [...readingQueryKeys.all, 'count', params] as const,
  stats: (params: ReadingsStatsParams) =>
    [...readingQueryKeys.all, 'stats', params] as const,
  predefinedTags: [{ scope: 'predefinedTags' }] as const,
  readingTypePredefinedTags: () =>
    [{ ...readingQueryKeys.predefinedTags[0] }] as const,
  practiecPredefinedTags: (params: { practiceId: string }) =>
    [{ ...readingQueryKeys.predefinedTags[0], ...params }] as const,
};

export const readingMutationKeys = {
  addReading: () => ['mutation', 'readings', 'add'] as const,
};
