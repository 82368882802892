import { AxiosError } from 'axios';
import { apiHttpInstance } from 'shared/common/api';
import { routes } from 'shared/common/routes';
import { saveAuthCookies } from 'shared/common/services/auth';
import { isSyncApp, syncAppHandlers } from 'shared/common/services/sync-app';
import { getCookie, getVersionedStorageKey, sleep } from 'shared/common/utils';
import { regionsMetadataStore } from 'shared/region-metadata/store';
import { userOauthApi } from 'shared/user/api';
import { userApiRoutes } from 'shared/user/consts';

import { LocalStorageKey } from 'consts';
import { CookieStorageKeys } from 'consts/cookie-storage-keys';

const REQUEST_RETRY_COUNT_CUSTOM_HEADER = 'X-Request-Retry-Count';

const refreshUserSession = async (
  refreshToken: string,
  scopes: string,
  oAuthClientId: string
) => {
  const response = await userOauthApi.refreshUserSession({
    refreshToken,
    scopes,
    oAuthClientId,
  });
  saveAuthCookies(response);
  return response.access_token;
};

let isRefreshingSession = false;

const WAIT_TIME_FOR_REFRESH_TOKEN_MS = 1000;
const excludedEndpoints: string[] = [
  userApiRoutes.oAuthToken,
  userApiRoutes.oAuthRevoke,
];
export const expiredSessionHandler = async (
  error: AxiosError,
  scopes: string
) => {
  const oAuthClientId =
    regionsMetadataStore.getCurrentRegionMetadata().properties.oauthClientId;

  const { response } = error;

  if (
    response?.status !== 401 ||
    excludedEndpoints.includes(response?.config?.url || '')
  ) {
    throw error;
  }

  // lets wait till the session is refreshed
  let hasBeenCalledDuringSessionRefresh = false;
  while (isRefreshingSession) {
    hasBeenCalledDuringSessionRefresh = true;
    await sleep(WAIT_TIME_FOR_REFRESH_TOKEN_MS);
  }

  const refreshToken = getCookie<string>(CookieStorageKeys.REFRESH_TOKEN);
  const accessToken = getCookie<string>(CookieStorageKeys.ACCESS_TOKEN);

  if (isSyncApp()) {
    syncAppHandlers.refreshSession();
    throw error;
  }

  if (!accessToken && !refreshToken) {
    window.location.href = routes.login;
    throw error;
  }

  // when embedded within sync app refresh of token is done on the backend
  if (!refreshToken) {
    window.location.href = routes.sessionExpired;
    throw error;
  }

  const retryCountHeaderValue =
    response.config.headers[REQUEST_RETRY_COUNT_CUSTOM_HEADER];

  // We want to retry only one time
  if (retryCountHeaderValue) {
    window.location.href = routes.sessionExpired;
    throw error;
  }

  const retryRequestWithNewAccessToken = (accessToken: string | null) => {
    response.config.headers.Authorization = `Bearer ${accessToken}`;
    response.config.headers[REQUEST_RETRY_COUNT_CUSTOM_HEADER] = true;
    return apiHttpInstance.request(response.config);
  };

  // if the request has been called during session refresh, we don't want to refresh session again
  if (hasBeenCalledDuringSessionRefresh) {
    const accessToken = getCookie<string>(CookieStorageKeys.ACCESS_TOKEN);
    return retryRequestWithNewAccessToken(accessToken);
  }

  try {
    isRefreshingSession = true;
    const accessToken = await refreshUserSession(
      refreshToken,
      scopes,
      oAuthClientId
    );
    return retryRequestWithNewAccessToken(accessToken).finally(
      () => (isRefreshingSession = false)
    );
  } catch (error) {
    localStorage.removeItem(
      getVersionedStorageKey(LocalStorageKey.OAUTH_SCOPES)
    );
    window.location.href = routes.sessionExpired;
    throw error;
  }
};
