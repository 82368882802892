import { useQuery } from 'react-query';
import { minutesToMilliseconds } from 'date-fns';
import { partnersConnectionStatusQueryKeys } from 'shared/partner/consts/partner-query-keys';
import { PromiseType } from 'utility-types';

import { userApi } from '../../api';

export const partnerConnectionStatusQueryFactory =
  (dependancies: { userApi: typeof userApi }) =>
  ({ enabled, partnerName }: { enabled?: boolean; partnerName: string }) => {
    const { userApi } = dependancies;

    return useQuery({
      queryKey: partnersConnectionStatusQueryKeys.detail(partnerName),
      queryFn: () => userApi.getPartnerConnectionStatus(partnerName),

      staleTime: minutesToMilliseconds(10),
      enabled: enabled ?? true,
      select: mapToPartnerConnectionStatus,
    });
  };

export const usePartnerConnectionStatusQuery =
  partnerConnectionStatusQueryFactory({
    userApi,
  });

const mapToPartnerConnectionStatus = (
  dto: PromiseType<ReturnType<typeof userApi.getPartnerConnectionStatus>>
) => ({
  connectionStatus: dto.credentials_status,
  lastSyncTimestamp: dto.last_sync_timestamp
    ? new Date(dto.last_sync_timestamp)
    : null,
  lastSyncFailTimestamp: dto.last_sync_fail_timestamp
    ? new Date(dto.last_sync_fail_timestamp)
    : null,
});
