import { LocalStorageKey } from 'consts';
import { SupportedLanguageCode } from 'consts/supported-language-code';

import { localStorage } from '../storages/local-storage';

export const getUserLanguageCode = (): SupportedLanguageCode => {
  const savedLanguageCode =
    localStorage.getItem<string>(LocalStorageKey.LANGUAGE_CODE) ||
    SupportedLanguageCode.EN;

  const formattedLanguageCode = savedLanguageCode.toLowerCase().split('-')[0];

  return isSupportedLanguageCode(formattedLanguageCode)
    ? formattedLanguageCode
    : SupportedLanguageCode.EN;
};

const isSupportedLanguageCode = (code: any): code is SupportedLanguageCode =>
  Object.values(SupportedLanguageCode).includes(code);
