import { ReactNode } from 'react';

export const getCategorizedMenuItems = (
  items: React.ReactElement<{
    category: string;
    children: ReactNode;
  }>[]
) =>
  items.reduce((acc, cur) => {
    const category = cur.props.category;

    acc[category] = [...(acc[category] || []), cur];

    return acc;
  }, {} as Record<string, ReactNode[]>);
