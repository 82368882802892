import {
  Action,
  createActionsHook,
  createContainer,
  createStateHook,
  createStore,
} from 'react-sweet-state';

type State = { supportedUserId: string | null };
type Actions = typeof actions;

const initialState: State = {
  supportedUserId: null,
};

const actions = {
  initializeAuthorizedSupportAccess:
    (supportedUserId: string): Action<State> =>
    ({ setState }) => {
      setState({
        supportedUserId,
      });
    },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
});

export const useAuthorizedSupportAccessStateActions = createActionsHook(Store);

export const useAuthorizedSupportAccessState = createStateHook(Store);
export const useIsAuthorizedSupportAccessInitialized = createStateHook(Store, {
  selector: (state) => !!state.supportedUserId,
});

export const AuthorizedSupportAccessStateContainer = createContainer(Store, {
  onInit:
    () =>
    ({ setState }, { initialState }: { initialState?: State } = {}) => {
      if (initialState) {
        setState(initialState);
      }
    },
});
