import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TextTruncatedMobileProps {
  text: string;
  visibleLength?: number;
  as?: React.ElementType;
  className?: string;
}

export const TextTruncatedMobile: React.VFC<TextTruncatedMobileProps> = ({
  text,
  visibleLength = text.length,
  as = 'p',
  className = '',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const Component = as;
  const showExpander = visibleLength < text.length;

  return (
    <Component className={className}>
      {text.slice(0, isExpanded ? Infinity : visibleLength)}
      {showExpander && (
        <>
          {!isExpanded && <span>...</span>}
          {isExpanded ? (
            <button
              onClick={() => setIsExpanded(false)}
              className="ml-2 font-bold"
              type="button"
            >
              {t('less')}
            </button>
          ) : (
            <button
              onClick={() => setIsExpanded(true)}
              className="ml-2 font-bold"
              type="button"
            >
              {t('more')}
            </button>
          )}
        </>
      )}
    </Component>
  );
};
