import { apiHttpClient as http } from 'shared/common/api';

import { practiceApiRoutes } from '../consts/practice-api-routes';
import { AffiliateLinkDto } from '../types';

export const practiceAffiliateLinkApiFactory = (httpClient: typeof http) => ({
  getPracticeAffiliateLink: async ({ practiceId }: { practiceId: string }) => {
    try {
      const res = await httpClient<AffiliateLinkDto[]>(
        practiceApiRoutes.practiceAffiliateLinks,
        {
          method: 'GET',
          pathParams: { practiceId },
        }
      );
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return null;
      } else {
        throw e;
      }
    }
  },

  createPracticeAffiliateLink: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: AffiliateLinkDto;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceAffiliateLinks, {
      method: 'POST',
      pathParams: { practiceId },
      data,
    });

    return res.data;
  },

  updatePracticeAffiliateLink: async ({
    practiceId,
    linkId,
    data,
  }: {
    practiceId: string;
    linkId: string;
    data: AffiliateLinkDto;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceAffiliateLink, {
      method: 'PATCH',
      pathParams: { practiceId, linkId },
      data,
    });

    return res.data;
  },

  deletePracticeAffiliateLink: async ({
    practiceId,
    linkId,
  }: {
    practiceId: string;
    linkId: string;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceAffiliateLink, {
      method: 'DELETE',
      pathParams: { practiceId, linkId },
    });

    return res.data;
  },
});
