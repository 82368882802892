import { useQuery } from 'react-query';

import { practiceApi } from '../api';
import { practiceQueryKeys } from '../consts';
import { mapToPractice } from '../utils/map-to-practice';

const hcpPracticesQueryFactory =
  (dependancies: { practiceApi: typeof practiceApi }) =>
  ({ enabled = true }: { enabled?: boolean } = {}) => {
    const { practiceApi } = dependancies;

    const query = useQuery(
      practiceQueryKeys.list(),
      async () => practiceApi.getPractices(),
      {
        enabled,
      }
    );

    return {
      ...query,
      data: query.data ? query.data.map(mapToPractice) : [],
    };
  };

/**
 * Practices where logged in user is HCP
 */
export const useHcpPracticesQuery = hcpPracticesQueryFactory({
  practiceApi,
});
