import i18next from 'i18next';

import { PatientStatus } from '../types';

export const patientStatusTexts: Record<PatientStatus, () => string> = {
  [PatientStatus.APPROVED]: () => i18next.t('Approved'),
  [PatientStatus.EXPIRED]: () => i18next.t('Expired'),
  [PatientStatus.INVITATION_REJECTED]: () => i18next.t('Invitation Rejected'),
  [PatientStatus.INVITED]: () => i18next.t('Invited'),
  [PatientStatus.PENDING_APPROVAL]: () => i18next.t('Pending Approval'),
  [PatientStatus.REJECTED]: () => i18next.t('Rejected'),
  [PatientStatus.EHR_CONNECTED]: () => i18next.t('EHR Connected'),
};
