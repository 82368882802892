import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import { colors } from 'consts';

import { useIsDarkMode } from './dark-mode.store';

import 'react-loading-skeleton/dist/skeleton.css';

interface SkeletonThemeProviderProps {}

export const SkeletonThemeProvider: React.FC<SkeletonThemeProviderProps> = ({
  children,
}) => {
  const isDarkMode = useIsDarkMode();
  const baseColor = isDarkMode ? colors.gray['700'] : colors.gray['200'];
  const highlightColor = isDarkMode ? colors.gray['600'] : colors.gray['100'];
  return <SkeletonTheme {...{ baseColor, highlightColor, children }} />;
};
