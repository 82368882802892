import { useEffect, useMemo, useState } from 'react';

export const useWatchMediaQuery = (
  breakPoint: valueof<typeof MIN_MEDIA_QUERIES>
) => {
  const wm = useMemo(() => window.matchMedia(breakPoint), [breakPoint]);
  const [matches, setMatches] = useState(wm.matches);

  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      const matches = event.matches;
      setMatches(matches);
    };

    wm.addEventListener('change', handler);

    return () => {
      wm.removeEventListener('change', handler);
    };
  }, [wm]);

  return matches;
};

export enum BREAKPOINTS {
  XS = 400,
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export const MIN_MEDIA_QUERIES = {
  XS: `(min-width: ${BREAKPOINTS.XS}px)`,
  SM: `(min-width: ${BREAKPOINTS.SM}px)`,
  MD: `(min-width: ${BREAKPOINTS.MD}px)`,
  LG: `(min-width: ${BREAKPOINTS.LG}px)`,
  XL: `(min-width: ${BREAKPOINTS.XL}px)`,
  XXL: `(min-width: ${BREAKPOINTS.XXL}px)`,
} as const;
