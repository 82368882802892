import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import OverlayLoading from 'components/OverlayLoading/OverlayLoading';

import { useRegionsMetadataQuery } from '../queries';

export const RegionsMetadataGuard: FC = ({ children }) => {
  const { t } = useTranslation();
  const { isLoading, isError, isSuccess } = useRegionsMetadataQuery();

  if (isLoading) {
    return <OverlayLoading heading={t('Please wait')} />;
  }

  if (isError) {
    throw new Error(t('Regions metadata query failed'));
  }

  if (isSuccess) {
    return <>{children}</>;
  }

  return null;
};
