import { FC } from 'react';
import clsx from 'clsx';
import { trimTagLabel } from 'shared/reading/utils';

import Badge from 'components/Badge/Badge';

interface TagsListProps {
  tags: string[];
  classes?: {
    wrapper?: string;
  };
}

export const TagsList: FC<TagsListProps> = ({ tags, classes }) => (
  <div className={clsx('flex flex-wrap gap-2', classes?.wrapper)}>
    {tags.map((tag) => (
      <Badge
        key={tag}
        className="bg-gray-200 text-gray-600 dark:bg-gray-600 dark:text-gray-300"
      >
        {trimTagLabel(tag)}
      </Badge>
    ))}
  </div>
);
