import {
  Action,
  ContainerComponent,
  createActionsHook,
  createContainer,
  createHook,
  createStateHook,
  createStore,
  defaults,
} from 'react-sweet-state';
import { mapValues } from 'lodash';
import { getVersionedStorageKey, localStorage } from 'shared/common/utils';

import { LocalStorageKey, VersionedLocalStorageKey } from 'consts';
import { DomainConfigs, ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';
import { StatType } from 'models';

import { storeSideEffectMiddleware } from '../../utils';

const storeName = 'dashboardReport';
let localStorageKey: VersionedLocalStorageKey;

export interface DashboardReportState {
  isDashboardReportAdvancedMode: boolean;
  selectedDialChartReadingName: ReadingName | null;
  selectedMetricsStatTypes: {
    [key in ReadingName]: StatType.MAX | StatType.MIN | StatType.AVG;
  };
}
type PersistedState = {
  isDashboardReportAdvancedMode: boolean;
};

const initialState: DashboardReportState = {
  isDashboardReportAdvancedMode: true,
  selectedDialChartReadingName: null,
  selectedMetricsStatTypes: mapValues<
    DomainConfigs,
    StatType.MAX | StatType.MIN | StatType.AVG
  >(getDomainConfigs(), () => StatType.AVG),
};

const actions = {
  toggleDashboardReportAdvancedMode:
    (): Action<DashboardReportState> =>
    ({ setState, getState }) => {
      setState({
        isDashboardReportAdvancedMode:
          !getState().isDashboardReportAdvancedMode,
      });
    },
  setSelectedDialChartReadingName:
    (readingName: ReadingName | null): Action<DashboardReportState> =>
    ({ setState }) => {
      setState({
        selectedDialChartReadingName: readingName,
      });
    },
  setSelectedMetricsStatType:
    (
      config: Partial<DashboardReportState['selectedMetricsStatTypes']>
    ): Action<DashboardReportState> =>
    ({ setState, getState }) => {
      const { selectedMetricsStatTypes } = getState();
      setState({
        selectedMetricsStatTypes: {
          ...selectedMetricsStatTypes,
          ...config,
        },
      });
    },
};

type Actions = typeof actions;

const store = createStore<DashboardReportState, Actions>({
  name: storeName,
  initialState,
  actions,
});

const useDashboardActions = createActionsHook(store);
const useDashboardReportState = createStateHook(store);
const useDashboardReport = createHook(store);

const DashboardReportContainer: ContainerComponent<{
  activeReadingNames: ReadingName[];
  mockInitialState?: Partial<DashboardReportState>;
}> = createContainer(store, {
  onInit:
    () =>
    ({ setState }, { mockInitialState }) => {
      localStorageKey = getVersionedStorageKey(
        LocalStorageKey.DASHBOARD_REPORT_STATE
      );

      const cachedState = localStorage.getItem<PersistedState>(localStorageKey);

      const newState = {
        ...initialState,
        ...cachedState,
        ...(mockInitialState ?? {}),
      };

      setState(newState);
    },
  onUpdate:
    () =>
    ({ setState }) => {
      setState({
        selectedDialChartReadingName: null,
      });
    },
});

defaults.middlewares.add(
  storeSideEffectMiddleware(storeName, (state: DashboardReportState) => {
    localStorage.setItem(localStorageKey, {
      isDashboardReportAdvancedMode: state.isDashboardReportAdvancedMode,
    });
  })
);

export {
  useDashboardActions,
  useDashboardReportState,
  useDashboardReport,
  DashboardReportContainer,
};
