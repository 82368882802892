import { FC } from 'react';
import { Transition } from '@headlessui/react';

export const OpacityTransition: FC<any> = ({
  children,
  className,
  as = 'div',
  ...props
}) => (
  <Transition
    as={as}
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
    className={className}
    {...props}
  >
    {children}
  </Transition>
);
