import { useState } from 'react';
import { setDefaultLocale as setDatePickerDefaultLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { getUserLanguageCode } from 'shared/common/utils';

import { SupportedLanguageCode } from 'consts/supported-language-code';

export const useLanguageSelect = () => {
  const { i18n } = useTranslation();
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(
    getUserLanguageCode()
  );

  const handleOnLanguageCodeSelect = (code: SupportedLanguageCode) => {
    setSelectedLanguageCode(code);

    i18n.changeLanguage(code);
    setDatePickerDefaultLocale(code);
  };

  return {
    selectedLanguageCode,
    handleOnLanguageCodeSelect,
  };
};
