import { Fragment, KeyboardEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { getDataPrivateAttr } from 'shared/common/utils';

import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

import { SELECT_ARIA_CONTROLS } from '../../forms.consts';
import { OptionItem } from '../option-item/OptionItem';

import { getDropdownCategorizedOptions } from './utils/get-dropdown-categorized-options';
import { getFilteredDropdownOptions } from './utils/get-filtered-dropdown-options';
import { getIsDropdownOptionSelected } from './utils/get-is-dropdown-option-selected';
import { useFocusNavigation } from './utils/use-focus-navigation';

import styles from './DropdownList.module.scss';

export interface DropdownListProps<T> {
  onSelect?: (value: T) => void;
  onToggleSelectAll?: () => void;
  selected?: T[] | T | null;
  options: DropdownOptionItem<T>[];
  isMultiselect?: boolean;
  maxSelectedCount?: number;
  withSearch?: boolean;
  withCategories?: boolean;
  withFocusOnMount?: boolean;
  withSelectAllOption?: boolean;
  renderAdditionalListContent?: () => JSX.Element;
  ['data-testid']?: string;
  ['data-private']?: boolean;
  className?: string;
}

export const DropdownList = <T,>({
  onSelect,
  onToggleSelectAll,
  selected = null,
  options,
  isMultiselect = false,
  maxSelectedCount,
  withSearch = false,
  withCategories = false,
  withSelectAllOption = true,
  renderAdditionalListContent,
  withFocusOnMount = true,
  className,
  ...props
}: DropdownListProps<T>) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(
    () => getFilteredDropdownOptions(query, options),
    [options, query]
  );

  const categorizedOptions = useMemo(() => {
    if (!withCategories) return [];
    return getDropdownCategorizedOptions(filteredOptions);
  }, [filteredOptions, withCategories]);

  const {
    listRef,
    searchRef,
    listContainerRef,
    handleFocusKeyNav,
    handleOptionKeyNav,
  } = useFocusNavigation<T>({
    withSearch,
    withFocusOnMount,
    onSelect,
  });

  const handleItemClick = (option: DropdownOptionItem<T>) => {
    if (getIsOptionDisabled(option)) return;

    option.onSelect?.(option.value);
    onSelect?.(option.value);
  };

  const handleItemKeyDown = (
    option: DropdownOptionItem<T>,
    e: KeyboardEvent<HTMLLIElement>
  ) => {
    if (getIsOptionDisabled(option)) return;
    handleOptionKeyNav(option.value, option.onSelect, e);
  };

  const getIsOptionDisabled = (option: DropdownOptionItem<T>) => {
    if (option.isDisabled) return true;

    if (
      maxSelectedCount &&
      Array.isArray(selected) &&
      selected.length >= maxSelectedCount &&
      !getIsDropdownOptionSelected(option, selected)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div
      className={clsx('space-y-3', className, 'dropdown-list')}
      ref={listContainerRef}
    >
      {renderAdditionalListContent?.()}
      {withSearch && (
        <div className="relative">
          <input
            value={query}
            ref={searchRef}
            onChange={(e) => setQuery(e.target.value)}
            className={styles.search}
            placeholder={t('Search')}
            aria-autocomplete="list"
          />
          <div className={clsx(styles.iconWrapper, styles.isWithIcon)}>
            <SearchIcon aria-hidden="true" />
          </div>
        </div>
      )}
      {isMultiselect && !query && !maxSelectedCount && withSelectAllOption && (
        <button
          type="button"
          onClick={() => onToggleSelectAll?.()}
          className={clsx(styles.optionWrapper, 'w-full')}
        >
          <OptionItem
            value={null}
            label={t('Select All')}
            isMultiselect={true}
            isSelected={
              !!(
                selected &&
                Array.isArray(selected) &&
                selected.length === options.length
              )
            }
            className={styles.option}
            data-testid={props['data-testid']}
          />
        </button>
      )}
      <ul
        id={SELECT_ARIA_CONTROLS}
        role="listbox"
        ref={listRef}
        onKeyDown={handleFocusKeyNav}
        data-testid={`${props['data-testid']}__listbox`}
      >
        {isEmpty(options) ? (
          <span className={styles.noOptionLabel}>
            {t('No options available')}
          </span>
        ) : withCategories ? (
          categorizedOptions.map(([category, options]) => (
            <Fragment key={category}>
              <h5 className={styles.categoryHeading}>{category}</h5>
              {options.map((option, idx) => (
                <li
                  key={idx}
                  onClick={() => handleItemClick(option)}
                  onKeyDown={(e) => handleItemKeyDown(option, e)}
                  tabIndex={getIsOptionDisabled(option) ? -1 : 0}
                  className={clsx(
                    styles.optionWrapper,
                    getIsOptionDisabled(option) && styles.isDisabled
                  )}
                >
                  <OptionItem
                    {...option}
                    isMultiselect={isMultiselect}
                    isSelected={getIsDropdownOptionSelected(option, selected)}
                    className={styles.option}
                    data-testid={props['data-testid']}
                    isDisabled={getIsOptionDisabled(option)}
                  />
                </li>
              ))}
            </Fragment>
          ))
        ) : (
          filteredOptions.map((option, idx) => (
            <li
              key={idx}
              onClick={() => handleItemClick(option)}
              onKeyDown={(e) => handleItemKeyDown(option, e)}
              tabIndex={getIsOptionDisabled(option) ? -1 : 0}
              className={clsx(
                styles.optionWrapper,
                getIsOptionDisabled(option) && styles.isDisabled
              )}
            >
              <OptionItem
                {...option}
                isMultiselect={isMultiselect}
                isSelected={getIsDropdownOptionSelected(option, selected)}
                className={clsx(
                  'option-item',
                  styles.option,
                  getIsDropdownOptionSelected(option, selected)
                    ? 'option-item-selected'
                    : ''
                )}
                data-testid={props['data-testid']}
                {...getDataPrivateAttr(props['data-private'])}
              />
            </li>
          ))
        )}
      </ul>
    </div>
  );
};
