export { colors } from 'consts/colors/colors';
export * from './environment';
export * from './local-storage-key';
export * from './phone-number-regexp';
export * from './user-region';
export * from './web-url-regexp';

/*
    to which time range charts using /readings endpoint should be displayed
*/
export const READINGS_CHART_DAY_TRESHOLD = 28;
export const CONTINUOUS_READINGS_CHART_DAY_TRESHOLD = 14;
/*
  Maximum amount of readings supported in the table
*/
export const MAX_TABLE_SUPPORT_READINGS_COUNT = 10000;
export const SUPPORT_EMAIL = 'support@keto-mojo.com';
/*
  Threshold start date for fetching readings, when oldest reading date cannot be resolved
*/
export const READINGS_START_DATE_THRESHOLD = new Date(2015, 0, 1);

/*
  Threshold end date for fetching readings, when newest reading date cannot be resolved
*/
export const READINGS_END_DATE_THRESHOLD = new Date(2035, 0, 1);

/*
  Charts consts
*/
export const LINE_CHARTS_HEIGHT = 400;

// External links
export const KETOSIS_DIFFERENCE_LINK =
  'https://keto-mojo.com/article/testing-for-ketosis-difference-between-blood-breath-urine/';
export const KETO_FACEBOOK_LINK = 'https://www.facebook.com/misterketomojo';
export const KETO_INSTAGRAM_LINK = 'https://www.instagram.com/ketomojo/';
export const KETO_TWITTER_LINK = 'https://twitter.com/KetoMojo';
export const TERMS_AND_CONDITIONS_LINK =
  'https://keto-mojo.com/terms-of-service/';
export const EPIC_MY_CHART_LOGIN_LINK =
  'https://apporchard.epic.com/mychart-aocurprd/Home/';
export const NO_READINGS_VIDEO_URL =
  'https://www.youtube.com/embed/Icsmm4OJvfI?si=28rNdAbQDREPx3xS';
export const EHRS_LINK = 'https://keto-mojo.com/mymojohealth/#EHR';
export const KM_VIDEOS_EU_LINK =
  'https://keto-mojo.com/topics/how-to-videos-europe/';
export const KM_VIDEOS_US_LINK = 'https://keto-mojo.com/topics/how-to-series/';
export const KM_HCP_VIDEOS_LINK =
  'https://keto-mojo.com/topics/mymojohealth-for-practitioners/';
export const KM_AFFILIATES_LINK = 'https://keto-mojo.com/affiliate-partners/';
export const REFERSION_LOGIN_LINK = 'https://www.refersion.com/affiliate/login';
export const CLIENT_APPLICATION_HEADER_NAME = 'X-Client-Application';
export const CLIENT_VERSION_HEADER_NAME = 'X-Client-Version';
