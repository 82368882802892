export interface IGetMailToLinkParams {
  subject?: string;
  cc?: string;
}

export const getMailtoLink = (
  mail: string,
  params: IGetMailToLinkParams = {}
) => {
  let url = `mailto:${mail}`;
  if (Object.keys(params).length) {
    url += `?`;
    url = Object.entries(params).reduce(
      (urlWithParams, [paramName, value]) =>
        urlWithParams + `${paramName}=${value}&`,
      url
    );
    // remove trailing &
    url = url.slice(0, -1);
  }

  return url;
};
