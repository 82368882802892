/*
    Use it when you're tired of dealing with safari bs.
*/
export const getIsSafari = (): boolean => {
  const safariCheck = (window as any)['safari'];
  return (
    /constructor/i.test(window.HTMLElement as any) ||
    ((p: any) => p.toString() === '[object SafariRemoteNotification]')(
      !safariCheck ||
        (typeof safariCheck !== 'undefined' && safariCheck.pushNotification)
    )
  );
};
