import { isGkrReadingType } from 'domain/domain.utils';

import { GkrReading, Reading } from '../types';

export const assertGkrReading: (
  reading: Reading
) => asserts reading is GkrReading = (reading) => {
  if (!isGkrReadingType(reading.type)) {
    throw new Error(`Expected reading to be a GKI/DBR reading`);
  }
};
