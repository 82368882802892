import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'shared/common/components';

interface TextTruncatedDesktopProps {
  text: string;
  visibleLength?: number;
  as?: React.ElementType;
  className?: string;
}

export const TextTruncatedDesktop: React.VFC<TextTruncatedDesktopProps> = ({
  text,
  visibleLength = text.length,
  as = 'p',
  className = '',
}) => {
  const { t } = useTranslation();

  const Component = as;
  const showExpander = visibleLength < text.length;

  return (
    <>
      <Component className={className}>
        {text.slice(0, visibleLength)}
        {showExpander && <>...</>}
      </Component>
      {showExpander && (
        <>
          <Popover
            popperOptions={{
              placement: 'bottom',
            }}
            renderTrigger={({ open, ref }) => (
              <button
                ref={ref}
                onClick={() => open()}
                className="font-bold"
                type="button"
              >
                {t('more')}
              </button>
            )}
          >
            <div className="max-w-112 p-4">
              <p
                className="paragraph"
                data-testid="text-truncated-popover-text"
              >
                {text}
              </p>
            </div>
          </Popover>
        </>
      )}
    </>
  );
};
