import { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { routes } from 'shared/common/routes';

import { permissionGuards } from '../../consts/permission-guards';

export const AuthorizationControl = <T extends keyof typeof permissionGuards>({
  children,
  permission,
  params,
}: {
  children: ReactNode;
  permission: T;
  params: Parameters<typeof permissionGuards[T]>[0];
}) => {
  const isAuthorized = permissionGuards[permission](params as any);
  return <>{isAuthorized ? children : null}</>;
};

export const AuthorizedRoute = <T extends keyof typeof permissionGuards>({
  permission,
  params,
  ...props
}: {
  permission: T;
  params: Parameters<typeof permissionGuards[T]>[0];
} & RouteProps) => {
  const isAuthorized = permissionGuards[permission](params as any);
  return isAuthorized ? (
    <Route {...props} />
  ) : (
    <Redirect to={routes.notFound} />
  );
};
