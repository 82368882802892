import { SearchPatientInExternalEhrData } from '../types';

export const patientQueryKeys = {
  all: ['patients'] as const,
  lists: () => [...patientQueryKeys.all, 'list'] as const,
  list: (practiceId: string) =>
    [...patientQueryKeys.all, 'list', practiceId] as const,
  details: () => [...patientQueryKeys.all, 'detail'] as const,
  detail: ({
    practiceId,
    patientUserId,
  }: {
    practiceId: string;
    patientUserId: string;
  }) => [...patientQueryKeys.details(), practiceId, patientUserId] as const,
  externalEhrPatientSearch: ({
    practiceId,
    searchData,
  }: {
    practiceId: string;
    searchData: SearchPatientInExternalEhrData;
  }) => ['externalEhrPatientSearch', practiceId, searchData] as const,
};
