import { apiHttpClient as http } from 'shared/common/api';
import { ReadingsParams } from 'shared/reading/types';

import { patientApiRoutes } from '../consts/patient-api-routes';
import { InvitePatientsDto, PatientDto, PatientOrRequestDto } from '../types';
import {
  ExternalEhrPatientSearchResultDto,
  SearchPatientInExternalEhrParams,
} from '../types/external-ehr-patient-search';

export const patientApiFactory = (httpClient: typeof http) => ({
  getPatient: async ({
    practiceId,
    patientUserId,
  }: {
    practiceId: string;
    patientUserId: string;
  }) => {
    const res = await httpClient<PatientDto>(patientApiRoutes.patient, {
      method: 'GET',
      pathParams: { practiceId, patientUserId },
    });

    return res.data;
  },
  getPatients: async ({ practiceId }: { practiceId: string }) => {
    const res = await httpClient<PatientOrRequestDto[]>(
      patientApiRoutes.patients,
      {
        method: 'GET',
        pathParams: { practiceId },
      }
    );

    return res.data;
  },

  updatePatient: async ({
    practiceId,
    patientUserId,
    data,
  }: {
    practiceId: string;
    patientUserId: string;
    data: {
      send_data_to_external_ehr?: boolean;
      display_in_shared_practice?: boolean;
      external_patient_id?: string;
      notes?: string;
      tags?: string[];
    };
  }) => {
    const res = await httpClient(patientApiRoutes.patient, {
      method: 'PATCH',
      pathParams: { practiceId, patientUserId },
      data,
    });

    return res.data;
  },

  invitePatients: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: InvitePatientsDto;
  }) => {
    const res = await httpClient(patientApiRoutes.patients, {
      method: 'PUT',
      pathParams: { practiceId },
      data,
    });

    return res.data;
  },

  removePatientRequests: async ({
    practiceId,
    patientRequestIds,
  }: {
    practiceId: string;
    patientRequestIds: string[];
  }) => {
    const res = await httpClient(patientApiRoutes.patientRequests, {
      method: 'DELETE',
      pathParams: { practiceId },
      params: { ehrPatientRequestId: patientRequestIds },
    });

    return res.data;
  },

  approvePatientRequests: async ({
    practiceId,
    patientRequestId: requestId,
  }: {
    practiceId: string;
    patientRequestId: string;
  }) => {
    const res = await httpClient(patientApiRoutes.patientRequest, {
      method: 'PUT',
      pathParams: { practiceId, requestId },
      data: {
        action: 'approve',
      },
    });

    return res.data;
  },

  rejectPatientRequests: async ({
    practiceId,
    patientRequestId: requestId,
  }: {
    practiceId: string;
    patientRequestId: string;
  }) => {
    const res = await httpClient(patientApiRoutes.patientRequest, {
      method: 'PUT',
      pathParams: { practiceId, requestId },
      data: {
        action: 'reject',
      },
    });

    return res.data;
  },

  removePatients: async ({
    practiceId,
    patientUserIds,
  }: {
    practiceId: string;
    patientUserIds: string[];
  }) => {
    const res = await httpClient(patientApiRoutes.patients, {
      method: 'DELETE',
      pathParams: { practiceId },
      params: { userId: patientUserIds },
    });

    return res.data;
  },

  removePatient: async ({
    practiceId,
    patientUserId,
  }: {
    practiceId: string;
    patientUserId: string;
  }) => {
    const res = await httpClient(patientApiRoutes.patient, {
      method: 'DELETE',
      pathParams: { practiceId, patientUserId },
    });

    return res.data;
  },

  movePatients: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: {
      targetPracticeId: string;
      patientUserIds: string[];
    };
  }) => {
    const res = await httpClient(patientApiRoutes.patients, {
      method: 'PUT',
      pathParams: { practiceId },
      data: {
        action: 'move_patients',
        data: {
          target_practice: data.targetPracticeId,
          users: data.patientUserIds,
          bulk_operation_type: 'selected',
        },
      },
    });

    return res.data;
  },

  copyPatients: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: {
      targetPracticeId: string;
      patientUserIds: string[];
    };
  }) => {
    const res = await httpClient(patientApiRoutes.patients, {
      method: 'PUT',
      pathParams: { practiceId },
      data: {
        action: 'copy_patients',
        data: {
          target_practice: data.targetPracticeId,
          users: data.patientUserIds,
          bulk_operation_type: 'selected',
        },
      },
    });

    return res.data;
  },

  sendMessageToPatients: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: {
      subject: string;
      message: string;
      patientUserIds: string[];
    };
  }) => {
    const res = await httpClient(patientApiRoutes.patients, {
      method: 'PUT',
      pathParams: { practiceId },
      data: {
        action: 'send_email',
        data: {
          subject: data.subject,
          message: data.message,
          users: data.patientUserIds,
        },
      },
    });

    return res.data;
  },

  exportPatientsData: async ({
    practiceId,
    params,
  }: {
    practiceId: string;
    params: ReadingsParams & {
      patientUserIds: string[];
      includeTags: boolean;
      personalDetails: boolean;
    };
  }) => {
    const { patientUserIds, ...rest } = params;
    const res = await httpClient(patientApiRoutes.patientsDataExport, {
      method: 'GET',
      pathParams: { practiceId },
      params: {
        detailedExport: true,
        deleted: false,
        userId: patientUserIds,
        ...rest,
      },
    });

    return res.data;
  },

  searchPatientInExternalEhr: async ({
    practiceId,
    params,
  }: {
    practiceId: string;
    params: SearchPatientInExternalEhrParams;
  }) => {
    const res = await httpClient<ExternalEhrPatientSearchResultDto[]>(
      patientApiRoutes.externalEhrPatientSearch,
      {
        method: 'GET',
        pathParams: { practiceId },
        params,
      }
    );

    return res.data;
  },
});
