import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { routes } from 'shared/common/routes';
import { getVersionedStorageKey, localStorage } from 'shared/common/utils';

import { LocalStorageKey } from 'consts';

export const usePersistLastVisitedPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      ![
        routes.root,
        routes.login,
        routes.oauthLanding,
        routes.notFound,
        routes.termsAndConditions,
        routes.privacyPolicy,
        routes.cookiePolicy,
        routes.logout,
        routes.sessionExpired,
      ].includes(pathname)
    )
      localStorage.setItem(
        getVersionedStorageKey(LocalStorageKey.LAST_VISITED_PAGE),
        pathname
      );
  }, [pathname]);
};
