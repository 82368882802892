import i18next from 'i18next';

import { StatType } from 'models';

export const getStatTypeLabel = ({
  statType,
  isCapitalized = false,
}: {
  statType:
    | StatType.AVG
    | StatType.MIN
    | StatType.MAX
    | StatType.STD
    | StatType.SUM;
  isCapitalized?: boolean;
}) => {
  const statTypeLabels = {
    [StatType.MIN]: i18next.t('minimum'),
    [StatType.MAX]: i18next.t('maximum'),
    [StatType.AVG]: i18next.t('average'),
    [StatType.SUM]: i18next.t('sum'),
    [StatType.STD]: i18next.t('standard deviation'),
  } as const;

  return isCapitalized
    ? statTypeLabels[statType].charAt(0).toUpperCase() +
        statTypeLabels[statType].slice(1)
    : statTypeLabels[statType];
};
