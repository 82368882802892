import { ElementType, FC, ReactNode, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';

import { useOutsideClick, useTooltipContainer } from './hooks';

interface TooltipProps {
  content: ReactNode;
  onHover?: boolean;
  as?: ElementType;
  popperOptions?: PopperOptions;
  classes?: {
    wrapper?: string;
    button?: string;
    panel?: string;
  };
}

export const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  as = 'button',
  onHover = false,
  popperOptions = {},
  classes = {},
}) => {
  const PopoverButton = as;

  const [open, setOpen] = useState(false);
  const tooltipContainer = useTooltipContainer();

  const [popoverButtonRef, setPopoverButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  useOutsideClick(setOpen, [popoverButtonRef, popperElement]);

  const { styles, attributes } = usePopper(
    popoverButtonRef,
    popperElement,
    popperOptions
  );

  const handleOnMouseEnter = useCallback(() => {
    if (onHover) setOpen(true);
  }, [onHover]);

  const handleOnMouseLeave = useCallback(() => {
    if (onHover) setOpen(false);
  }, [onHover]);

  return (
    <Popover className={clsx(classes.wrapper)}>
      <PopoverButton
        ref={setPopoverButtonRef}
        className={clsx(classes.button)}
        onClick={() => setOpen((v) => !v)}
        type="button"
        onMouseEnter={() => handleOnMouseEnter()}
        onMouseLeave={() => handleOnMouseLeave()}
      >
        {children}
      </PopoverButton>
      {open &&
        createPortal(
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            static
            data-testid="tooltip"
            className={clsx(
              'z-40 max-w-44 overflow-hidden whitespace-normal rounded bg-gkiGrey-900 p-2 text-left text-xs text-white',
              classes.panel
            )}
          >
            {content}
          </Popover.Panel>,
          tooltipContainer
        )}
    </Popover>
  );
};

type PopperOptions = Parameters<typeof usePopper>[2];
