import { useQuery } from 'react-query';

import { practiceApi } from '../api';
import { practiceQueryKeys } from '../consts';
import { mapToPractice } from '../utils/map-to-practice';

const practiceQueryFactory =
  (dependancies: { practiceApi: typeof practiceApi }) =>
  (practiceId: string, params: { withTags?: boolean } = {}) => {
    const { practiceApi } = dependancies;

    return useQuery(practiceQueryKeys.detail(practiceId), async () => {
      const data = await practiceApi.getPractice(practiceId, params);
      return mapToPractice(data);
    });
  };

export const usePracticeQuery = practiceQueryFactory({
  practiceApi,
});
