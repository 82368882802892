export enum SyncAppErrors {
  COOKIE_NOT_SET = 'cookieNotSet',
}

export enum SyncAppMessage {
  USER_LOGOUT = 'userLogout',
  REFRESH_SESSION = 'refreshSession',
  ERROR = 'error',
  CONTENT_LOADED = 'contentLoaded',
}
