import { apiHttpClient as http } from 'shared/common/api';

import { practiceApiRoutes } from '../consts/practice-api-routes';
import {
  PracticeRangeDescriptionDataDto,
  PracticeRangeDescriptionDto,
} from '../types';

export const practiceRangeDescriptionApiFactory = (
  httpClient: typeof http
) => ({
  getCustomPracticeRangeDescriptions: async ({
    practiceId,
  }: {
    practiceId: string;
  }) => {
    const res = await httpClient<PracticeRangeDescriptionDto[]>(
      practiceApiRoutes.practiceRangeDescriptions,
      {
        method: 'GET',
        pathParams: { practiceId },
      }
    );
    return res.data;
  },

  getGlobalRangeDescriptions: async ({
    practiceId,
  }: {
    practiceId?: string;
  } = {}) => {
    const res = await httpClient<PracticeRangeDescriptionDto[]>(
      practiceApiRoutes.globalRangeDescriptions,
      {
        method: 'GET',
        params: { ehrPracticeId: practiceId },
      }
    );
    return res.data;
  },

  createPracticeRangeDescription: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: PracticeRangeDescriptionDataDto;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceRangeDescriptions, {
      method: 'POST',
      pathParams: { practiceId },
      data,
    });

    return res.data;
  },

  updatePracticeRangeDescription: async ({
    practiceId,
    rangeId,
    data,
  }: {
    practiceId: string;
    rangeId: string;
    data: PracticeRangeDescriptionDataDto;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceRangeDescription, {
      method: 'PATCH',
      pathParams: { practiceId, rangeId },
      data,
    });

    return res.data;
  },

  deletePracticeRangeDescription: async ({
    practiceId,
    rangeId,
  }: {
    practiceId: string;
    rangeId: string;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceRangeDescription, {
      method: 'DELETE',
      pathParams: { practiceId, rangeId },
    });

    return res.data;
  },
});
