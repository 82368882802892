import { useCallback } from 'react';
import { getUserLanguageCode } from 'shared/common/utils';

import { SupportedLanguageCode } from 'consts/supported-language-code';

export enum Policy {
  PRIVACY_POLICY,
  COOKIE_POLICY,
  COOKIE_POLICY_NO_MARKUP,
}

const IubendaLangcodes: Record<SupportedLanguageCode, string> = {
  [SupportedLanguageCode.ES]: '41073122',
  [SupportedLanguageCode.FR]: '60287115',
  [SupportedLanguageCode.PT]: '45011105',
  [SupportedLanguageCode.DE]: '18267693',
  [SupportedLanguageCode.EN]: '21469520',
  [SupportedLanguageCode.EL]: '35896665',
  [SupportedLanguageCode.TR]: '21469520',
  [SupportedLanguageCode.SV]: '66599628',
  [SupportedLanguageCode.IT]: '66341169',
  [SupportedLanguageCode.NL]: '63234040',
  [SupportedLanguageCode.PL]: '10381741',
};

const IubendaURL: Record<Policy, string> = {
  [Policy.PRIVACY_POLICY]:
    'https://www.iubenda.com/privacy-policy/:policyLanguageCode',
  [Policy.COOKIE_POLICY]:
    'https://www.iubenda.com/privacy-policy/:policyLanguageCode/cookie-policy',
  [Policy.COOKIE_POLICY_NO_MARKUP]:
    'https://www.iubenda.com/api/privacy-policy/:policyLanguageCode/cookie-policy/no-markup',
};

export const useGetPolicyLink = () => {
  const userLanguageCode = getUserLanguageCode();

  return useCallback(
    (policy: Policy) => {
      const policyLanguageCode = IubendaLangcodes[userLanguageCode];
      const url = IubendaURL[policy];
      return interpolateParams(url, { policyLanguageCode });
    },
    [userLanguageCode]
  );
};

const interpolateParams = (url: string, params: Object) => {
  Object.entries(params).forEach(
    ([key, value]) => (url = url.replace(new RegExp(`:${key}`, 'g'), value))
  );
  return url;
};
