import { config } from 'shared/common/config';
import { localStorage } from 'shared/common/utils';

import { LocalStorageKey, UserRegion } from 'consts';

export const regionStore = {
  save: (region: unknown) => {
    if (isValidRegion(region)) {
      localStorage.setItem(LocalStorageKey.REGION, region);
    } else {
      localStorage.setItem(LocalStorageKey.REGION, resolveDefaultRegion());
    }
  },
  getCurrent: (): UserRegion => {
    let region: UserRegion | null = localStorage.getItem(
      LocalStorageKey.REGION
    );

    if (!region || !isValidRegion(region)) {
      region = resolveDefaultRegion();
    }

    return region;
  },
};

/**
 * Method for approximating user region based on browser language. It doesn't need to be
 * 100% accurate, as it's only used as a fallback when user region is not set and user can switch
 * regions when logging in
 */
const resolveDefaultRegion = (): UserRegion => {
  const fallback = config.getDefaultRegion();

  if (fallback === UserRegion.STAGING || fallback === UserRegion.QA)
    return fallback;

  const lang = navigator.language;

  let region = fallback;

  if (lang.includes('en-US')) {
    region = UserRegion.US;
    // } else if (lang.includes('en-CA')) {
    //   region = UserRegion.CA;
  } else if (
    lang.includes('en') ||
    lang.includes('de') ||
    lang.includes('fr') ||
    lang.includes('es') ||
    lang.includes('it') ||
    lang.includes('ru') ||
    lang.includes('nl') ||
    lang.includes('tr') ||
    lang.includes('pl') ||
    lang.includes('ro') ||
    lang.includes('el') ||
    lang.includes('hu') ||
    lang.includes('cs') ||
    lang.includes('sv') ||
    lang.includes('da') ||
    lang.includes('fi') ||
    lang.includes('pt') ||
    lang.includes('no') ||
    lang.includes('sk') ||
    lang.includes('sl')
  ) {
    region = UserRegion.EU;
  }

  return region;
};

const isValidRegion = (region: unknown): region is UserRegion =>
  Object.values(UserRegion).includes(region as UserRegion);
