import { Ranges } from 'shared/common/store';

import { ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

export const getReadingsRangesParams = ({
  readingNames,
  ranges,
}: {
  readingNames: ReadingName[];
  ranges: Ranges;
}) =>
  readingNames.reduce(
    (acc, readingName) => ({
      ...acc,
      [getParamKey(readingName)]: ranges[readingName],
    }),
    {}
  );

export const getParamKey = (readingName: ReadingName) => {
  if (readingName === ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT) {
    return 'bloodPressureSystolicRange';
  }

  if (readingName === ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT) {
    return 'bloodPressureDiastolicRange';
  }

  if (readingName === ReadingName.GKI_BLOOD) {
    return `glucoseKetoneIndexRange`;
  }

  if (readingName === ReadingName.VITAMIN_D_INGESTION) {
    return `vitaminDRange`;
  }

  if (readingName === ReadingName.OMEGA_3_INGESTION) {
    return `omega3Range`;
  }

  if (readingName === ReadingName.OMEGA_3_EPA_INGESTION) {
    return `omega3EpaRange`;
  }

  if (readingName === ReadingName.OMEGA_3_DHA_INGESTION) {
    return `omega3DhaRange`;
  }

  if (readingName === ReadingName.VITAMIN_B12_INGESTION) {
    return `vitaminB12Range`;
  }

  return `${getDomainConfigs(readingName).readingType}Range`;
};
