export const isAuthorized = (
  requiredRoles: string | string[],
  userRoles: string[]
): boolean => {
  if (!userRoles) {
    return false;
  }

  if (Array.isArray(requiredRoles)) {
    return requiredRoles.some((role) => userRoles.includes(role));
  }

  return userRoles.includes(requiredRoles);
};
