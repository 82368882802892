import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { ToastType, useToast } from 'shared/common/hooks';

import OverlayLoading from 'components/OverlayLoading/OverlayLoading';

import { Policy, useGetPolicyLink } from '../shared/use-get-policy-link';

interface IProps {
  header: string;
  type: 'cookie' | 'privacy';
}

export const PolicyIframe: React.FC<IProps> = ({ header, type }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { openToast } = useToast();
  const history = useHistory();
  const getPolicyLink = useGetPolicyLink();

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleLoadError = () => {
    setIsLoading(false);
    openToast({
      label: t('We are unable to load the page.'),
      content: t('Please try again in a minute.'),
      type: ToastType.ERROR,
    });
    history.goBack();
  };

  return (
    <main className="container my-4 mx-auto flex grow flex-col text-center">
      <h1 className="h1 mb-4">{header}</h1>
      {isLoading && <OverlayLoading />}
      <iframe
        className={clsx('min-h-96 w-full grow', isLoading && 'hidden')}
        title={header}
        src={getPolicyLink(
          type === 'cookie' ? Policy.COOKIE_POLICY : Policy.PRIVACY_POLICY
        )}
        onLoad={handleIframeLoad}
        onError={handleLoadError}
      />
    </main>
  );
};
