import i18next from 'i18next';

import { ReadingName, UnitForReadingName } from 'domain/domain.models';
import { getBoundaryRange } from 'domain/domain.utils';

import { getFormattedUnit } from '../get-formatted-unit/get-formatted-unit';

export const getRequiredValidationMsg = () =>
  i18next.t('This field is required');

export const getNumberRequiredValidationMsg = () =>
  i18next.t('Field must be a number');

export const getMaxNumberValidationMsg = (max: number) =>
  `${i18next.t('Maximum number is')} ${max}.`;

export const getMinNumberValidationMsg = (min: number) =>
  `${i18next.t('Minimum number is')} ${min}.`;

export const getRangeValidationMsg = (min: number, max: number) =>
  `${i18next.t('Value must be between')} ${min} ${i18next.t('and')} ${max}`;

export const getMaxHigherThanMinValidationMsg = () =>
  i18next.t('Maximum value cannot be lower or equal to minimum value.');

export const getRequiredMsg = () => i18next.t('This field is required');

export const getRequiredFieldMsg = () => i18next.t('This field is required');

export const getMaxLengthMsg = (max: number) =>
  i18next.t('Maximum character length is {{value}}', {
    value: max,
  });

export const getEmailOrWebUrlMsg = () =>
  i18next.t('Provide valid email or url address');

export const getInvalidEmailMsg = () => i18next.t('Email is invalid');

export const getBoundaryRangeValidationMessage = <T extends ReadingName>(
  readingName: T,
  unit: UnitForReadingName<T>
) => {
  const [min, max] = getBoundaryRange(readingName, unit);

  return i18next.t(
    'Value must be between {{min}} {{unit}} and {{max}} {{unit}}',
    {
      min,
      max,
      unit: getFormattedUnit(unit),
    }
  );
};
