import {
  CategorizedMenu as CategorizedMenuRoot,
  CategorizedMenuItem,
} from './categorized-menu/categorized-menu';
import { MenuDotsTrigger } from './menu-dots-trigger/menu-dots-trigger';
import { MenuTrigger } from './menu-trigger/menu-trigger';
import { Menu as MenuRoot } from './menu';
import { MenuItem, MenuItemWithIcon } from './menu-item';

export const Menu = Object.assign(MenuRoot, {
  Item: MenuItem,
  ItemWithIcon: MenuItemWithIcon,
  Trigger: MenuTrigger,
  DotsTrigger: MenuDotsTrigger,
});

export const CategorizedMenu = Object.assign(CategorizedMenuRoot, {
  Trigger: MenuTrigger,
  Item: CategorizedMenuItem,
  DotsTrigger: MenuDotsTrigger,
});

export { useMenu } from './shared/menu.context';
