import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { SupportLink } from 'components/SupportLink/SupportLink';

interface Props {
  error: unknown;
}

export const ErrorFallbackGlobal = ({ error }: Props) => {
  const { t } = useTranslation();
  const refreshPage = () => window.location.reload();

  if (isError(error) && isOldAppVersionError(error)) {
    return (
      <ErrorMessage
        variant="overlay"
        title={t('New Version Released')}
        message={t(
          `You're viewing an old version of the app. Please refresh the page to see the latest version.`
        )}
        showButton
        buttonText={t('Refresh Page')}
        onClick={refreshPage}
      />
    );
  }

  if (axios.isAxiosError(error) && !error.response) {
    return (
      <ErrorMessage
        variant="overlay"
        title={t('No Connection')}
        message={
          <>
            {t(
              `We've had trouble connecting to the server. Please check your internet connection and try again. If the problem persists, please contact support:`
            )}{' '}
            <SupportLink mailSubject="MyMojoHealth Dashboard crashed" />
          </>
        }
        showButton
        buttonText={t('Refresh Page')}
        onClick={refreshPage}
      />
    );
  }

  return (
    <ErrorMessage
      title={t('Something went wrong')}
      message={
        <>
          {t(
            'There was an error. Please try again. If the problem persists, please contact support:'
          )}{' '}
          <wbr />
          <SupportLink mailSubject="MyMojoHealth Dashboard crashed" />
        </>
      }
      showButton
      buttonText={t('Refresh Page')}
      onClick={refreshPage}
    />
  );
};

const isOldAppVersionError = (error: Error): boolean => {
  const errorPatterns = [
    /loading.*chunk/i,
    /unexpected token '<'/i,
    /expected expression, got '<'/i,
  ];

  return errorPatterns.some((pattern) => pattern.test(error?.message));
};

const isError = (e: unknown): e is Error => e instanceof Error;
