import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import serverError from 'assets/images/empty-state.svg';
import Button from 'components/Button/Button';

type BaseProps = {
  variant?: 'overlay' | 'default';
  title?: string;
  message: React.ReactNode;
};

type WithoutButtonProps = {
  showButton?: false;
} & BaseProps;

type WithButtonProps = {
  showButton: true;
  buttonText: string;
  onClick: () => void;
} & BaseProps;

type Props = WithButtonProps | WithoutButtonProps;

export const ErrorMessage = ({
  message,
  variant = 'default',
  ...props
}: Props) => {
  const { t } = useTranslation();
  const title = props.title || t('Something went wrong');

  return (
    <div
      role="alert"
      className={clsx(
        'flex flex-col items-center justify-center p-4 text-center md:p-16',
        variant === 'overlay' &&
          'absolute inset-0 z-50 h-screen bg-gray-50 dark:bg-black',
        variant === 'default' && 'h-full'
      )}
    >
      <img className="mb-12" src={serverError} alt={t('Server error')} />
      <h1 className="h1 mb-4">{title}</h1>
      <p className="text-base font-medium text-gray-900 dark:text-gray-50">
        {message}
      </p>

      {props.showButton && (
        <Button className="mt-4" onClick={props.onClick}>
          {props.buttonText}
        </Button>
      )}
    </div>
  );
};
