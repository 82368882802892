import { DomainConfig, ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';
import { GkrType } from 'models/gkr-type';

export const getGkrConfig = (gkrType: GkrType) => {
  const gkRatioConfigs = getDomainConfigs({
    include: [ReadingName.GKI_BLOOD, ReadingName.DBR_BLOOD],
  });

  const gkRatioConfig =
    gkrType === GkrType.GKI
      ? gkRatioConfigs[ReadingName.GKI_BLOOD]
      : gkRatioConfigs[ReadingName.DBR_BLOOD];

  return gkRatioConfig as DomainConfig<
    ReadingName.GKI_BLOOD | ReadingName.DBR_BLOOD
  >;
};
