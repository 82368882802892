import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as RollbarProvider } from '@rollbar/react';
import i18next from 'i18next';
import { install as installResizeObserver } from 'resize-observer';
import { config } from 'shared/common/config';
import {
  getIsNewAppVersion,
  initializeLogRocket,
  initializeLogrocketConditionally,
  updateAppVersionInLocalStorage,
} from 'shared/common/services/logrocket';
import { isSyncApp, SyncAppGuard } from 'shared/common/services/sync-app';
import { RegionsMetadataGuard } from 'shared/region-metadata/components';

import { Analytics } from 'app/analytics';
import App from 'app/App';
import { AppErroBoundary } from 'app/app-error-boundary';
import {
  initializeChartJsPlugins,
  initializeCssVariables,
  initializeI18n,
  initializeSweetState,
  queryClient,
} from 'app/initialization';
import { Interceptors } from 'app/interceptors';
import { UnsupportedBrowserNotifier } from 'app/unsupported-browser-notifier';
import OverlayLoading from 'components/OverlayLoading/OverlayLoading';
import { CookieNotice } from 'features/policy';

import '@reach/combobox/styles.css';
import 'index.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/Combobox.css';
import './styles/pdf-report.scss';

initializeI18n();
initializeSweetState();
initializeCssVariables();
initializeChartJsPlugins();
updateAppVersionInLocalStorage();
initializeLogrocketConditionally({
  isSyncApp: isSyncApp(),
  isAppDeployed: config.getIsAppDeployed(),
  isLoggingGloballyEnabled: config.getIsLogRocketSessionRecordingEnabled(),
  isNewAppVersion: getIsNewAppVersion(),
  initializeLogRocket: initializeLogRocket,
});

// Safari 13 polyfill
if (!window.ResizeObserver) installResizeObserver();

ReactDOM.render(
  <React.Suspense
    fallback={<OverlayLoading heading={i18next.t('Loading...')} />}
  >
    <RollbarProvider>
      <AppErroBoundary>
        <SyncAppGuard>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <Toaster />
            <Analytics />
            <CookieNotice />
            <UnsupportedBrowserNotifier />
            <RegionsMetadataGuard>
              <Interceptors />
              <App />
            </RegionsMetadataGuard>
          </QueryClientProvider>
        </SyncAppGuard>
      </AppErroBoundary>
    </RollbarProvider>
  </React.Suspense>,
  document.getElementById('root')
);
