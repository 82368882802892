import { SyncAppErrors, SyncAppMessage } from './constants';
import { isAndroidSyncApp, isIosSyncApp, isSyncApp } from './is-sync-app';

const dispatchMessage = (messageName: SyncAppMessage, data: any = {}) => {
  if (!isSyncApp()) {
    return;
  }

  const message = JSON.stringify({ message: messageName, data });
  if (isIosSyncApp()) {
    window.webkit?.messageHandlers?.ketoMojoMessageHandler?.postMessage(
      message
    );
  }

  if (isAndroidSyncApp()) {
    window.ketoMojoMessageHandler?.postMessage(message);
  }
};

const logoutUser = () => {
  dispatchMessage(SyncAppMessage.USER_LOGOUT);
};

const refreshSession = () => {
  dispatchMessage(SyncAppMessage.REFRESH_SESSION);
};

const error = (error: SyncAppErrors) => {
  dispatchMessage(SyncAppMessage.ERROR, error);
};

const contentLoaded = () => {
  dispatchMessage(SyncAppMessage.CONTENT_LOADED);
};

export const syncAppHandlers = {
  logoutUser,
  refreshSession,
  error,
  contentLoaded,
};
