import i18n from 'i18next';

export enum ReadingCategory {
  METABOLISM = 'METABOLISM',
  MACROS = 'MACROS',
  BIOMETRICS = 'BIOMETRICS',
  MICRONUTRIENTS = 'MICRONUTRIENTS',
  LAB = 'LAB',
}

export const readingCategories = Object.values(
  ReadingCategory
) as ReadingCategory[];

export const getTranslatedReadingCategory = (category: ReadingCategory) => {
  const categories = {
    [ReadingCategory.METABOLISM]: i18n.t('Metabolism'),
    [ReadingCategory.MACROS]: i18n.t('Macros'),
    [ReadingCategory.BIOMETRICS]: i18n.t('Biometrics'),
    [ReadingCategory.MICRONUTRIENTS]: i18n.t('Micronutrients'),
    [ReadingCategory.LAB]: i18n.t('Lab'),
  };

  return categories[category];
};
