import i18next from 'i18next';

export enum SupportedLanguageCode {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  PT = 'pt',
  EL = 'el',
  TR = 'tr',
  SV = 'sv',
  IT = 'it',
  NL = 'nl',
  PL = 'pl'
}

export const languageCodeLabelsMap = {
  [SupportedLanguageCode.EN]: 'EN',
  [SupportedLanguageCode.DE]: 'DE',
  [SupportedLanguageCode.FR]: 'FR',
  [SupportedLanguageCode.ES]: 'ES',
  [SupportedLanguageCode.PT]: 'PT',
  [SupportedLanguageCode.EL]: 'EL',
  [SupportedLanguageCode.TR]: 'TR',
  [SupportedLanguageCode.SV]: 'SE',
  [SupportedLanguageCode.IT]: 'IT',
  [SupportedLanguageCode.NL]: 'DT',
  [SupportedLanguageCode.PL]: 'PL',
};

export const languageCodeNamesMap: Record<SupportedLanguageCode, () => string> =
  {
    [SupportedLanguageCode.EN]: () => i18next.t('English'),
    [SupportedLanguageCode.DE]: () => i18next.t('German'),
    [SupportedLanguageCode.FR]: () => i18next.t('French'),
    [SupportedLanguageCode.ES]: () => i18next.t('Spanish'),
    [SupportedLanguageCode.PT]: () => i18next.t('Portuguese'),
    [SupportedLanguageCode.EL]: () => i18next.t('Greek'),
    [SupportedLanguageCode.TR]: () => i18next.t('Turkish'),
    [SupportedLanguageCode.SV]: () => i18next.t('Swedish'),
    [SupportedLanguageCode.IT]: () => i18next.t('Italian'),
    [SupportedLanguageCode.NL]: () => i18next.t('Dutch'),
    [SupportedLanguageCode.PL]: () => i18next.t('Polish'),
  };
