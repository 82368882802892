import { forwardRef, LabelHTMLAttributes } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Tooltip } from 'shared/common/components';

import styles from './InputLabel.module.scss';

export interface InputLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string;
  textClassname?: string;
  isRequired?: boolean;
  textTooltip?: string;
}

export const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(
  (
    {
      text,
      children,
      className,
      textClassname,
      isRequired,
      textTooltip,
      ...props
    },
    ref
  ) => (
    <label {...{ className }} {...{ ref, ...props }}>
      {text && (
        <div className={clsx(styles.text, textClassname)}>
          {text}
          {isRequired && '*'}
          {textTooltip && (
            <Tooltip content={textTooltip}>
              <InformationCircleIcon className={styles.tooltipIcon} />
            </Tooltip>
          )}
        </div>
      )}
      {children}
    </label>
  )
);
