import { ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';
import { getTranslatedReadingCategory } from 'models/reading-category';

export const getReadingNamesSelectorOptions = ({
  excludedReadingNames,
}: {
  excludedReadingNames: ReadingName[];
}) => {
  const options = getDomainConfigs({
    shape: 'regularArray',
    exclude: excludedReadingNames,
  });
  return options.map((option) => ({
    category: getTranslatedReadingCategory(option.category),
    value: option.readingName,
    label: option.label(),
  }));
};
