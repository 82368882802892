import { ReadingName, ReadingNameToUnitMap } from 'domain/domain.models';

import { SharedReadingsParams } from '../../types/readings-request-params';

export const getReadingsUnitsParams = ({
  readingNames,
  units,
}: {
  readingNames: ReadingName[];
  units: ReadingNameToUnitMap;
}): Pick<
  SharedReadingsParams,
  | 'carbohydratesUnit'
  | 'fatUnit'
  | 'glucoseUnit'
  | 'proteinUnit'
  | 'vitaminDUnit'
  | 'waistUnit'
  | 'weightUnit'
> =>
  readingNames.reduce((acc, readingName) => {
    if (readingName === ReadingName.GLUCOSE_BLOOD)
      return { ...acc, glucoseUnit: units[readingName] };
    if (readingName === ReadingName.PROTEIN_INGESTION)
      return { ...acc, proteinUnit: units[readingName] };
    if (readingName === ReadingName.FAT_INGESTION)
      return { ...acc, fatUnit: units[readingName] };
    if (readingName === ReadingName.CARBS_INGESTION)
      return { ...acc, carbohydratesUnit: units[readingName] };
    if (readingName === ReadingName.WEIGHT_MEASUREMENT)
      return { ...acc, weightUnit: units[readingName] };
    if (readingName === ReadingName.WAIST_MEASUREMENT)
      return { ...acc, waistUnit: units[readingName] };
    if (readingName === ReadingName.VITAMIN_D_INGESTION)
      return { ...acc, vitaminDUnit: units[readingName] };

    return acc;
  }, {});
