import { DateRangePeriod } from '../../../types';
import { getDateRangeConfig } from '../../../utils';
import { DateRangeFilterState } from '../date-range-filter.store';

export const getDateRangeFilterStoreInitialState = (
  cachedState: DateRangeFilterState | null
) => {
  if (
    cachedState &&
    (cachedState.period === DateRangePeriod.CUSTOM ||
      cachedState.period === DateRangePeriod.ALL_TIME)
  ) {
    return {
      ...cachedState,
      startDate: new Date(cachedState.startDate),
      endDate: new Date(cachedState.endDate),
    };
  } else {
    const period =
      (cachedState?.period as
        | Parameters<typeof getDateRangeConfig>[0]
        | undefined) || DateRangePeriod.DAYS_28;

    return getDateRangeConfig(period);
  }
};
