import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 24 * 60 * 60 * 1000, // 1 day
      retry: 3,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000, // 2 mins
    },
  },
});
