import { createContext, FC, useContext } from 'react';

const MenuContext = createContext({
  isOpen: false,
});

export const MenuProvider: FC<{ isOpen: boolean }> = ({ isOpen, children }) => (
  <MenuContext.Provider value={{ isOpen }}>{children}</MenuContext.Provider>
);

export const useMenu = () => useContext(MenuContext);
