import React from 'react';
import toast from 'react-hot-toast';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';

import { IToastTemplateProps } from './toast-types';

import styles from './ToastTemplate.module.scss';

export const successIcon = <CheckCircleIcon className="h-6 text-green-500" />;
export const errorIcon = <XCircleIcon className="h-6 text-red-500" />;
export const infoIcon = <ExclamationCircleIcon className="h-6 text-blue-500" />;
export const warningIcon = <ExclamationIcon className="h-6 text-yellow-500" />;

export const ToastTemplate = ({
  toastInstance,
  content,
  label,
  icon,
}: IToastTemplateProps) => {
  const toastAlignClass = Boolean(content) ? 'items-start' : 'items-center';

  return (
    <div
      className={clsx(
        styles.animatedToast,
        'pointer-events-auto flex w-screen-90 max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-900 md:mr-16 md:w-full'
      )}
      data-testid="toast"
    >
      <div className="w-0 flex-1 p-4">
        <div className={clsx('flex', toastAlignClass)}>
          <div className="shrink-0">{icon}</div>
          <div className="ml-3 flex-1">
            <p className="label word">{label}</p>
            <div className={clsx(styles.contentWrapper, 'paragraph mt-1')}>
              {/* When content is text, we wrap in a styled paragraph for convenience */}
              {typeof content === 'string' && <p>{content}</p>}
              {/* Otherwise, simply pass it, for cases when we don't want to use a paragraph */}
              {React.isValidElement(content) && content}
            </div>
          </div>
          <div className="shrink-0 pt-0.5">
            <button
              onClick={() => toast.remove(toastInstance.id)}
              className="rounded-lg border-none text-sm font-medium outline-none focus:outline-none focus:ring-2"
              aria-labelledby="toast-close-btn-label"
            >
              <span id="toast-close-btn-label" hidden>
                Close
              </span>
              <XIcon
                aria-hidden="true"
                focusable="false"
                className="h-5 text-gray-500 dark:text-gray-300"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
