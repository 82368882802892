import Rollbar from 'rollbar';
import { config } from 'shared/common/config';

import { Environment } from 'consts';

const rollbarEnvironmentNames: Record<Environment, string> = {
  [Environment.QA]: 'FE: QA',
  [Environment.PRODUCTION]: 'FE: Production',
  [Environment.STAGING]: 'FE: Staging',
};

const rollbarConfig: Rollbar.Configuration = {
  enabled: config.getIsAppDeployed(),
  accessToken: config.getRollbarAccessToken(),
  environment: rollbarEnvironmentNames[config.getEnv()],
  captureUncaught: true,
  captureUnhandledRejections: true,
  codeVersion: config.getAppVersion(),
};

export const rollbar = new Rollbar(rollbarConfig);
