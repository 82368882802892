import isFunction from 'lodash/isFunction';

import { isReadingName, ReadingName } from '../../../domain.models';
import { getReadingName } from '../../../domain.utils';
import { Predicate, RegularDictionaryShape } from '../get-domain-configs.types';

import { isReadingNameArray, isReadingTypeAndSampleArray } from './type-guards';

export const excludeConfigs = (
  configs: RegularDictionaryShape,
  predicate: Predicate
) => {
  const _configs = { ...configs };

  if (isReadingNameArray(predicate)) {
    predicate.forEach(
      (readingName) =>
        isReadingName(readingName) &&
        _configs[readingName] &&
        delete _configs[readingName]
    );
  }

  if (isFunction(predicate)) {
    (Object.keys(_configs) as ReadingName[]).forEach((readingName) => {
      const config = _configs[readingName];

      if (config && predicate(config)) {
        delete _configs[readingName];
      }
    });
  }

  if (isReadingTypeAndSampleArray(predicate)) {
    predicate.forEach(({ type, sampleType }) => {
      const readingName = getReadingName(type, sampleType);

      if (isReadingName(readingName) && _configs[readingName]) {
        delete _configs[readingName];
      }
    });
  }

  return _configs;
};
