/**
 * RegExp validating phone number.
 * @example
 * 888888888
 * 888 888 888
 * 888-888-888
 * 888.888.888
 * 888333888888
 * 8883 3388 8888
 * 8883-3388-8888
 * 8883.3388.8888
 * 8883 388 8888
 * 883 3388 8888
 * 8883 3388 888
 * +48 512 512 512
 * (48) 512 512 512
 * (408) 512 512 512
 * (480) 5312 5312 5312
 */
export const PHONE_NUMBER_REGEXP =
  /^([+(]{0,1}[0-9]{2,3}\)?){0,1}([ .-]?)([0-9]{3,4})([ .-]?)([0-9]{3,4})([ .-]?)([0-9]{3,4})$/;
