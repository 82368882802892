import { AxiosRequestConfig } from 'axios';
import { getUserLanguageCode } from 'shared/common/utils';

export const attachLanguageHeaderInterceptor = (
  request: AxiosRequestConfig
) => {
  const userLanguageCode = getUserLanguageCode();

  if (userLanguageCode) {
    request.headers['Accept-Language'] = userLanguageCode;
  }

  return request;
};
