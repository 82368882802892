import { LineController } from 'chart.js';

import 'chartjs-adapter-date-fns';

export const LineChartWithVerticalLineId = 'lineChartWithVerticalLine';

const lineYPadding = 10;

class LineChartWithVerticalLine extends LineController {
  draw() {
    super.draw();

    const ctx = this.chart.ctx;
    const chart: any = this.chart; // types are not there yet
    const activeTooltip = chart.tooltip?._active[0];
    if (activeTooltip) {
      const { x } = activeTooltip.element;
      const { bottom, top } = this.chart.chartArea;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, top + lineYPadding);
      ctx.lineTo(x, bottom - lineYPadding);
      ctx.lineWidth = 50;
      ctx.strokeStyle = 'rgba(46, 44, 52, 0.1)';
      ctx.stroke();
      ctx.restore();
    }
  }
}

LineChartWithVerticalLine.id = LineChartWithVerticalLineId;
LineChartWithVerticalLine.defaults = LineController.defaults;

export default LineChartWithVerticalLine;
