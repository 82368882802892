import { Switch as HeadlessSwitch } from '@headlessui/react';
import clsx from 'clsx';

interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  id?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  testId?: string;
}

/**
 * Toggle component - a styled version of the `Switch` from `@headlessui/react`
 */
export const Toggle = ({
  checked,
  onChange,
  label,
  disabled,
  className,
  testId,
  ...props
}: ToggleProps) => (
  <HeadlessSwitch
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    type="button"
    className={clsx(
      'relative inline-flex h-[24px] w-[46px] shrink-0 items-center rounded-full',
      'transform transition duration-200 ease-in-out',
      checked ? 'bg-ketoneBlue dark:bg-ketoneBlue-400' : 'bg-gray-300',
      disabled && 'opacity-50',
      className
    )}
    id={props.id}
    data-testid={testId}
    {...props}
  >
    {label && <span className="sr-only">{label}</span>}
    <span
      className={clsx(
        'inline-block h-[20px] w-[20px] rounded-full bg-white dark:bg-ketoneBlue-900',
        'transform transition duration-200 ease-in-out',
        checked ? 'translate-x-[24px]' : 'translate-x-[2px]'
      )}
    />
  </HeadlessSwitch>
);

Toggle.Group = HeadlessSwitch.Group;
Toggle.Label = HeadlessSwitch.Label;
