import React, { createContext, useContext } from 'react';
import { ModalId } from 'shared/common/types';

type Props = {
  id: ModalId;
  children: React.ReactNode;
};
type Context = {
  id: ModalId;
};

const ModalContext = createContext<Context | null>(null);

export const ModalContextProvider = ({ children, id }: Props) => (
  <ModalContext.Provider value={{ id }}>{children}</ModalContext.Provider>
);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context)
    throw new Error(
      'ModalContext must be called from within the ModalContextProvider'
    );

  return context;
};
