export const practiceApiRoutes = {
  practices: '/ehr/practices',
  publicPractices: '/ehr/practice-directory',
  practice: '/ehr/practices/:practiceId',
  practiceLogo: '/ehr/practices/:practiceId/logo',
  exportPatientsData: '/ehr/practices/:practiceId/export',
  practiceAffiliateLinks: '/ehr/practices/:practiceId/affiliate-link',
  practiceAffiliateLink: '/ehr/practices/:practiceId/affiliate-link/:linkId',
  practiceRangeDescriptions: '/ehr/practices/:practiceId/ranges',
  globalRangeDescriptions: '/ranges',
  practiceRangeDescription: '/ehr/practices/:practiceId/ranges/:rangeId',
} as const;
