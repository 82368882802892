import { FC, ReactNode } from 'react';
import clsx from 'clsx';

interface TopBarOverlayProps {
  topBarContent: ReactNode;
  color?: keyof typeof colorClasses;
}

export const TopBarOverlay: FC<TopBarOverlayProps> = ({
  children,
  color = 'yellow',
  topBarContent,
}) => (
  <div className={clsx('border-4', colorClasses[color].outerContainer)}>
    <div
      className={clsx(
        'sticky top-0 z-40 py-2 px-6 text-center',
        colorClasses[color].innerContainer
      )}
    >
      {topBarContent}
    </div>
    {children}
  </div>
);

const colorClasses = {
  yellow: {
    outerContainer: 'border-4 border-yellow-300 dark:border-yellow-800',
    innerContainer: 'bg-yellow-300 dark:bg-yellow-800',
  },
};
