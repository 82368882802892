import { StoreState } from 'react-sweet-state';

const isMatchingStore = <T>(key: string, store: StoreState<T>): boolean => {
  const isSingleKeyMatching = (storeKey: string) => storeKey.startsWith(key);

  return isSingleKeyMatching(store.key);
};

export const storeSideEffectMiddleware =
  <S = any>(key: string, callback: (state: S) => void) =>
  (storeState: StoreState<S>) =>
  (next: any) =>
  (arg: any) => {
    const result = next(arg);
    if (isMatchingStore(key, storeState)) {
      callback(storeState.getState());
    }
    return result;
  };
