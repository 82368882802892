import i18n from 'i18next';

export enum AggregationInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  TOTAL = 'total',
  DAY_OF_WEEK = 'day_of_week',
  HOUR_OF_DAY = 'hour_of_day',
  MINUTE_OF_DAY = 'minute_of_day',
}

export type FixedAggregationInterval = `fixed_interval${string}`;

export enum AggregationFixedInterval {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
}

export const getFixedAggregationInterval = (
  params: { amount?: number; interval?: AggregationFixedInterval } = {}
): FixedAggregationInterval => {
  const intervalMap = {
    [AggregationFixedInterval.DAY]: 'd',
    [AggregationFixedInterval.HOUR]: 'h',
    [AggregationFixedInterval.MINUTE]: 'm',
  } as const;

  if (!params.amount || !params.interval) {
    return 'fixed_interval';
  }

  return `fixed_interval_${params.amount}${intervalMap[params.interval]}`;
};

export const getAggregationIntervalText = (
  interval: AggregationInterval,
  options: { case: 'capitalized' | 'lower' } = { case: 'capitalized' }
) => {
  const isCapitalized = options.case === 'capitalized';

  const intervalTextsMap = {
    [AggregationInterval.DAY]: isCapitalized ? i18n.t('Day') : i18n.t('day'),
    [AggregationInterval.WEEK]: isCapitalized ? i18n.t('Week') : i18n.t('week'),
    [AggregationInterval.MONTH]: isCapitalized
      ? i18n.t('Month')
      : i18n.t('month'),
    [AggregationInterval.YEAR]: isCapitalized ? i18n.t('Year') : i18n.t('year'),
    [AggregationInterval.TOTAL]: isCapitalized
      ? i18n.t('Total')
      : i18n.t('total'),
    [AggregationInterval.DAY_OF_WEEK]: isCapitalized
      ? i18n.t('Day of Week')
      : i18n.t('day of week'),
    [AggregationInterval.HOUR_OF_DAY]: isCapitalized
      ? i18n.t('Hour of Day')
      : i18n.t('hour of day'),
    [AggregationInterval.MINUTE_OF_DAY]: isCapitalized
      ? i18n.t('Minute of Day')
      : i18n.t('minute of day'),
  } as const;

  return intervalTextsMap[interval];
};
