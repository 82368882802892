import { AxiosResponse } from 'axios';
import { ErrorResponseDto } from 'shared/common/types';

export class ApiError extends Error {
  readonly message: string;
  readonly errors: string[];
  readonly isRequestError: boolean;
  readonly response: AxiosResponse<ErrorResponseDto>;

  constructor({
    message,
    errors,
    isRequestError,
    response,
  }: {
    message: string;
    response: AxiosResponse<ErrorResponseDto>;
    errors?: string[];
    isRequestError: boolean;
  }) {
    super(message);

    this.message = message;
    this.errors = errors || [];
    this.isRequestError = isRequestError;
    this.response = response;
  }
}

export const isApiError = (error: unknown): error is ApiError =>
  error instanceof ApiError;
