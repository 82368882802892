import { getReadingName } from 'domain/domain.utils';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';
import { isReadingType } from 'models';

import {
  PracticeRangeDescription,
  PracticeRangeDescriptionDto,
  PracticeRangeDescriptionsMap,
} from '../types';

export const mapToPracticeRangeDescriptionsMap = (
  dtos: PracticeRangeDescriptionDto[]
) =>
  dtos.reduce((acc, cur) => {
    if (!isReadingType(cur.reading_type)) return acc;

    const readingName = getReadingName(
      cur.reading_type,
      cur.reading_sample_type ?? undefined
    );

    acc[readingName] = [
      ...(acc[readingName] ?? []),
      mapToPracticeRangeDescription(cur),
    ].sort((a, b) => a.min - b.min);

    return acc;
  }, {} as PracticeRangeDescriptionsMap);

const mapToPracticeRangeDescription = (
  dto: PracticeRangeDescriptionDto
): PracticeRangeDescription => {
  const readingName = getReadingName(
    dto.reading_type,
    dto.reading_sample_type ?? undefined
  );

  const [boundaryMin, boundaryMax] =
    getDomainConfigs(readingName).boundaryRanges[dto.reading_unit];

  return {
    id: dto.id,
    readingName,
    unit: dto.reading_unit,
    name: dto.range_name,
    min:
      Math.abs(Number(dto.bound_min)) === Infinity
        ? boundaryMin
        : Number(dto.bound_min),
    max:
      Math.abs(Number(dto.bound_max)) === Infinity
        ? boundaryMax
        : Number(dto.bound_max),
    minInclusive: dto.bound_min_inclusive,
    maxInclusive: dto.bound_max_inclusive,
    defaultLang: dto.default_language,
    descriptions: dto.range_descriptions.reduce((acc, description) => {
      const matchingLink = dto.range_hyperlinks.find(
        (link) => link.language === description.language
      );

      if (description && matchingLink) {
        return [
          ...acc,
          {
            description: description.value,
            link: matchingLink.value,
            language: description.language,
          },
        ];
      }

      return [
        ...acc,
        {
          description: description.value,
          language: description.language,
        },
      ];
    }, [] as PracticeRangeDescription['descriptions']),
  };
};
