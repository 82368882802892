import { AxiosError, AxiosInstance } from 'axios';
import { saveAuthCookies } from 'shared/common/services/auth';
import { ApiErrorType, ErrorResponseDto } from 'shared/common/types';
import { getCookie } from 'shared/common/utils';
import { regionsMetadataStore } from 'shared/region-metadata/store';
import { userOauthApi } from 'shared/user/api';

import { CookieStorageKeys } from 'consts/cookie-storage-keys';

export const insufficientScopeErrorInterceptor = async (
  error: AxiosError<ErrorResponseDto>,
  instance: AxiosInstance,
  scopes: string
) => {
  const oAuthClientId =
    regionsMetadataStore.getCurrentRegionMetadata().properties.oauthClientId;

  if (isInsufficientScopeError(error)) {
    return handleInsufficientScopeError(error, instance, scopes, oAuthClientId);
  }
  throw error;
};

const isInsufficientScopeError = (error: AxiosError<ErrorResponseDto>) =>
  error.response?.status === 403 &&
  error.response?.data?.type === ApiErrorType.INSUFFICIENT_SCOPE;

/**
 * After refreshing the token, request should be retried only once, so we only
 * keep track of whether we made a request or not.
 */
let hasRetriedRequest = false;

const handleInsufficientScopeError = async (
  error: AxiosError<ErrorResponseDto>,
  instance: AxiosInstance,
  scopes: string,
  oAuthClientId: string
) => {
  if (hasRetriedRequest) {
    throw error;
  }

  const refreshToken = getCookie<string>(CookieStorageKeys.REFRESH_TOKEN);
  if (!refreshToken) {
    throw error;
  }

  // Get new access token
  const response = await userOauthApi.refreshUserSession({
    refreshToken,
    scopes,
    oAuthClientId,
  });
  saveAuthCookies(response);
  const accessToken = response.access_token;

  // Update Authorization header with new token
  error.config.headers['Authorization'] = `Bearer ${accessToken}`;

  hasRetriedRequest = true;

  try {
    await instance.request(error.config);
  } catch (error) {
    throw error;
  }
};
