export enum CookieStorageKeys {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  FILTERS = 'FILTERS',
  FILTERS_HCP = 'FILTERS_HCP',
  READINGS_TABLE_FILTERS_USER = 'READINGS_TABLE_FILTERS_USER',
  READINGS_TABLE_FILTERS_HCP = 'READINGS_TABLE_FILTERS_HCP',
}

type AppVersion = string;
type Variant = string;
export type VersionedCookieStorageKey =
  `${CookieStorageKeys}_${Variant}_${AppVersion}`;
