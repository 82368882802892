import { ModalCloseButton } from './shared/modal-close-button';
import { ModalDescription } from './shared/modal-description';
import { ModalTitle } from './shared/modal-title';
import {
  ModalCenter,
  ModalContentWrapper,
  ModalOverlay,
  ModalRoot,
} from './modal';
import { ModalBody } from './modal-body';
import { ModalCancelButton } from './modal-cancel-button';
import { ModalFooter } from './modal-footer';
import { ModalFooterButtons } from './modal-footer-buttons';
import { ModalHeader } from './modal-header';
import { ModalOpen } from './modal-open';
import { ModalSubmitButton } from './modal-submit-button';

export const Modal = Object.assign(ModalRoot, {
  Title: ModalTitle,
  Description: ModalDescription,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
  FooterButtons: ModalFooterButtons,
  CloseButton: ModalCloseButton,
  CancelButton: ModalCancelButton,
  SubmitButton: ModalSubmitButton,
  Open: ModalOpen,
  Overlay: ModalOverlay,
  ContentWrapper: ModalContentWrapper,
  Center: ModalCenter,
});
