import { intervalToDuration } from 'date-fns';
import round from 'lodash/round';

import { ReadingName } from 'domain/domain.models';
import { Unit } from 'models';

const formatter = (maxFractionDigits: number, minFractionDigits: number) =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  });

/**
 * Formats reading value, based on its reading name and unit.
 *
 * Should be used when the value is not associated with any particular reading
 * and we don't have info about its source/meter. For example, average, min, max,
 * etc. values from "/readings/stats" endpoint.
 *
 * Similarly, it should NOT be used for all the readings from "/readings" endpoint,
 * or LAST_READING from "/readings/stats", because they are associated with some
 * particular reading and we've got info about their source/meter.
 */
export const getFormattedValue = ({
  value,
  unit,
  readingName,
  compact,
}: {
  value: number;
  unit: Unit;
  readingName: ReadingName;
  compact?: boolean;
}) => {
  if (
    !!value &&
    [
      ReadingName.GLUCOSE_BLOOD,
      ReadingName.GLUCOSE_INTERSTITIAL,
      ReadingName.KETONE_BLOOD,
      ReadingName.KETONE_BREATH_ACE,
      ReadingName.KETONE_BREATH_PPM,
      ReadingName.KETONE_URINE,
    ].includes(readingName)
  ) {
    if ([Unit.MMOLL].includes(unit)) return formatter(2, 1).format(value);
    if ([Unit.MGDL].includes(unit)) return formatter(1, 0).format(value);
  }

  if (unit === Unit.MGDL || readingName === ReadingName.DBR_BLOOD)
    return String(Math.round(value));

  if (unit === Unit.MMOLL || readingName === ReadingName.GKI_BLOOD)
    return Number(value).toFixed(1);

  if (
    readingName === ReadingName.KETONE_BREATH_PPM ||
    readingName === ReadingName.KETONE_BREATH_ACE
  ) {
    if (value < 100) {
      return Number(value).toFixed(1);
    } else {
      return String(Math.round(value));
    }
  }

  if (readingName === ReadingName.SLEEP_SESSION_MEASUREMENT) {
    const duration = intervalToDuration({ start: 0, end: value * 1000 });
    const days = duration.days || 0;
    const minutes = duration.minutes || 0;
    const hours = duration.hours || 0;

    // edge case if sleep session would be 24h or longer for some reason 🤷‍♂️
    const getHours = days ? days * 24 + hours : hours;

    const renderDuration = () => {
      if (!minutes) return `${getHours}h`;
      else if (!getHours) return `${minutes}min`;

      return compact ? `${getHours}h${minutes}m` : `${getHours}h ${minutes}min`;
    };

    return renderDuration();
  }

  // other units/readingNames have no restrictions, but round them to one place
  // (in case many decimal numbers are provided), just like it's done on BE
  return String(round(value, 1));
};
