import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { routes } from 'shared/common/routes';
import { getVersionedStorageKey, localStorage } from 'shared/common/utils';
import { PracticePagesParams } from 'shared/practice/types';

import { LocalStorageKey } from 'consts';

export const usePersistRecentPractice = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const practiceRouteMatch = matchPath<PracticePagesParams>(pathname, {
      path: routes.hcp.practices.practice.index(),
    });

    if (!!practiceRouteMatch) {
      localStorage.setItem(
        getVersionedStorageKey(LocalStorageKey.RECENT_PRACTICE_ID),
        practiceRouteMatch.params.practiceId
      );
    }
  }, [pathname]);
};
