/**
 * Will match:
    http://www.foufos.gr
    https://www.foufos.gr
    http://foufos.gr
    http://www.foufos.gr/kino
    http://werer.gr
    www.foufos.gr
    www.mp3.com
    www.t.co
    http://t.co
    http://www.t.co
    https://www.t.co
    www.aa.com
    http://aa.com
    http://www.aa.com
    https://www.aa.com
 */
export const webUrlRegExp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
