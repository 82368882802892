export const poll = async ({
  numberOfIterations,
  iterationCb,
  onPollingSuccess,
  onPollingFail,
  interval = 1000,
}: {
  numberOfIterations: number;
  iterationCb: () => Promise<boolean>;
  onPollingFail?: () => void;
  onPollingSuccess?: () => void;
  interval?: number;
}) => {
  let iterationsCount = 0;

  while (iterationsCount < numberOfIterations) {
    await new Promise((resolve) => setTimeout(resolve, interval));

    try {
      const isSuccess = await iterationCb();
      if (isSuccess) {
        onPollingSuccess?.();
        return;
      }

      iterationsCount++;
    } catch (error) {
      onPollingFail?.();
      return;
    }
  }

  onPollingFail?.();
};
