/* eslint-disable func-style */
import i18next from 'i18next';

import {
  CustomReadingName,
  ReadingItem,
  ReadingItemConfig,
} from 'domain/domain.models';
import { ReadingCategory } from 'models/reading-category';

import {
  GetConfigsOptions,
  getDomainConfigs,
} from '../get-domain-configs/get-domain-configs';
import { DomainConfigsShape } from '../get-domain-configs/get-domain-configs.types';
import { parseConfigs } from '../get-domain-configs/utils/parse-configs';

import { isReadingItem } from './../../domain.models';

export function getReadingItemsConfigs(
  options: GetConfigsOptions<'regularDictionary'>
): Record<ReadingItem, ReadingItemConfig>;
//@ts-ignore
export function getReadingItemsConfigs(options: ReadingItem): ReadingItemConfig;
export function getReadingItemsConfigs(
  options: GetConfigsOptions<'regularArray'>
): ReadingItemConfig[];
export function getReadingItemsConfigs(
  options: GetConfigsOptions<'categorizedArray'>
): CategorizedArrayShape;
export function getReadingItemsConfigs(
  options: GetConfigsOptions<'categorizedDictionary'>
): CategorizedDictionaryShape;
export function getReadingItemsConfigs(
  options: GetConfigsOptions<DomainConfigsShape>
) {
  const { include, exclude, shape } = options;

  const configs = Object.entries(getDomainConfigs({ include, exclude })).reduce(
    (acc, [readingItem, config]) => {
      acc[readingItem] = {
        readingItem: readingItem as ReadingItem,
        slug: config.slug,
        label: config.label,
        category: config.category,
        canBeAddedManually: config.canBeAddedManually,
      };

      return acc;
    },
    {} as Record<string, ReadingItemConfig>
  );

  configs[CustomReadingName.MACROS] = {
    readingItem: CustomReadingName.MACROS,
    slug: 'macros',
    label: () => i18next.t('Macro Composition'),
    category: ReadingCategory.MACROS,
    canBeAddedManually: true,
  };

  configs[CustomReadingName.BLOOD_PRESSURE] = {
    readingItem: CustomReadingName.BLOOD_PRESSURE,
    slug: 'blood-pressure',
    label: () => i18next.t('Blood Pressure'),
    category: ReadingCategory.BIOMETRICS,
    canBeAddedManually: true,
  };

  if (isReadingItem(options)) return configs[options] as ReadingItemConfig;

  return parseConfigs(configs, shape || 'regularDictionary');
}

type CategorizedArrayShape = {
  category: ReadingCategory;
  configs: ReadingItemConfig[];
}[];

type CategorizedDictionaryShape = {
  [key in ReadingCategory]: ReadingItemConfig[];
};
