import isFunction from 'lodash/isFunction';

import { isReadingName, ReadingName } from '../../../domain.models';
import { getReadingName } from '../../../domain.utils';
import { Predicate, RegularDictionaryShape } from '../get-domain-configs.types';

import { isReadingNameArray, isReadingTypeAndSampleArray } from './type-guards';

export const includeConfigs = (
  configs: RegularDictionaryShape,
  predicate: Predicate
) => {
  const _configs = {} as RegularDictionaryShape;

  if (isReadingNameArray(predicate)) {
    predicate.forEach(
      (readingName) =>
        isReadingName(readingName) &&
        configs[readingName] &&
        (_configs[readingName] = configs[readingName])
    );

    return _configs;
  }

  if (isFunction(predicate)) {
    (Object.keys(configs) as ReadingName[]).forEach((readingName) => {
      const config = configs[readingName];

      if (config && predicate(config) && configs[readingName]) {
        _configs[readingName] = configs[readingName];
      }
    });

    return _configs;
  }

  if (isReadingTypeAndSampleArray(predicate)) {
    predicate.forEach(({ type, sampleType }) => {
      const readingName = getReadingName(type, sampleType);

      if (isReadingName(readingName) && configs[readingName]) {
        _configs[readingName] = configs[readingName];
      }
    });

    return _configs;
  }

  return configs;
};
