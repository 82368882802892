import { Column } from 'react-table';
import { ColumnDef } from '@tanstack/react-table';

import { Checkbox } from 'components/forms-v2/inputs/checkbox/Checkbox';

export const selectColumn: Column<any> = {
  accessor: () => {},
  id: 'selection',
  disableSortBy: true,
  Header: ({ getToggleAllRowsSelectedProps }) => {
    const props = getToggleAllRowsSelectedProps();
    return (
      <div>
        <Checkbox isChecked={props.checked ?? false} {...props} />
      </div>
    );
  },
  Cell: ({ row }) => {
    const props = row.getToggleRowSelectedProps();
    return (
      <div>
        <Checkbox isChecked={props.checked ?? false} {...props} />
      </div>
    );
  },
  headerCellClassName: 'w-2 !pr-2',
  bodyCellClassName: 'w-2 !pr-2',
  ExpandedCell: () => null,
} as const;

export const selectMobileColumn: Column<any> = {
  accessor: () => {},
  id: 'selection',
  disableSortBy: true,
  Header: ({ getToggleAllRowsSelectedProps }) => {
    const props = getToggleAllRowsSelectedProps();
    return (
      <div>
        <Checkbox isChecked={props.checked ?? false} {...props} />
      </div>
    );
  },
  Cell: ({ row }) => {
    const props = row.getToggleRowSelectedProps();
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox isChecked={props.checked ?? false} {...props} />
      </div>
    );
  },
  headerCellClassName: 'w-2 !pr-2',
  bodyCellClassName: 'w-2 !pr-2',
  ExpandedCell: () => null,
} as const;

export const getSelectColumn = <T,>(): ColumnDef<T> =>
  ({
    id: 'selection',
    enableSorting: false,
    header: (props) => (
      <div>
        <Checkbox
          isChecked={props.table.getIsAllRowsSelected()}
          onChange={() => props.table.toggleAllRowsSelected()}
        />
      </div>
    ),
    cell: ({ row }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          isChecked={row.getIsSelected()}
          onChange={() => row.toggleSelected()}
        />
      </div>
    ),
    meta: {
      classes: {
        header: 'w-10 ',
      },
    },
  } as const);
