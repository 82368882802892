import * as yup from 'yup';

import { webUrlRegExp } from 'consts/index';

/**
 * Checks if a value is a valid email or URL.
 * @param value - The value to be checked.
 * @returns An object with properties indicating if the value is an email or URL.
 */
export const checkIsEmailOrUrl = (value?: string | null) => {
  const result = {
    isEmail: false,
    isUrl: false,
  };

  if (!value) return result;

  const isValueEmail = yup.string().email().isValidSync(value);

  if (isValueEmail) {
    result.isEmail = true;
    return result;
  }

  const regExp = new RegExp(webUrlRegExp);

  const isValueUrl = regExp.test(value);

  if (isValueUrl) {
    result.isUrl = true;
    return result;
  }

  return result;
};
