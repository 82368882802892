export enum ReadingSampleType {
  BLOOD = 'blood',
  BREATH_ACE = 'breath_ace',
  BREATH_PPM = 'breath_ppm',
  URINE = 'urine',
  INGESTION = 'ingestion',
  INTERSTITIAL = 'interstitial',
  MEASUREMENT = 'measurement',
  MDI = 'mdi',
}

export const readingSampleTypes = Object.values(
  ReadingSampleType
) as ReadingSampleType[];

export const getReadingSampleTypes = () =>
  (Object.keys(ReadingSampleType) as (keyof typeof ReadingSampleType)[]).map(
    (key) => ReadingSampleType[key]
  );

export const isReadingSampleType = (value: any): value is ReadingSampleType =>
  readingSampleTypes.includes(value);
