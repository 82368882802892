import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import i18next from 'i18next';
import { OpacityTransition } from 'shared/common/components/atoms';
import { toggleBodyFixedPosition } from 'shared/common/utils';

import styles from './styles.module.scss';

export const MobileMenuItems: FC<{
  close: () => void;
  classes?: {
    menu?: string;
    items?: string;
  };
  isOpen: boolean;
}> = ({ children, close, classes, isOpen }) => {
  const [tooltipContainer] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(tooltipContainer);
    return () => {
      document.body.removeChild(tooltipContainer);
    };
  }, [tooltipContainer]);

  useEffect(() => {
    toggleBodyFixedPosition(isOpen);
  }, [isOpen]);

  return createPortal(
    <OpacityTransition as="div" className={styles.fullScreenModal}>
      <HeadlessUIMenu.Items
        className={clsx(styles.mobileItems, classes?.items)}
      >
        <button
          className={styles.closeMenuBtn}
          onClick={close}
          aria-label={i18next.t('Close Menu')}
        >
          <XIcon className="h-5 w-5" />
        </button>
        {children}
      </HeadlessUIMenu.Items>
    </OpacityTransition>,
    tooltipContainer
  );
};
