import { AxiosRequestConfig } from 'axios';
import { getCookie } from 'shared/common/utils';

import { CookieStorageKeys } from 'consts/cookie-storage-keys';

export const attachAuthTokenInterceptor = (request: AxiosRequestConfig) => {
  const authToken = getCookie<string>(CookieStorageKeys.ACCESS_TOKEN);
  if (authToken) {
    request.headers.Authorization = `Bearer ${authToken}`;
  }
  return request;
};
