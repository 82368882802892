import axios from 'axios';

import { ApiNames } from '../consts/api-names';

export const apiHttpInstance = axios.create({
  baseURL: ApiNames.API,
  headers: {
    'Content-type': 'application/json',
    accept: 'application/json',
  },
});
