import React from 'react';
import clsx from 'clsx';

import { THEME_CLASSES, Themes } from 'components/Button/consts';
import Spinner from 'components/Spinner';

import styles from './Button.module.css';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: Themes;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  testId?: string;
  'data-testid'?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      theme = 'primary',
      className = '',
      isLoading = false,
      disabled = false,
      children,
      testId,
      ...rest
    },
    ref
  ) => {
    const { background, textColor } = THEME_CLASSES[theme];
    return (
      <button
        ref={ref}
        className={clsx(
          'relative inline-flex items-center justify-center',
          'rounded-xl py-3 px-4 text-sm font-semibold',
          background,
          textColor,
          className
        )}
        {...rest}
        disabled={disabled || isLoading}
        data-testid={testId || rest['data-testid']}
      >
        <span className={clsx(styles.buttonInner, isLoading && 'invisible')}>
          {children}
        </span>
        {isLoading && <Spinner className="absolute" size={5} />}
      </button>
    );
  }
);

export default Button;
