import {
  Action,
  ContainerComponent,
  createActionsHook,
  createContainer,
  createHook,
  createStateHook,
  createStore,
  defaults,
} from 'react-sweet-state';
import { intervalToDuration } from 'date-fns';
import { versionedLocalStorage } from 'shared/common/utils';

import { LocalStorageKey } from 'consts';

import { DateRangePeriod } from '../../types';
import { getDateRangeConfig, storeSideEffectMiddleware } from '../../utils';

import { getDateRangeFilterStoreInitialState } from './utils/get-date-range-filter-store-initial-state';

const storeName = 'dateRangeFilter';
let variant:
  | 'user'
  | 'user_practice'
  | 'hcp_practice'
  | 'user_patient'
  | 'hcp_patient';

export interface DateRangeFilterState {
  duration: Duration;
  startDate: Date;
  endDate: Date;
  period: DateRangePeriod;
}

const actions = {
  setDateFilter:
    (
      data: Pick<DateRangeFilterState, 'startDate' | 'endDate' | 'period'>
    ): Action<DateRangeFilterState> =>
    ({ setState }) => {
      const duration = intervalToDuration({
        start: data.startDate,
        end: data.endDate,
      });
      setState({
        ...data,
        duration,
      });
    },
  refreshDateFilter:
    (): Action<DateRangeFilterState> =>
    ({ setState, getState }) => {
      const { period } = getState();
      const isDateRangeRefreshable =
        period === DateRangePeriod.HOURS_1 ||
        period === DateRangePeriod.HOURS_3 ||
        period === DateRangePeriod.HOURS_6 ||
        period === DateRangePeriod.HOURS_12;

      if (isDateRangeRefreshable) {
        setState(getDateRangeConfig(period));
      }
    },
};

type Actions = typeof actions;

const store = createStore<DateRangeFilterState, Actions>({
  name: storeName,
  initialState: getDateRangeConfig(DateRangePeriod.DAYS_28),
  actions,
});

const useDateRangeFilter = createHook(store);
const useDateRangeFilterActions = createActionsHook(store);
const useDateRangeFilterState = createStateHook(store);

const DateRangeFilterContainer: ContainerComponent<{
  variant: typeof variant;
  initialState?: DateRangeFilterState;
}> = createContainer(store, {
  onInit:
    () =>
    (
      { setState },
      { variant: propsVariant, initialState: initialStateFromProps }
    ) => {
      variant = propsVariant;

      const cachedState = versionedLocalStorage.getItem<DateRangeFilterState>({
        key: LocalStorageKey.DATE_RANGE_FILTER,
        variant,
      });

      const initialState = getDateRangeFilterStoreInitialState(cachedState);

      const newState = {
        ...initialState,
        ...(initialStateFromProps ?? {}),
      };

      setState(newState);
    },
});

defaults.middlewares.add(
  storeSideEffectMiddleware(storeName, (state: DateRangeFilterState) => {
    if (variant !== 'hcp_patient' && variant !== 'user_patient') {
      versionedLocalStorage.setItem({
        key: LocalStorageKey.DATE_RANGE_FILTER,
        variant,
        value: state,
      });
    }
  })
);

export {
  useDateRangeFilterActions,
  useDateRangeFilterState,
  useDateRangeFilter,
  DateRangeFilterContainer,
};
