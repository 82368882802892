import { FC } from 'react';
import clsx from 'clsx';

interface TableActionsWrapperProps {
  classes?: string;
}

export const TableActionsWrapper: FC<TableActionsWrapperProps> = ({
  children,
  classes,
}) => (
  <div
    className={clsx(
      'rounded-lg p-3 leading-3 md:p-1',
      classes,
      ' bg-ketoneBlue-50 dark:bg-ketoneBlue-900',
      'text-ketoneBlue dark:text-ketoneBlue-400'
    )}
  >
    {children}
  </div>
);
