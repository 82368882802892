import { KeyboardEvent, useCallback, useLayoutEffect, useRef } from 'react';

import { DropdownOptionItem } from '../../../forms.types';

interface UseFocusNavigationProps<T> {
  onSelect?(value: T): void;
  withFocusOnMount?: boolean;
  withSearch?: boolean;
}
export const useFocusNavigation = <T,>({
  withSearch,
  withFocusOnMount,
  onSelect,
}: UseFocusNavigationProps<T>) => {
  const listRef = useRef<HTMLUListElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);

  const getFocusableOptions = useCallback(() => {
    if (!listRef.current) return [];

    const listItems = listRef.current.querySelectorAll('li');
    return Array.from(listItems) as HTMLElement[];
  }, []);

  useLayoutEffect(() => {
    const handleInitialFocus = () => {
      if (withSearch && searchRef.current) {
        searchRef.current.focus({ preventScroll: true });
      } else if (listRef.current) {
        getFocusableOptions()[0]?.focus({ preventScroll: true });
      }
    };

    if (withFocusOnMount) {
      handleInitialFocus();
    }
  }, [withSearch, getFocusableOptions, withFocusOnMount]);

  const handleFocusKeyNav = (e: KeyboardEvent<HTMLUListElement>) => {
    if (!listRef.current) return;

    const options = getFocusableOptions();
    const focusedOptionIdx = options.findIndex(
      (option) => option === document.activeElement
    );
    const nextOption = options[focusedOptionIdx + 1];
    const prevOption = options[focusedOptionIdx - 1];
    const firstOption = options[0];
    const lastOption = options[options.length - 1];

    switch (e.code) {
      case 'ArrowDown':
        e.preventDefault();

        const next = nextOption || firstOption;
        next.focus();
        break;
      case 'ArrowUp':
        e.preventDefault();

        const prev = prevOption || lastOption;
        prev.focus();
        break;
      case 'Tab':
        e.preventDefault();

        listContainerRef.current?.parentElement?.focus();
        break;
      default:
        break;
    }
  };

  const handleOptionKeyNav = (
    value: T,
    onOptionSelect: DropdownOptionItem<T>['onSelect'],
    e: KeyboardEvent<HTMLLIElement>
  ) => {
    if (['Enter', 'Space'].includes(e.code)) {
      onOptionSelect?.(value);
      onSelect?.(value);
    }
  };

  return {
    listRef,
    searchRef,
    listContainerRef,
    handleFocusKeyNav,
    handleOptionKeyNav,
  };
};
