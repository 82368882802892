import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

import {
  PracticeRangeDescriptionData,
  PracticeRangeDescriptionDataDto,
} from '../types/practice-range-description';

export const mapPracticeRangeDescriptionDataToDto = (
  data: PracticeRangeDescriptionData
): PracticeRangeDescriptionDataDto => ({
  reading_type: getDomainConfigs(data.readingName).readingType,
  reading_sample_type: getDomainConfigs(data.readingName).readingSampleType,
  reading_unit: data.unit,
  range_name: data.name,
  range_type: 'chart_range',
  bound_min: String(data.min),
  bound_max: String(data.max),
  bound_min_inclusive: true,
  bound_max_inclusive: false,
  default_language: data.descriptions[0].language,
  range_descriptions: data.descriptions.map((d) => ({
    value: d.description,
    language: d.language,
  })),
  range_hyperlinks: data.descriptions
    .filter((d) => !!d.link)
    .map((d) => ({
      value: d.link!,
      language: d.language,
    })),
});
