import { differenceInDays, differenceInHours } from 'date-fns';

import { AggregationFixedInterval } from 'models';

/**
 * Calculates the fixed interval configuration based on the provided from and to dates.
 * By default, the fixed interval is set to hourly, but if the hourlyAggregationFixedIntervalAmount
 * is greater than 400 hours, the fixed interval is set to daily.
 * At no point aggregationFixedIntervalAmount should exceed 500, as it will not be supported by BE
 */
export const getFixedIntervalConfig = ({
  fromDate,
  toDate,
}: {
  fromDate: Date;
  toDate: Date;
}) => {
  const hourlyDiff = differenceInHours(toDate, fromDate);
  const dailyDiff = differenceInDays(toDate, fromDate);

  const hourlyAggregationFixedIntervalAmount = Math.round(hourlyDiff / 300);

  if (hourlyAggregationFixedIntervalAmount < 400) {
    return {
      aggregationFixedInterval: AggregationFixedInterval.HOUR,
      aggregationFixedIntervalAmount: hourlyAggregationFixedIntervalAmount,
    };
  } else {
    return {
      aggregationFixedInterval: AggregationFixedInterval.DAY,
      aggregationFixedIntervalAmount: Math.round(dailyDiff / 300),
    };
  }
};
