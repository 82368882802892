import { useQuery } from 'react-query';
import axios from 'axios';
import { config } from 'shared/common/config';

import {
  CLIENT_APPLICATION_HEADER_NAME,
  CLIENT_VERSION_HEADER_NAME,
} from 'consts';
import { ReadingName } from 'domain/domain.models';

import { regionsMetadataStore } from '../store/regions-metadata.localstorage';
import { RegionMetadata, RegionMetadataDto } from '../types';

export const regionsMetadataQueryFactory =
  (dependancies: {
    axios: typeof axios;
    url: string;
    appName: string;
    appVersion: string;
    regionsMetadataStore: typeof regionsMetadataStore;
  }) =>
  () => {
    const { axios, url, appName, appVersion } = dependancies;

    return useQuery(
      'regions-metadata',
      async () => {
        const res = await axios.get<RegionMetadataDto[]>(url, {
          headers: {
            'Content-type': 'application/json',
            accept: 'application/json',
            [CLIENT_APPLICATION_HEADER_NAME]: appName,
            [CLIENT_VERSION_HEADER_NAME]: appVersion,
          },
        });

        const regionsMetadata = res.data.map(mapToRegionMetadata);

        regionsMetadataStore.save(regionsMetadata);
        return regionsMetadata;
      },
      {
        staleTime: Infinity,
        initialData: () => {
          const persistedData = regionsMetadataStore.getRegionsMetadata();

          if (persistedData) {
            return persistedData;
          }
        },
      }
    );
  };

const mapToRegionMetadata = (region: RegionMetadataDto): RegionMetadata => ({
  name: region.name,
  code: region.code,
  oldDashboardUrl: region.locations.auth_service,
  preferredUnits: {
    [ReadingName.GLUCOSE_BLOOD]: region.default_glucose_unit,
    [ReadingName.GLUCOSE_INTERSTITIAL]: region.default_glucose_unit,
    [ReadingName.CARBS_INGESTION]: region.default_carbohydrates_unit,
    [ReadingName.FAT_INGESTION]: region.default_fat_unit,
    [ReadingName.PROTEIN_INGESTION]: region.default_protein_unit,
    [ReadingName.WAIST_MEASUREMENT]: region.default_waist_unit,
    [ReadingName.WEIGHT_MEASUREMENT]: region.default_weight_unit,
    [ReadingName.VITAMIN_D_INGESTION]: region.default_vitamin_d_unit,
  },
  apiUrls: {
    authService: new URL('api/v1', region.locations.auth_service).href,
    apiService: new URL('api/v1', region.locations.api_service).href,
  },
  properties: {
    oauthClientId: region.properties.client_id,
  },
});

export const useRegionsMetadataQuery = regionsMetadataQueryFactory({
  url: config.getAllRegionsMetadataUrl(),
  appName: config.getApplicationName(),
  appVersion: config.getAppVersion(),
  axios,
  regionsMetadataStore,
});
