import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import serverError from 'assets/images/empty-state.svg';
import Button from 'components/Button/Button';

const EmptyStateServerError: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'm-auto flex max-w-max flex-col items-center justify-center rounded-2xl bg-white p-8 dark:bg-black md:px-44',
        className
      )}
    >
      <img src={serverError} alt={t('Server error')} />
      <h2 className="mt-14 text-center text-3xl font-bold text-black dark:text-white">
        {t('Server Error')}
      </h2>
      <h3 className="mt-4 mb-10 text-center font-medium text-black dark:text-white">
        {t('There is a problem with our servers. Please try again!')}
      </h3>
      <Button onClick={() => window.location.reload()}>
        {t('Refresh Page')}
      </Button>
    </div>
  );
};
export default EmptyStateServerError;
