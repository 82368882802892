import round from 'lodash/round';

export const getReadingsPercentagesInRanges = (
  numberOfReadingsInRanges?: Record<string, number> | null
): [number | null, number | null, number | null] => {
  const percentages = Array(3).fill(null) as [
    number | null,
    number | null,
    number | null
  ];

  if (!numberOfReadingsInRanges) return percentages;

  const amountOfAllReadings = Object.values(numberOfReadingsInRanges).reduce(
    (sum, num) => sum + num,
    0
  );
  const calculatePercentageOfReadings = (amountOfReadings: number) =>
    round(amountOfReadings / amountOfAllReadings, 2);

  Object.entries(numberOfReadingsInRanges).forEach(
    ([range, numberOfReadingsInRange]) => {
      const lowerRangeRegexp = /^\*-/;
      const upperRangeRegexp = /-\*$/;

      if (lowerRangeRegexp.test(range)) {
        percentages[0] = calculatePercentageOfReadings(numberOfReadingsInRange);
      } else if (upperRangeRegexp.test(range)) {
        percentages[2] = calculatePercentageOfReadings(numberOfReadingsInRange);
      } else {
        percentages[1] = calculatePercentageOfReadings(numberOfReadingsInRange);
      }
    }
  );

  return percentages as [number, number, number];
};
