import { useEffect } from 'react';
import { apiHttpInstance, oauthHttpInstance } from 'shared/common/api';

import { attachAuthTokenInterceptor } from './attach-auth-token-interceptor';
import { attachLanguageHeaderInterceptor } from './attach-language-header-interceptor';
import { baseUrlInterceptor } from './base-url-interceptor';
import clientIdInterceptor from './client-id-interceptor';
import { errorLoggerInterceptor } from './error-logger-interceptor';
import { errorMapperInterceptor } from './error-mapper-interceptor';

const httpClients = [oauthHttpInstance, apiHttpInstance];

export const Interceptors = () => {
  useEffect(() => {
    let responseInterceptors: number[] = [];
    let requestsInterceptors: number[] = [];

    for (const instance of httpClients) {
      requestsInterceptors.push(
        instance.interceptors.request.use(attachAuthTokenInterceptor)
      );

      responseInterceptors.push(
        instance.interceptors.response.use((res) => res, errorLoggerInterceptor)
      );

      responseInterceptors.push(
        instance.interceptors.response.use((res) => res, errorMapperInterceptor)
      );

      requestsInterceptors.push(
        instance.interceptors.request.use(clientIdInterceptor)
      );

      requestsInterceptors.push(
        instance.interceptors.request.use(attachLanguageHeaderInterceptor)
      );

      // base url interceptor needs to be last
      requestsInterceptors.push(
        instance.interceptors.request.use(baseUrlInterceptor)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
