import {
  Action,
  ContainerComponent,
  createContainer,
  createHook,
  createStateHook,
  createStore,
  defaults,
} from 'react-sweet-state';
import { localStorage } from 'shared/common/utils';

import { LocalStorageKey } from 'consts';

import { storeSideEffectMiddleware } from '../utils';

const STORE_NAME = 'darkModeState';

interface State {
  isDarkMode: boolean;
}

const initialState: State = {
  isDarkMode: false,
};

const actions = {
  toggleDarkMode:
    (isDarkMode: boolean): Action<State> =>
    ({ setState }) => {
      setState({ isDarkMode });
    },
};

type Actions = typeof actions;

const store = createStore<State, Actions>({
  name: STORE_NAME,
  initialState,
  actions,
});

defaults.middlewares.add(
  storeSideEffectMiddleware(STORE_NAME, (state: State) => {
    localStorage.setItem(LocalStorageKey.DARK_MODE, state);
    toggleDarkModeClassName(state.isDarkMode);
  })
);

const useDarkMode = createHook(store);
const useIsDarkMode = createStateHook(store, {
  selector: (state) => state.isDarkMode,
});

const DarkModeContainer: ContainerComponent<{
  initialState?: State;
}> = createContainer(store, {
  onInit:
    () =>
    ({ setState, getState }, { initialState }) => {
      const persistedState = localStorage.getItem<State>(
        LocalStorageKey.DARK_MODE
      );

      const isDarkModePreferred =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;

      setState({
        ...getState(),
        isDarkMode: persistedState?.isDarkMode ?? isDarkModePreferred,
        ...(initialState ?? {}),
      });
    },
});

const toggleDarkModeClassName = (isOn: boolean) => {
  if (isOn) {
    document.body.classList.add('dark');
  } else {
    document.body.classList.remove('dark');
  }
};

export { useDarkMode as useDarkModeState, useIsDarkMode, DarkModeContainer };
