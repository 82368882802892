import { useQuery } from 'react-query';
import { getVersionedStorageKey, localStorage } from 'shared/common/utils';

import { LocalStorageKey } from 'consts';

import { userApi } from '../../api';
import { userQueryKeys } from '../../consts';

export const oAuthScopesQueryFactory =
  (dependancies: { userApi: typeof userApi }) => (clientId: string) => {
    const { userApi } = dependancies;

    const query = useQuery(
      userQueryKeys.oAuthScopes(),
      () => userApi.getOAuthScopes(clientId),
      {
        staleTime: 1000,
        onSuccess: (data) => {
          localStorage.setItem(
            getVersionedStorageKey(LocalStorageKey.OAUTH_SCOPES),
            data.scope
          );
        },
        initialData: () => {
          const persistedData = localStorage.getItem<string[]>(
            getVersionedStorageKey(LocalStorageKey.OAUTH_SCOPES)
          );

          if (persistedData) {
            return { scope: persistedData };
          }
        },
      }
    );

    return {
      ...query,
      data: query.data ? query.data.scope.join(' ') : undefined,
    };
  };

export const useOAuthScopesQuery = oAuthScopesQueryFactory({
  userApi,
});
