import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config } from 'shared/common/config';
import { routes } from 'shared/common/routes';
import { isSyncApp } from 'shared/common/services/sync-app';
import { formatDate } from 'shared/common/utils';

import ketoMojoLogoMono from 'assets/svg/keto-logo-mono.svg';
import facebookLogo from 'assets/svg/social-icons/facebook.svg';
import instagramLogo from 'assets/svg/social-icons/instagram.svg';
import twitterLogo from 'assets/svg/social-icons/twitter.svg';
import { Select } from 'components/forms-v2/inputs/select/Select';
import { KetoManIcon } from 'components/Icons/index';
import {
  KETO_FACEBOOK_LINK,
  KETO_INSTAGRAM_LINK,
  KETO_TWITTER_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from 'consts';
import { SupportedLanguageCode } from 'consts/supported-language-code';

import { AppFooterNavigationLinks } from './app-footer-navigation-links';

import styles from './app-footer.module.scss';

interface AppFooterProps {
  onLanguageCodeChange: (code: SupportedLanguageCode) => void;
  selectedLanguageCode: SupportedLanguageCode;
}

export const AppFooter = ({
  onLanguageCodeChange,
  selectedLanguageCode,
}: AppFooterProps) => {
  const { t } = useTranslation();
  const currentYear = formatDate(new Date(), 'yyyy');

  const socialLinks = [
    {
      link: KETO_FACEBOOK_LINK,
      iconSrc: facebookLogo,
      iconAlt: t(`Ours Facebook`),
    },
    {
      link: KETO_INSTAGRAM_LINK,
      iconSrc: instagramLogo,
      iconAlt: t('Ours Instagram'),
    },
    {
      link: KETO_TWITTER_LINK,
      iconSrc: twitterLogo,
      iconAlt: t('Ours Twitter'),
    },
  ];

  const legalLinks = [
    <Link key="cookiePolicy" to={routes.cookiePolicy}>
      {t('Cookie Policy')}
    </Link>,
    <Link key="privacyPolicy" to={routes.privacyPolicy}>
      {t('Privacy Policy')}
    </Link>,
    <a
      key="termsAndConditions"
      href={TERMS_AND_CONDITIONS_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('Terms and Conditions')}
    </a>,
  ];

  return (
    <footer className="bg-white p-16 dark:bg-black">
      <div className="container mx-auto flex flex-col space-x-0 space-y-8 md:flex-row md:space-x-8 md:space-y-0">
        <div className="w-100 md:w-1/3">
          <img
            className="mb-8"
            src={ketoMojoLogoMono}
            alt={t('KetoMojo Logo')}
          />
          <ul className="flex items-center space-x-7">
            {socialLinks.map(
              ({ link, iconSrc, iconAlt }) =>
                link && (
                  <li key={link}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <img src={iconSrc} alt={iconAlt} />
                    </a>
                  </li>
                )
            )}
          </ul>
        </div>

        <div className="flex w-2/3 flex-col flex-wrap justify-start space-x-0 space-y-8 md:flex-row md:space-y-0 xl:space-x-8">
          <div className="max-w-52 grow">
            <h4 className={styles.heading}>{t('Navigation')}</h4>
            <ul>
              <AppFooterNavigationLinks linkClassName={styles.link} />
            </ul>
          </div>
          <div className="max-w-52 grow">
            <h4 className={styles.heading}>{t('Legal')}</h4>
            <ul>
              {legalLinks.map((link, index) => (
                <li key={index} className={styles.link}>
                  {link}
                </li>
              ))}
            </ul>
          </div>
          {!isSyncApp() && (
            <div className="w-52 justify-self-center md:pt-6">
              <Select
                data-testid="lang-select"
                options={languageSelectOptions}
                onChange={onLanguageCodeChange}
                value={selectedLanguageCode}
              />
            </div>
          )}
        </div>
      </div>
      <div className="container mx-auto mt-12 flex items-center justify-center gap-2 border-t border-gray-200 pt-8 text-center text-base font-normal leading-6 text-gray-400 dark:border-gray-700 dark:text-gray-500">
        <span>
          {t('Copyright')} © {currentYear} Keto-Mojo
        </span>
        <KetoManIcon />
        <span>{`v${config.getAppVersion()}`}</span>
      </div>
    </footer>
  );
};

const languageSelectOptions = [
  { value: SupportedLanguageCode.DE, label: 'Deutsch' },
  { value: SupportedLanguageCode.EN, label: 'English' },
  { value: SupportedLanguageCode.ES, label: 'Español' },
  { value: SupportedLanguageCode.FR, label: 'Français' },
  { value: SupportedLanguageCode.IT, label: 'Italiano' },
  { value: SupportedLanguageCode.NL, label: 'Nederlands' },
  { value: SupportedLanguageCode.PT, label: 'Português' },
  { value: SupportedLanguageCode.EL, label: 'Ελληνικά' },
  { value: SupportedLanguageCode.SV, label: 'Svenska' },
  { value: SupportedLanguageCode.TR, label: 'Türkçe' },
  { value: SupportedLanguageCode.PL, label: 'Polski' },
];
