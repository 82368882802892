import React from 'react';
import { MIN_MEDIA_QUERIES, useWatchMediaQuery } from 'shared/common/hooks';

import { TextTruncatedDesktop } from './components/text-truncated-desktop';
import { TextTruncatedMobile } from './components/text-truncated-mobile';

interface TextTruncatedProps {
  text: string;
  visibleLength?: number;
  as?: React.ElementType;
  className?: string;
  isMobile?: boolean;
}

export const TextTruncated: React.VFC<TextTruncatedProps> = ({
  text,
  visibleLength = text.length,
  as = 'p',
  className = '',
  isMobile: customIsMobile,
}) => {
  const isMobile = !useWatchMediaQuery(MIN_MEDIA_QUERIES.MD);

  return customIsMobile ?? isMobile ? (
    <TextTruncatedMobile
      text={text}
      visibleLength={visibleLength}
      as={as}
      className={className}
    />
  ) : (
    <TextTruncatedDesktop
      text={text}
      visibleLength={visibleLength}
      as={as}
      className={className}
    />
  );
};
