import { getTimezoneOffset, toDate, utcToZonedTime } from 'date-fns-tz';

import { getLocalTimezone } from '../get-local-timezone/get-local-timezone';

export const getZonedTimestampFactory =
  ({ localTimezone }: { localTimezone: string }) =>
  ({ timestamp, timezone }: { timestamp: string; timezone: string }) => {
    const ifNonUtcOffset = checkIfNonUtcOffset(timestamp);
    const isUtcTimestamp = checkIfUtcTimestamp(timestamp);

    if (ifNonUtcOffset) {
      const offset = getOffsetMatch(timestamp)![0] as string;
      const readingTimezoneOffset = getTimezoneOffset(offset) / 1000 / 60 / 60;
      const systemTimezoneOffset =
        getTimezoneOffset(localTimezone) / 1000 / 60 / 60;

      const showTimezone = readingTimezoneOffset !== systemTimezoneOffset;
      const zonedDate = utcToZonedTime(
        toDate(timestamp, { timeZone: offset }),
        offset
      );

      return { showTimezone, zonedDate, timezone: offset };
    } else if (isUtcTimestamp) {
      const zonedDate = utcToZonedTime(new Date(timestamp), timezone);
      const showTimezone = timezone !== localTimezone;

      return { showTimezone, zonedDate, timezone };
    } else {
      throw new Error(`Timestamp ${timestamp} is not in UTC or has no offset`);
    }
  };

export const getZonedTimestamp = getZonedTimestampFactory({
  localTimezone: getLocalTimezone(),
});

const checkIfNonUtcOffset = (timestamp: string) => {
  const offsetMatch = getOffsetMatch(timestamp); // Extract offset part from the timestamp

  if (offsetMatch) {
    const hours = parseInt(offsetMatch[2]); // Hours part of the offset
    const minutes = parseInt(offsetMatch[3]); // Minutes part of the offset

    return !(hours === 0 && minutes === 0);
  } else {
    // If no offset is present or it's equal to 00:00, return false
    return false;
  }
};

const checkIfUtcTimestamp = (timestamp: string) => {
  const utcPattern = /(Z)$/; // Regular expression pattern to match the UTC part of the timestamp
  const utcMatch = timestamp.match(utcPattern); // Extract offset part from the timestamp
  const offsetMatch = getOffsetMatch(timestamp); // Extract offset part from the timestamp

  if (offsetMatch) {
    const hours = parseInt(offsetMatch[2]); // Hours part of the offset
    const minutes = parseInt(offsetMatch[3]); // Minutes part of the offset

    return hours === 0 && minutes === 0;
  } else if (utcMatch) {
    return true;
  } else {
    // If no offset is present or it's equal to 00:00, return false
    return false;
  }
};

const getOffsetMatch = (timestamp: string) => {
  const offsetPattern = /([+-])(\d{2}):(\d{2})$/; // Regular expression pattern to match the offset part of the timestamp
  const offsetMatch = timestamp.match(offsetPattern); // Extract offset part from the timestamp

  return offsetMatch;
};
