export enum ReadingType {
  BLOOD_PRESSURE_SYSTOLIC = 'blood_pressure_systolic',
  BLOOD_PRESSURE_DIASTOLIC = 'blood_pressure_diastolic',
  KETONE = 'ketone',
  GLUCOSE = 'glucose',
  GLUCOSE_KETONE_INDEX = 'glucose_ketone_index',
  DBR = 'dbr',
  CARBS = 'carbohydrates',
  FAT = 'fat',
  PROTEIN = 'protein',
  WEIGHT = 'weight',
  CALORIES = 'calories',
  WAIST = 'waist',
  VITAMIN_D = 'vitamin_d',
  MAGNESIUM = 'magnesium',
  POTASSIUM = 'potassium',
  OMEGA_3 = 'omega_3',
  OMEGA_3_EPA = 'omega_3_epa',
  OMEGA_3_DHA = 'omega_3_dha',
  ZINC = 'zinc',
  VITAMIN_B12 = 'vitamin_b12',
  SELENIUM = 'selenium',
  BMI = 'bmi',
  HEART_RATE = 'hr',
  HEART_RATE_VARIABILITY = 'hrv',
  SLEEP_SESSION = 'sleep_session',
  // SLEEP_AWAKE = 'sleep_awake',
  // SLEEP_REM = 'sleep_rem',
  // SLEEP_LIGHT = 'sleep_light',
  // SLEEP_DEEP = 'sleep_deep',

  // HOMA_IR = 'homa_ir',

  INSULIN_SERUM = 'insulin_serum',
  HDL = 'hdl',
  LDL = 'ldl',
  TRIGLYCERIDES = 'triglycerides',
  HBA1C = 'hba1c',
  APOB = 'apob',
  APOAL = 'apoal',
  T3 = 't3',
  FREE_T3 = 'free_t3',
  T4 = 't4',
  FREE_T4 = 'free_t4',
  TSH = 'tsh',
  CARNITINE = 'carnitine',

  VISCERAL_FAT_PERCENTAGE = 'visceral_fat_percentage',
  ABSOLUTE_FAT_PERCENTAGE = 'absolute_fat_percentage',
  MUSCLE_MASS_PERCENTAGE = 'muscle_mass_percentage',
  HSCRP = 'hscrp',
  ALANINE_AMINOTRANSFERASE = 'alanine_aminotransferase',
  ALBUMIN = 'albumin',
  ALKALINE_PHOSPHATASE = 'alkaline_phosphatase',
  ASPARTATE_AMINOTRANSFERASE = 'aspartate_aminotransferase',
  BICARBONATE = 'bicarbonate',
  BILIRUBIN = 'bilirubin',
  BLOOD_UREA_NITROGEN = 'blood_urea_nitrogen',
  CALCIUM = 'calcium',
  CHLORIDE = 'chloride',
  CREATININE = 'creatinine',
  SODIUM = 'sodium',
  TOTAL_PROTEIN = 'total_protein',
  COPPER = 'copper',
  TNFA = 'tnfa',
  IL6 = 'il6',
  BONE_MASS = 'bone_mass',
  FAT_FREE_MASS = 'fat_free_mass',
  HEIGHT = 'height',
  VISCERAL_FAT = 'visceral_fat',
  ABSOLUTE_FAT = 'absolute_fat',
  MUSCLE_MASS = 'muscle_mass',

  INSULIN_BASAL = 'insulin_basal',
  INSULIN_BOLUS = 'insulin_bolus',
}

export const readingTypes = Object.values(ReadingType) as ReadingType[];

export const getReadingTypes = () =>
  (Object.keys(ReadingType) as (keyof typeof ReadingType)[]).map(
    (key) => ReadingType[key]
  );

export type TReadingType = `${ReadingType}`;

export enum ExtraReadingTypes {
  HEMOGLOBIN = 'hemoglobin',
  HEMATOCRIT = 'hematocrit',
}

export const isReadingType = (value: any): value is ReadingType =>
  Object.values(ReadingType).includes(value as ReadingType);

export type ExpandedReadingType = ReadingType | ExtraReadingTypes;

export type MacroReadingType =
  | ReadingType.CARBS
  | ReadingType.PROTEIN
  | ReadingType.FAT;

export type GkrReadingType = ReadingType.GLUCOSE_KETONE_INDEX | ReadingType.DBR;

export const isMacroReadingType = (
  readingType: ReadingType
): readingType is MacroReadingType =>
  [ReadingType.CARBS, ReadingType.FAT, ReadingType.PROTEIN].includes(
    readingType
  );
