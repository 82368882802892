import isEqual from 'lodash/isEqual';

import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

export const getSelectLabel = <T>(
  options: DropdownOptionItem<T>[],
  selected: T[] | T | null
) =>
  options.reduce((acc, cur) => {
    if (!selected) return acc;
    if (!Array.isArray(selected)) {
      if (isEqual(selected, cur.value)) return [cur.label];
      return acc;
    }

    const isOptionSelected =
      selected.findIndex((s: T) => isEqual(cur.value, s)) !== -1;

    return isOptionSelected ? [...acc, cur.label] : acc;
  }, [] as string[]);
