import React from 'react';

import Spinner from 'components/Spinner';

interface IProps {
  heading?: string;
  subHeading?: string;
}

const OverlayLoading: React.FC<IProps> = ({ subHeading, heading }) => (
  <div className="fixed inset-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-300 opacity-90 dark:bg-gray-800">
    <Spinner size={12} className="mb-4" />
    {heading && (
      <h2 className="text-3xl font-medium dark:text-white">{heading}</h2>
    )}
    {subHeading && <p className="text-lg dark:text-white">{subHeading}</p>}
  </div>
);

export default OverlayLoading;
