import {
  Action,
  createContainer,
  createHook,
  createStore,
  defaults,
} from 'react-sweet-state';
import { localStorage, storeSideEffectMiddleware } from 'shared/common/utils';

import { LocalStorageKey } from 'consts';

const STORE_NAME = 'userPracticesTableState';

interface State {
  pageSize: number;
  textFilter: string;
}

type PersistedState = Pick<State, 'pageSize'>;

const initialState: State = {
  pageSize: 10,
  textFilter: '',
};

const actions = {
  setTextFilter:
    (textFilter: string): Action<State> =>
    ({ setState }) => {
      setState({ textFilter });
    },
  setTableState:
    ({ pageSize }: Pick<State, 'pageSize'>): Action<State> =>
    ({ setState }) => {
      setState({ pageSize });
    },
};

type Actions = typeof actions;

const store = createStore<State, Actions>({
  name: STORE_NAME,
  initialState,
  actions,
});

defaults.middlewares.add(
  storeSideEffectMiddleware(STORE_NAME, (state: State) => {
    const persistedState: PersistedState = {
      pageSize: state.pageSize,
    };

    localStorage.setItem(
      LocalStorageKey.USER_PRACTICES_TABLE_STATE,
      persistedState
    );
  })
);

const useUserPracticesTableState = createHook(store);

const UserPracticesTableContainer = createContainer(store, {
  onInit:
    () =>
    ({ setState, getState }) => {
      const initialState = localStorage.getItem<PersistedState>(
        LocalStorageKey.USER_PRACTICES_TABLE_STATE
      );

      setState({
        ...getState(),
        ...(initialState ?? {}),
      });
    },
});

export { useUserPracticesTableState, UserPracticesTableContainer };
