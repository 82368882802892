import { TrendType } from 'shared/reading/types';

import { ReadingName } from 'domain/domain.models';

import { TimeInRangeState } from '../../types/time-in-range-state';

export const getTimeInRangeTrendType = ({
  currentPercentage,
  previousPercentage,
  timeInRangeState,
}: {
  currentPercentage: number;
  previousPercentage?: number;
  timeInRangeState: TimeInRangeState;
  readingName: ReadingName;
}): TrendType => {
  if (previousPercentage === undefined) {
    return TrendType.NO_PREVIOUS_DATA;
  }

  if (currentPercentage === previousPercentage) {
    if (
      timeInRangeState === TimeInRangeState.IN_RANGE ||
      currentPercentage === 0
    ) {
      return TrendType.NEUTRAL;
    }
    return TrendType.NEGATIVE;
  }

  if (currentPercentage > previousPercentage) {
    if (timeInRangeState === TimeInRangeState.IN_RANGE) {
      return TrendType.POSITIVE;
    }
    return TrendType.NEGATIVE;
  }

  // currentPercentage < previousPercentage
  if (timeInRangeState === TimeInRangeState.IN_RANGE) {
    return TrendType.NEGATIVE;
  }
  return TrendType.POSITIVE;
};
