import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import snakeCase from 'lodash/snakeCase';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  pathParams?: object;
}

export const httpClientFactory =
  (http: AxiosInstance) =>
  <T>(
    url: string,
    config?: ExtendedAxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    const newConfig = { ...config, url };

    if (config?.pathParams) {
      newConfig.url = interpolateParams(newConfig.url, config?.pathParams);
    }

    if (config?.params) {
      newConfig.url = buildQueryString(newConfig.url, config?.params);
      delete newConfig.params;
    }

    return http(newConfig);
  };

const interpolateParams = (url: string, params: Object) => {
  Object.entries(params).forEach(
    ([key, value]) => (url = url.replace(new RegExp(`:${key}`, 'g'), value))
  );
  return url;
};

const buildQueryString = (
  url: string,
  params: { [key: string]: string | string[] | Date }
) => {
  const searchParams = new URLSearchParams();

  for (let key in params) {
    const value = params[key];

    if (value instanceof Date) {
      searchParams.append(snakeCase(key), value.toISOString());
    } else if (Array.isArray(value)) {
      value.forEach((val) => {
        searchParams.append(snakeCase(key), val);
      });
    } else if (value !== undefined && value !== null && value !== '') {
      searchParams.append(snakeCase(key), value);
    }
  }

  return `${url}?${searchParams.toString()}`;
};
