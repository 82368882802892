import { sub } from 'date-fns';
import max from 'lodash/max';

import { READINGS_START_DATE_THRESHOLD } from 'consts';

/**
 * Resolves the oldest reading date based on the provided date and a threshold date.
 * If the provided date is null, the threshold date is used instead.
 * The resolved date is capped at 12 years ago from the current date.
 * @param oldestReadingDate The oldest reading date to resolve.
 * @returns The resolved oldest reading date.
 */
export const resolveOldestReadingDateFactory =
  (deps: { readingsStartDateThreshold: Date }) =>
  (oldestReadingDate: Date | null): Date => {
    oldestReadingDate = oldestReadingDate ?? deps.readingsStartDateThreshold;
    const maxOldestReadingDate = sub(new Date(), { years: 12 });

    return max([oldestReadingDate, maxOldestReadingDate]) as Date;
  };

export const resolveOldestReadingDate = resolveOldestReadingDateFactory({
  readingsStartDateThreshold: READINGS_START_DATE_THRESHOLD,
});
