import { TrendColor } from '../../../types';

const iconColors = {
  [TrendColor.GREEN]: {
    background: 'bg-green-50 dark:bg-green-500',
    text: 'text-green-600 dark:text-green-500',
    textAndBackground:
      'text-green-600 dark:text-green-100 bg-green-100 dark:bg-green-700',
  },
  [TrendColor.RED]: {
    background: 'bg-red-50 dark:bg-red-500',
    text: 'text-red-600 dark:text-red-400',
    textAndBackground:
      'text-red-600 dark:text-red-100 bg-red-100 dark:bg-red-700',
  },
  [TrendColor.BLUE]: {
    background: 'bg-blue-50 dark:bg-blue-500',
    text: 'text-blue-600 dark:text-blue-400',
    textAndBackground:
      'text-blue-600 dark:text-blue-100 bg-blue-100 dark:bg-blue-700',
  },
  [TrendColor.GRAY]: {
    background: 'bg-gray-100 dark:bg-gray-500',
    text: 'text-gray-600 dark:text-gray-400',
    textAndBackground:
      'text-gray-600 dark:text-gray-50 bg-gray-100 dark:bg-gray-500',
  },
};

export const getTrendColorClasses = (trendColor: TrendColor) =>
  iconColors[trendColor];
