import { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import clsx from 'clsx';
import { OpacityTransition } from 'shared/common/components/atoms';

import styles from './styles.module.scss';

export const DesktopMenuItems: FC<{
  classes?: {
    menu?: string;
    items?: string;
  };
  targetElement: HTMLDivElement | null;
}> = ({ children, classes, targetElement }) => {
  const [tooltipContainer] = useState(() => document.createElement('div'));
  const popperElRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(
    targetElement,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    }
  );

  useEffect(() => {
    document.body.appendChild(tooltipContainer);
    return () => {
      document.body.removeChild(tooltipContainer);
    };
  }, [tooltipContainer]);

  return createPortal(
    <div
      ref={popperElRef}
      style={popperStyles.popper}
      {...attributes.popper}
      className="z-10"
    >
      <OpacityTransition
        beforeEnter={() => setPopperElement(popperElRef.current)}
        afterLeave={() => setPopperElement(null)}
      >
        <HeadlessUIMenu.Items className={clsx(styles.items, classes?.items)}>
          {children}
        </HeadlessUIMenu.Items>
      </OpacityTransition>
    </div>,
    tooltipContainer
  );
};
