import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

export const getDropdownCategorizedOptions = <T>(
  options: DropdownOptionItem<T>[]
) => {
  const categoriesMap = options.reduce((acc, cur) => {
    const category = cur.category;

    if (category && !acc[category]) {
      acc[category] = [cur];
    } else if (category) {
      acc[category] = [...acc[category], cur];
    } else {
      // fallback if some options do not have category - no category displayed
      acc[''] = [...(acc[''] ?? []), cur];
    }

    return acc;
  }, {} as { [key: string]: DropdownOptionItem<T>[] });

  return Object.entries(categoriesMap);
};
