import React, { createContext, ReactNode, useContext } from 'react';

import { ReadingNameToUnitMap } from 'domain/domain.models';

const UnitsContext = createContext<ReadingNameToUnitMap | undefined>(undefined);

interface UnitsProviderProps {
  children: ReactNode;
  units: ReadingNameToUnitMap;
}

export const UnitsProvider: React.FC<UnitsProviderProps> = ({
  children,
  units,
}) => <UnitsContext.Provider value={units}>{children}</UnitsContext.Provider>;

export const useUnits = () => {
  const context = useContext(UnitsContext);
  if (context === undefined) {
    throw new Error('useUnits must be used within a UnitsProvider');
  }
  return context;
};
