import i18next from 'i18next';
import { PracticeRole } from 'shared/common/consts';
import { getPracticeTypeDependantLabels } from 'shared/practice/utils';

import { PracticeType } from '../types';

export const getPracticeRoleDescription = ({
  practiceType,
  practiceRole,
}: {
  practiceRole: PracticeRole;
  practiceType: PracticeType;
}): { description: string; label: string } => {
  const {
    getPatientLabel,
    getPracticeLabel,
    getPracticeOwnerLabel,
    getHcpLabel,
    getPracticeAdminLabel,
  } = getPracticeTypeDependantLabels(practiceType);

  switch (practiceRole) {
    case PracticeRole.PRACTICE_OWNER:
      return {
        label: getPracticeOwnerLabel(),
        description: i18next.t(
          'Can perform any action on {{ patientsLabel }} or {{ practicesLabel }}, including deleting {{ practiceLabel }}. Only one user ca be {{ practiceOwnerLabel }}.',
          {
            practicesLabel: getPracticeLabel({ isPlural: true }),
            practiceLabel: getPracticeLabel(),
            patientsLabel: getPatientLabel({ isPlural: true }),
            practiceOwnerLabel: getPracticeOwnerLabel(),
          }
        ),
      };
    case PracticeRole.PRACTICE_ADMIN:
      return {
        label: getPracticeAdminLabel(),
        description: i18next.t(
          'Full access to {{ patientsLabel }} data. Can promote {{ hcpLabel }} to the {{ adminLabel2 }} role. Multiple users can be {{ adminLabel }}.',
          {
            hcpLabel: getHcpLabel({
              isPlural: true,
            }),
            adminLabel: getPracticeAdminLabel({ isPlural: true }),
            adminLabel2: getPracticeAdminLabel(),
            patientsLabel: getPatientLabel({ isPlural: true }),
          }
        ),
      };
    case PracticeRole.HCP:
      return {
        label: getHcpLabel(),
        description: i18next.t(
          'Can view {{ patientLabel }} data, can invite and manage {{ patientLabel }}. Cannot change {{ practiceLabel }} goals and ranges. Cannot manage or upgrade others to {{ adminLabel }} roles.',
          {
            adminLabel: getPracticeAdminLabel(),
            practiceLabel: getPracticeLabel(),
            patientLabel: getPatientLabel({ isPlural: true }),
          }
        ),
      };
    default:
      return {
        label: '',
        description: '',
      };
  }
};
