import { FC, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useModalActions, useModalState } from 'shared/common/store';
import { ModalId } from 'shared/common/types';

import { ModalContextProvider } from './shared/modal.context';

export const ModalRoot: FC<{ id: ModalId }> = ({ children, id }) => {
  const state = useModalState();
  const { closeModal } = useModalActions();
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const isOpen = !!state.activeModals.find((modal) => modal === id);

  return (
    <ModalContextProvider id={id}>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={() => closeModal(id)}
          initialFocus={modalBodyRef}
        >
          <div className="min-h-screen p-0 text-center sm:block sm:px-2">
            <ModalOverlay />
            <ModalCenter />
            <ModalContentWrapper>{children}</ModalContentWrapper>
          </div>
        </Dialog>
      </Transition.Root>
    </ModalContextProvider>
  );
};

export const ModalOverlay = () => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-30 backdrop-blur-sm backdrop-filter transition-opacity" />
  </Transition.Child>
);

/* This element is to trick the browser into centering the modal contents. */
export const ModalCenter = () => (
  <span className="inline-block h-screen align-middle" aria-hidden="true">
    &#8203;
  </span>
);

export const ModalContentWrapper: FC = ({ children }) => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0 translate-y-4 translate-y-0 scale-95"
    enterTo="opacity-100 translate-y-0 scale-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 translate-y-0 scale-100"
    leaveTo="opacity-0 translate-y-0 scale-95"
  >
    <div
      className={`inline-block min-h-screen w-full transform
            rounded-none bg-white p-8 text-left align-middle shadow-xl
            transition-all dark:bg-black sm:my-8 sm:min-h-0 sm:max-w-xl sm:rounded-lg`}
    >
      {children}
    </div>
  </Transition.Child>
);
