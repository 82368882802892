import React from 'react';
import clsx from 'clsx';

import { ReadingTypeColor } from 'consts/colors/colors';

// Default colors
const defaultColors = {
  gray: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-50',
  green: 'bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-50',
  red: 'bg-red-100 text-red-800 dark:bg-red-700 dark:text-red-50',
  yellow:
    'bg-yellow-100 text-yellow-800 dark:bg-yellow-700 dark:text-yellow-50',
  blue: 'bg-blue-100 text-blue-800 dark:bg-blue-700 dark:text-blue-50',
  indigo:
    'bg-indigo-100 text-indigo-800 dark:bg-indigo-700 dark:text-indigo-50',
  purple:
    'bg-purple-100 text-purple-800 dark:bg-purple-700 dark:text-purple-50',
  pink: 'bg-pink-100 text-pink-800 dark:bg-pink-700 dark:text-pink-50',
};

// Keto-Mojo colors
const brandColors: Record<ReadingTypeColor, string> = Object.values(
  ReadingTypeColor
).reduce(
  (colors, color: ReadingTypeColor) => ({
    ...colors,
    [color]: `bg-${color}-100 text-${color}-800 dark:bg-${color}-700 dark:text-${color}-50`,
  }),
  {} as Record<ReadingTypeColor, string>
);

export type BrandColors = typeof brandColors;

const colors = {
  ...defaultColors,
  ...brandColors,
};
type Colors = typeof colors;

const dotColors: Colors = {
  // Default colors
  gray: 'bg-gray-400',
  green: 'bg-green-400',
  red: 'bg-red-400',
  yellow: 'bg-yellow-400',
  blue: 'bg-blue-400',
  indigo: 'bg-indigo-400',
  purple: 'bg-purple-400',
  pink: 'bg-pink-400',

  // Keto-Mojo colors
  [ReadingTypeColor.ketoneBlue]: 'bg-ketoneBlue-400',
  [ReadingTypeColor.glucoseBrown]: 'bg-glucoseBrown-400',
  [ReadingTypeColor.gkiGrey]: 'bg-gkiGrey-400',
  [ReadingTypeColor.carbsBlue]: 'bg-carbsBlue-400',
  [ReadingTypeColor.proteinGreen]: 'bg-proteinGreen-400',
  [ReadingTypeColor.fatPink]: 'bg-fatPink-400',
  [ReadingTypeColor.ketoneBreath]: 'bg-ketoneBreath-400',
  [ReadingTypeColor.ketoneUrine]: 'bg-ketoneUrine-400',
  [ReadingTypeColor.calories]: 'bg-calories-400',
  [ReadingTypeColor.waist]: 'bg-waist-400',
  [ReadingTypeColor.weight]: 'bg-weight-400',
  [ReadingTypeColor.vitaminD]: 'bg-vitaminD-400',
  [ReadingTypeColor.magnesium]: 'bg-magnesium-400',
  [ReadingTypeColor.potassium]: 'bg-potassium-400',
  [ReadingTypeColor.omega3]: 'bg-omega3-400',
  [ReadingTypeColor.omega3epa]: 'bg-omega3epa-400',
  [ReadingTypeColor.omega3dha]: 'bg-omega3dha-400',
  [ReadingTypeColor.zinc]: 'bg-zinc-400',
  [ReadingTypeColor.vitaminB12]: 'bg-vitaminB12-400',
  [ReadingTypeColor.selenium]: 'bg-selenium-400',
  [ReadingTypeColor.bmi]: 'bg-bmi-400',
  [ReadingTypeColor.heartRate]: 'bg-heartRate-400',
  [ReadingTypeColor.heartRateVariability]: 'bg-heartRateVariability-400',
  [ReadingTypeColor.bloodPressureSystolic]: 'bg-bloodPressureSystolic-400',
  [ReadingTypeColor.bloodPressureDiastolic]: 'bg-bloodPressureDiastolic-400',
};

// Size
const sizes = {
  small: 'px-2.5 py-0.5 text-xs font-medium leading-4',
  large: 'px-3 py-0.5 text-sm font-medium leading-5',
};
type Sizes = typeof sizes;

interface IBadgeProps {
  size?: keyof Sizes;
  color?: keyof Colors;
  rounded?: 'rounded-full' | 'rounded-md';
  withDot?: boolean;
  children: React.ReactNode;
  className?: string;
}

const Badge: React.FC<IBadgeProps> = ({
  size = 'small',
  color,
  rounded = 'rounded-full',
  withDot = false,
  children,
  className,
}) => (
  <div
    className={clsx(
      'inline-flex items-center',
      sizes[size],
      color && colors[color],
      rounded,
      className
    )}
    role="status"
  >
    {withDot && (
      <div
        className={clsx('mr-2 h-2 w-2 rounded-full', color && dotColors[color])}
      />
    )}
    <span>{children}</span>
  </div>
);

export default Badge;
