import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import de from 'date-fns/locale/de';
import el from 'date-fns/locale/el';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt';
import sv from 'date-fns/locale/sv';
import tr from 'date-fns/locale/tr';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import httpApi from 'i18next-http-backend';
import { isSyncApp } from 'shared/common/services/sync-app';
import { getUserLanguageCode } from 'shared/common/utils';

import { SupportedLanguageCode } from 'consts/supported-language-code';

const langCodeToLocaleMap: Record<SupportedLanguageCode, Locale> = {
  [SupportedLanguageCode.DE]: de,
  [SupportedLanguageCode.FR]: fr,
  [SupportedLanguageCode.ES]: es,
  [SupportedLanguageCode.PT]: pt,
  [SupportedLanguageCode.EN]: enUS,
  [SupportedLanguageCode.EL]: el,
  [SupportedLanguageCode.PL]: pl,
  [SupportedLanguageCode.TR]: tr,
  [SupportedLanguageCode.SV]: sv,
  [SupportedLanguageCode.IT]: it,
  [SupportedLanguageCode.NL]: nl,
};

Object.entries(langCodeToLocaleMap).forEach(([langCode, locale]) => {
  registerLocale(langCode as SupportedLanguageCode, locale);
});

const langCode = getUserLanguageCode();

setDefaultLocale(langCode);

export const initializeI18n = (): void => {
  i18n
    .use(initReactI18next)
    .use(httpApi)
    .use(I18nextBrowserLanguageDetector)
    .init({
      backend: {
        loadPath: '/i18n/{{lng}}.json',
      },
      detection: {
        // for sync lang should be determined only by browser language
        // for web we want to rely on selected language saved in local storage
        order: isSyncApp()
          ? ['navigator', 'localStorage']
          : ['localStorage', 'navigator'],
        lookupLocalStorage: 'i18nextLng',
      },
      // allow keys to be phrases having `:`, `.`
      nsSeparator: false,
      keySeparator: false,

      // do not load a fallback
      fallbackLng: false,

      contextSeparator: '^',
      pluralSeparator: '^',
      interpolation: { escapeValue: false },
      react: {
        useSuspense: !!process.env.I18N_DISABLE_SUSPENSE,
      },
    });
};
