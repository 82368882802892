// import styles from "./DropdownButton.module.scss";

import { forwardRef } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { getDataPrivateAttr } from 'shared/common/utils';

import { SELECT_ARIA_CONTROLS } from '../../forms.consts';

import styles from './DropdownButton.module.scss';

interface DropdownButtonProps {
  onClick: () => void;
  isOpen: boolean;
  value?: string[];
  placeholder?: string;
  isMultiselect?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  id?: string;
  icon?: IconComponent;
  'data-testid'?: string;
  'data-private'?: boolean;
}

export const DropdownButton = forwardRef<
  HTMLButtonElement,
  DropdownButtonProps
>(
  (
    {
      onClick,
      isOpen,
      value = [],
      placeholder = '',
      isMultiselect = false,
      isError = false,
      isDisabled = false,
      id,
      icon: Icon,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      type="button"
      disabled={isDisabled}
      onClick={() => !isDisabled && onClick()}
      id={id}
      role="combobox"
      aria-expanded={isOpen}
      aria-controls={SELECT_ARIA_CONTROLS}
      aria-haspopup="listbox"
      data-testid={props['data-testid']}
      {...getDataPrivateAttr(props['data-private'])}
      className={clsx(
        styles.button,
        isError && styles.isInvalid,
        isDisabled && styles.isDisabled
      )}
    >
      {Icon && (
        <div className={styles.iconWrapper}>
          <Icon aria-hidden="true" />
        </div>
      )}
      {!!value.length ? (
        <span className={styles.content}>{value.join(', ')}</span>
      ) : (
        <span className={clsx(styles.placeholder, styles.content)}>
          {placeholder}
        </span>
      )}
      <div className={styles.wrapper}>
        {isMultiselect && !!value.length && (
          <span className={styles.count} data-testid="dropdown-button__count">
            {value.length}
          </span>
        )}
        <span className={styles.arrowsWrapper}>
          {isOpen ? (
            <ChevronUpIcon
              aria-hidden="true"
              data-testid="dropdown-button__arrow-up"
            />
          ) : (
            <ChevronDownIcon
              aria-hidden="true"
              data-testid="dropdown-button__arrow-down"
            />
          )}
        </span>
      </div>
    </button>
  )
);
