import {
  ChatAltIcon,
  CogIcon,
  KeyIcon,
  PuzzleIcon,
  ServerIcon,
  TableIcon,
} from '@heroicons/react/outline';
import i18n from 'i18next';
import { routes } from 'shared/common/routes';
import { getIsAuthorized, getOldDashboardRoutes } from 'shared/common/utils';
import { User } from 'shared/user/types';

import { DropdownOptionItem } from 'components/forms-v2/shared/forms.types';

import { feedbackFormLink } from '../TopNav.consts';

export const getBottomMenuItems = ({
  userRoles,
  oldDashboardUrl,
}: {
  userRoles: User['roles'];
  oldDashboardUrl: string;
}): DropdownOptionItem<string>[] => {
  const showApiPartnerLink = getIsAuthorized(
    'accessOldDashboardApiPartnerSettings',
    {
      userRoles,
    }
  );

  const showAdminPanelLink = getIsAuthorized('accessOldDashboardAdminPanel', {
    userRoles,
  });

  const oldDashboardRoutes = getOldDashboardRoutes(oldDashboardUrl);

  return [
    {
      value: `internalLink ${routes.user.settings.index}`,
      icon: CogIcon,
      label: i18n.t('Settings'),
      'data-testid': 'top-nav__menu-item--settings',
    },
    {
      value: `internalLink ${routes.user.integrations.index}`,
      icon: PuzzleIcon,
      label: i18n.t('Integrations'),
      'data-testid': 'top-nav__menu-item--integrations',
    },
    {
      value: `internalLink ${routes.user.practiceDirectory.index}`,
      icon: TableIcon,
      label: i18n.t('Practice Directory'),
      'data-testid': 'top-nav__menu-item--practiceDirectory',
    },
    ...(showApiPartnerLink
      ? [
          {
            value: `externalLink ${oldDashboardRoutes.apiPartnerSettings()}`,
            icon: ServerIcon,
            label: i18n.t('API Admin'),
            'data-testid': 'top-nav__menu-item--api-admin',
          },
        ]
      : []),
    ...(showAdminPanelLink
      ? [
          {
            value: `externalLink ${oldDashboardRoutes.adminPanel()}`,
            icon: KeyIcon,
            label: i18n.t('Admin Panel'),
            'data-testid': 'top-nav__menu-item--admin-panel',
          },
        ]
      : []),
    {
      value: `externalLink ${feedbackFormLink}`,
      icon: ChatAltIcon,
      label: i18n.t('Give Feedback'),
      'data-testid': 'top-nav__menu-item--give-feedback',
    },
  ];
};
