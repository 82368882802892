import { TrendColor, TrendType } from '../../../types';

/**
 * Get color for any type of trend
 */
export const getTrendTypeColor = (trendType: TrendType): TrendColor => {
  switch (trendType) {
    case TrendType.POSITIVE:
      return TrendColor.GREEN;
    case TrendType.NEGATIVE:
      return TrendColor.RED;
    case TrendType.NEUTRAL:
      return TrendColor.BLUE;
    case TrendType.NO_PREVIOUS_DATA:
      return TrendColor.GRAY;
  }
};
