import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToast } from 'shared/common/hooks';

export const useCopyToClipboard = () => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text).then(() => {
        openToast(
          {
            type: ToastType.SUCCESS,
            label: t('Copied!'),
          },
          { duration: 2000 }
        );
      });
    },
    [openToast, t]
  );

  return copyToClipboard;
};
