import { checkIsEmailOrUrl } from 'shared/common/utils';

import { mapToPreferredUnits } from '../../common/utils/map-to-preferred-units/map-to-preferred-units';
import { mapToRanges } from '../../common/utils/map-to-ranges/map-to-ranges';
import { Practice, PracticeDto } from '../types/practice';

export const mapToPractice = (practiceDto: PracticeDto): Practice => {
  const { isEmail: isExistingContactEmail, isUrl: isExistingContactUrl } =
    checkIsEmailOrUrl(practiceDto.ehr_practice_contact_info);

  return {
    id: practiceDto.ehr_practice_id,
    name: practiceDto.ehr_practice_name,
    slug: practiceDto.ehr_practice_canonical_name,
    ehrSystem: {
      ehrName: practiceDto.ehr_system.ehr_name,
      ehrCanonicalName: practiceDto.ehr_system.ehr_canonical_name,
      ehrPartnerSystem: practiceDto.ehr_system.ehr_partner_system,
      canSearchPatients: practiceDto.ehr_system.can_search_patients,
      isSendingReportsEnabled:
        practiceDto.ehr_system.is_sending_reports_enabled,
    },
    isExternalEhrConnected: !!practiceDto.ehr_system.ehr_partner_system,
    reportLength: practiceDto.report_length,
    reportType: practiceDto.report_type,
    reportSchedule: {
      frequency: practiceDto.report_schedule?.frequency || undefined,
      dayNumber: practiceDto.report_schedule?.day_number,
      enabled: practiceDto.report_schedule?.enabled,
      realTimeReports: practiceDto.report_schedule?.real_time_reports,
    },
    description: practiceDto.ehr_practice_description ?? '',
    contactInfo: practiceDto.ehr_practice_contact_info ?? '',
    specialties: practiceDto.ehr_practice_specialties || [],
    hcpSignupEnabled: practiceDto.hcp_signup_enabled,
    listedInHcpdEnabled: practiceDto.listed_in_hcpd_enabled,
    patientSignupEnabled: practiceDto.patient_signup_enabled,
    patientsTags: practiceDto.patients_tags ?? [],
    gkrType: practiceDto.glucose_ketone_ratio_type,
    createdAt: practiceDto.created_at,
    updatedAt: practiceDto.updated_at,
    isChatEnabled: practiceDto.is_chat_enabled,
    patientsCount: practiceDto.patients_count,
    practiceType: practiceDto.ehr_practice_type,
    enabledViewingOtherPatientsReadings:
      practiceDto.enabled_viewing_other_users_readings,
    practiceRoles: practiceDto.practice_roles,
    ranges: mapToRanges(practiceDto),
    preferredUnits: mapToPreferredUnits(practiceDto),
    hcpSignupUrl: practiceDto.hcp_signup_url,
    patientSignupUrl: practiceDto.patient_signup_url,
    qualifyingCriteria: practiceDto.qualifying_criteria ?? '',
    logoImageUrl: practiceDto.logo_image_url,
    country: practiceDto.address_country ?? '',
    state: practiceDto.address_state ?? '',
    town: practiceDto.address_town ?? '',
    street: practiceDto.address_street ?? '',
    streetNumber: practiceDto.address_street_number ?? '',
    zipCode: practiceDto.address_zip_code ?? '',
    email: practiceDto.contact_email
      ? practiceDto.contact_email
      : isExistingContactEmail
      ? practiceDto.ehr_practice_contact_info!
      : '',
    website: practiceDto.contact_website
      ? practiceDto.contact_website
      : isExistingContactUrl
      ? practiceDto.ehr_practice_contact_info!
      : '',
    phone: practiceDto.contact_phone ?? '',
    languages: practiceDto.languages,
    practiceOwner: practiceDto.practice_owner
      ? {
          id: practiceDto.practice_owner.user_id,
          firstName: practiceDto.practice_owner.first_name,
          lastName: practiceDto.practice_owner.last_name,
        }
      : null,
  };
};
