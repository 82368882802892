export const practiceQueryKeys = {
  all: ['practices'] as const,
  lists: () => [...practiceQueryKeys.all, 'list'] as const,
  list: (params?: Record<string, unknown>) =>
    [...practiceQueryKeys.all, 'list', params] as const,
  details: () => [...practiceQueryKeys.all, 'detail'] as const,
  detail: (practiceId: string) =>
    [...practiceQueryKeys.details(), practiceId] as const,
  affiliateLink: (practiceId: string) =>
    ['affiliate-link', practiceId] as const,
  globalRangeDescriptions: (practiceId?: string) =>
    ['globalRangeDescriptions', practiceId] as const,
  practiceRangeDescriptions: (practiceId: string) =>
    ['practiceRangeDescriptions', practiceId] as const,
};
