import { ElementType, FC } from 'react';
import clsx from 'clsx';

interface ModalBodyProps {
  as?: ElementType;
  className?: string;
}

export const ModalBody: FC<ModalBodyProps> = ({
  children,
  className,
  as = 'main',
}) => {
  const Element = as;
  return <Element className={clsx('mb-10', className)}>{children}</Element>;
};
