const DEFAULT_CONFIG = { abs: false, decimals: 0, trailingZeros: true };

/**
 * format a number to a string of a format 69% or -69%
 * @param percent 100% is 1
 * @param config
 */
export const getFormattedPercentages = (
  percent: number,
  config: Partial<typeof DEFAULT_CONFIG> = {}
) => {
  const { decimals, abs, trailingZeros } = { ...DEFAULT_CONFIG, ...config };
  const fraction = Math.pow(10, decimals);
  let percentageValue: string | number =
    Math.round(percent * 100 * fraction) / fraction;
  if (abs) {
    percentageValue = Math.abs(percentageValue);
  }
  if (trailingZeros) {
    percentageValue = Number(percentageValue).toFixed(decimals);
  }

  return `${percentageValue}%`;
};
