import { Ranges } from 'shared/common/store';
import { AverageStats, ReadingsStatsResponseDto } from 'shared/reading/types';
import { ValuesType } from 'utility-types';

import { ReadingName } from 'domain/domain.models';
import { AggregationInterval, StatType, Unit } from 'models';

export const mapToTotalAverageStats = ({
  currentStats,
  pastStats,
  ranges,
}: {
  currentStats: ValuesType<
    ReadingsStatsResponseDto<
      ReadingName,
      StatType.AVG,
      AggregationInterval.TOTAL
    >
  >;
  pastStats?: ValuesType<
    ReadingsStatsResponseDto<
      ReadingName,
      StatType.AVG,
      AggregationInterval.TOTAL
    >
  >;
  ranges: Ranges;
}) =>
  Object.entries(currentStats.stats).reduce((acc, [name, stats]) => {
    const readingName = name as ReadingName;

    const currentStatsAvg = stats.avg.total.total;
    const pastStatsAvg = pastStats?.stats[readingName].avg.total.total;

    const currentAverage = currentStatsAvg
      ? Number(currentStatsAvg.reading_value)
      : undefined;
    const pastAverage = pastStatsAvg
      ? Number(pastStatsAvg.reading_value)
      : undefined;
    const unit = currentStatsAvg ? currentStatsAvg.reading_unit : Unit.NO_UNIT;

    return {
      ...acc,
      [readingName]: {
        readingName,
        currentAverage,
        pastAverage,
        unit,
        range: ranges[readingName],
      },
    };
  }, {} as Record<ReadingName, AverageStats>);
