import {
  Action,
  ContainerComponent,
  createActionsHook,
  createContainer,
  createHook,
  createStateHook,
  createStore,
} from 'react-sweet-state';
import { Practice } from 'shared/practice/types';

const storeName = 'chat';

export interface ChatState {
  isChatInitalized: boolean;
  isChatLoading: boolean;
  practicesWithChat: Practice[];
  totalUnreadCount: number;
  totalUnreadCountByPracticeId: Record<string, number>;
}

const initialState: ChatState = {
  isChatInitalized: false,
  isChatLoading: true,
  practicesWithChat: [],
  totalUnreadCount: 0,
  totalUnreadCountByPracticeId: {},
};

const actions = {
  setIsChatInitialized:
    (isChatInitalized: boolean): Action<ChatState> =>
    ({ setState }) => {
      setState({
        isChatInitalized,
      });
    },
  setIsChatLoading:
    (isChatLoading: boolean): Action<ChatState> =>
    ({ setState }) => {
      setState({
        isChatLoading,
      });
    },
  setPracticesWithChat:
    (practicesWithChat: Practice[]): Action<ChatState> =>
    ({ setState }) => {
      setState({
        practicesWithChat,
      });
    },
  setTotalUnreadCount:
    (totalUnreadCount: number): Action<ChatState> =>
    ({ setState }) => {
      setState({
        totalUnreadCount,
      });
    },
  setTotalUnreadCountByPracticeId:
    (totalUnreadCountByPracticeId: Record<string, number>): Action<ChatState> =>
    ({ setState }) => {
      setState({
        totalUnreadCountByPracticeId,
      });
    },
};

type Actions = typeof actions;

const store = createStore<ChatState, Actions>({
  name: storeName,
  initialState,
  actions,
});

const useChatActions = createActionsHook(store);
const useChatState = createStateHook(store);
const useChat = createHook(store);

const ChatContainer: ContainerComponent<{
  initialState?: ChatState;
}> = createContainer(store, {
  onInit:
    () =>
    ({ setState }, { initialState: initialStateFromProps }) => {
      const newState = {
        ...initialState,
        ...(initialStateFromProps ?? {}),
      };

      setState(newState);
    },
});

export { useChatActions, useChatState, useChat, ChatContainer };
