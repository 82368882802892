import { merge } from 'lodash';
import { config } from 'shared/common/config';

import { LocalStorageKey } from 'consts';

interface VersionedLocalStorage {
  getItem: <T>(config: { key: LocalStorageKey; variant?: string }) => T | null;
  setItem: (config: {
    value: unknown;
    key: LocalStorageKey;
    variant?: string;
  }) => void;
}

export const versionedLocalStorageFactory = (deps: {
  appVersion: string;
}): VersionedLocalStorage => {
  const versionedLocalStorage: VersionedLocalStorage = {
    getItem: (config) => {
      const { key, variant } = config;

      const parsedItem = getParsedItem(key);

      if (!parsedItem) return null;

      const hasItemVariant = !!parsedItem.variants[variant ?? 'default'];

      if (!hasItemVariant) return null;

      return parsedItem.variants[variant ?? 'default'];
    },
    setItem: (config) => {
      const { value, key, variant } = config;

      let parsedItem = getParsedItem(key);

      const newItem = {
        version: deps.appVersion,
        variants: {
          [variant ?? 'default']: value,
        },
      };

      if (!parsedItem) {
        localStorage.setItem(key, JSON.stringify(newItem));
      } else {
        localStorage.setItem(key, JSON.stringify(merge(parsedItem, newItem)));
      }
    },
  };

  const getParsedItem = (key: LocalStorageKey) => {
    const item = localStorage.getItem(key);

    if (!item) return null;

    let parsedItem: any;

    try {
      parsedItem = JSON.parse(item);
    } catch {
      return null;
    }

    const isItemValid =
      typeof parsedItem === 'object' &&
      (parsedItem as object).hasOwnProperty('version') &&
      (parsedItem as object).hasOwnProperty('variants') &&
      typeof parsedItem.variants === 'object';

    const isItemCurrent = isItemValid && parsedItem.version === deps.appVersion;

    if (!isItemCurrent) return null;

    return parsedItem;
  };

  return versionedLocalStorage;
};

export const versionedLocalStorage = versionedLocalStorageFactory({
  appVersion: config.getAppVersion(),
});
