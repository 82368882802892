import { ReadingName } from 'domain/domain.models';

/**
 * Get icon type for displaying any trend, those icon types can then be matched with different icons at implementation
 */
export const getTrendIconType = ({
  previousValue,
  currentValue,
}: {
  previousValue?: number;
  currentValue: number;
  readingName: ReadingName;
}) => {
  if (previousValue === undefined) {
    return 'minus';
  }

  if (currentValue === previousValue) {
    return 'minus';
  }

  if (currentValue > previousValue) {
    return 'arrowUp';
  }

  //currentValue < previousValue
  return 'arrowDown';
};
