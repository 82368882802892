import { getFormattedPercentages } from 'shared/common/utils';

/**
 * @param value 0.0 - 1.0
 */
export const formatTrendPercentage = (value: number) => {
  const formattedPercentage = getFormattedPercentages(value, {
    decimals: 1,
    trailingZeros: false,
  });
  const prefix = value > 0 ? '+' : '';
  return `${prefix}${formattedPercentage}`;
};
