import { SyncGkrState } from '../consts';

export const getGkrSyncState = ({
  isGkrUpdatePossible,
  isGkrRemovalPossible,
}: {
  isGkrUpdatePossible: boolean;
  isGkrRemovalPossible: boolean;
}): SyncGkrState => {
  if (!isGkrUpdatePossible) return SyncGkrState.NO_SYNC;

  if (isGkrRemovalPossible) return SyncGkrState.WAIT;

  return SyncGkrState.POLL;
};
