import i18next from 'i18next';
import isNumber from 'lodash/isNumber';
import replace from 'lodash/replace';
import { getFormattedPercentages } from 'shared/common/utils';
import { getTimeInRangeStateLabel } from 'shared/reading/utils';

import { ReadingName } from 'domain/domain.models';
import { getDomainConfigs } from 'domain/utils/get-domain-configs/get-domain-configs';

import { TimeInRangeState } from '../../types/time-in-range-state';

export const getTimeInRangeTrendTooltipContent = ({
  currentPercentage,
  previousPercentage,
  timeInRangeState,
  readingName,
}: {
  currentPercentage?: number;
  previousPercentage?: number;
  timeInRangeState: TimeInRangeState;
  readingName: ReadingName;
}) => {
  const { label: getReadingNameLabel } = getDomainConfigs(readingName);

  const timeInRangeStateLabel = getTimeInRangeStateLabel({ timeInRangeState });
  const formattedPreviousPercentage = isNumber(previousPercentage)
    ? getFormattedPercentages(previousPercentage, {
        decimals: 1,
        trailingZeros: false,
      })
    : '';

  if (currentPercentage === undefined) {
    return removeExcessWhitespace(
      i18next.t(
        `There were no {{ readingNameLabel }} readings during selected time period.`,
        {
          readingNameLabel: getReadingNameLabel(),
        }
      )
    );
  }

  const formattedCurrentPercentage = getFormattedPercentages(
    currentPercentage,
    {
      decimals: 1,
      trailingZeros: false,
    }
  );

  if (previousPercentage === undefined) {
    return removeExcessWhitespace(
      i18next.t(
        '{{ formattedCurrentPercentage }} of {{readingNameLabel}} readings were {{ timeInRangeStateLabel }} during selected time period. Trend could not be calculated, as there was no data during previous time period to compare it to.',
        {
          readingNameLabel: getReadingNameLabel(),
          formattedCurrentPercentage,
          timeInRangeStateLabel,
        }
      )
    );
  }

  if (currentPercentage === previousPercentage) {
    return removeExcessWhitespace(
      i18next.t(
        '{{ formattedCurrentPercentage }} of {{readingNameLabel}} readings were {{ timeInRangeStateLabel }} during selected time period. This has not changed compared to {{ formattedPreviousPercentage }} during previous time period.',
        {
          readingNameLabel: getReadingNameLabel(),
          formattedCurrentPercentage,
          formattedPreviousPercentage,
          timeInRangeStateLabel,
        }
      )
    );
  }

  if (currentPercentage > previousPercentage) {
    return removeExcessWhitespace(
      i18next.t(
        '{{ formattedCurrentPercentage }} of {{readingNameLabel}} readings were {{ timeInRangeStateLabel }} during selected time period. This is an increase compared to {{ formattedPreviousPercentage }} during previous time period.',
        {
          readingNameLabel: getReadingNameLabel(),
          formattedCurrentPercentage,
          timeInRangeStateLabel,
          formattedPreviousPercentage,
        }
      )
    );
  }

  // currentPercentage < previousPercentage
  return removeExcessWhitespace(
    i18next.t(
      '{{ formattedCurrentPercentage }} of {{readingNameLabel}} readings were {{ timeInRangeStateLabel }} during selected time period. This is a decrease compared to {{ formattedPreviousPercentage }} during previous time period.',
      {
        readingNameLabel: getReadingNameLabel(),
        formattedCurrentPercentage,
        timeInRangeStateLabel,
        formattedPreviousPercentage,
      }
    )
  );
};

const removeExcessWhitespace = (value: string) =>
  replace(value.trim(), /\s+/g, ' ');
