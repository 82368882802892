import i18next from 'i18next';
import { DateRangeFilterState } from 'shared/common/store';
import { DateRangePeriod } from 'shared/common/types';

import { formatDateRange } from '../format-date-range/format-date-range';
import { getPastTimeRange } from '../get-past-time-range/get-past-time-range';

export const formatTrendDateRange = (dateRangeState: DateRangeFilterState) => {
  if (dateRangeState.period === DateRangePeriod.ALL_TIME) {
    return i18next.t('All Time');
  }
  const { pastStartDate, pastEndDate } = getPastTimeRange(dateRangeState);

  return `${formatDateRange({
    startDate: dateRangeState.startDate,
    endDate: dateRangeState.endDate,
  })} ${i18next.t('vs')} ${formatDateRange({
    startDate: pastStartDate,
    endDate: pastEndDate,
  })}`;
};
