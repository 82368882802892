import i18next from 'i18next';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import {
  getFormattedUnit,
  getFormattedValue,
  getRequiredValidationMsg,
} from 'shared/common/utils';
import * as yup from 'yup';

import { ReadingName } from 'domain/domain.models';
import { getBoundaryRange } from 'domain/domain.utils';
import { Unit } from 'models';

/*
  Factory for producing schemas for validating wether reading name value is in range
*/
export const rangeValueSchemaFactory = (
  name: string,
  readingName: ReadingName,
  unit?: string
) =>
  rangeValueSchema().test(name, '', (value, context) =>
    getValueInRangeValidation({
      readingName,
      unit: unit ?? context.parent.unit,
      value,
      createError: context.createError,
    })
  );

export const throwIfMaxLowerThanMin = (max?: number, min?: number): boolean =>
  !(isNumber(max) && isNumber(min) && max <= min);

const rangeValueSchema = () =>
  yup
    .number()
    .required(getRequiredValidationMsg())
    .transform((_, originalValue) =>
      (typeof originalValue === 'string' && originalValue.trim() === '') ||
      isNaN(originalValue)
        ? undefined
        : Number(originalValue)
    );

const getValueInRangeValidation = ({
  readingName,
  unit,
  value,
  createError,
}: {
  readingName: ReadingName;
  unit: Unit;
  value?: number;
  createError: (params: { message: string }) => yup.ValidationError;
}) => {
  const boundaryRange = getBoundaryRange(readingName, unit);

  const validationError = createError({
    message: i18next.t(
      'Value must be between {{min}} {{unit}} and {{max}} {{unit}}',
      {
        min: getFormattedValue({
          value: boundaryRange[0],
          unit,
          readingName,
        }),
        max: getFormattedValue({
          value: boundaryRange[1],
          unit,
          readingName,
        }),
        unit: getFormattedUnit(unit),
      }
    ),
  });

  const isValueValid = isValueInRange(boundaryRange, value);

  return isValueValid || validationError;
};

export const isValueInRange = (
  range: [number, number],
  value?: number | null
): boolean => {
  if (value === null || value === undefined) return false;

  return value >= range[0] && value <= range[1];
};
