import { FC } from 'react';
import clsx from 'clsx';

interface NotificationProps {
  heading?: string;
  type?: keyof typeof colorMap;
}

export const Notification: FC<NotificationProps> = ({
  heading,
  children,
  type = 'default',
}) => (
  <div
    className={clsx(colorMap[type], 'rounded-sm border-l-4 p-4')}
    role="alert"
  >
    {heading && <p className="font-bold">{heading}</p>}
    {children}
  </div>
);

const colorMap = {
  default:
    'bg-ketoneBlue-100 border-ketoneBlue text-ketoneBlue-700 dark:bg-ketoneBlue-800 dark:border-ketoneBlue dark:text-ketoneBlue-100',
  warning:
    'bg-orange-100 border-orange-500 text-orange-700 dark:bg-orange-800 dark:border-orange-500 dark:text-orange-100',
  danger:
    'bg-red-100 border-red-500 text-red-700 dark:bg-red-800 dark:border-red-500 dark:text-red-100',
};
