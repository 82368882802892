import React, { forwardRef } from 'react';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';

export interface CheckboxProps
  extends React.InputHTMLAttributes<
    Omit<HTMLInputElement, 'checked' | 'disabled'>
  > {
  isChecked: boolean;
  isDisabled?: boolean;
  indeterminate?: boolean;
  isError?: boolean;
  label?: string;
  'data-testid'?: string;
  className?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      isChecked,
      isDisabled = false,
      isError = false,
      onChange = () => {},
      className,
      indeterminate,
      ...props
    },
    ref
  ) => (
    <div className={clsx(styles.container, className)}>
      <label className={styles.checkboxLabel}>
        <span className="order-2">{label ?? null}</span>
        <input
          ref={ref}
          aria-invalid={isError}
          type="checkbox"
          disabled={isDisabled}
          checked={isChecked}
          onChange={onChange}
          className={clsx(styles.checkbox, {
            [styles.isInvalid]: isError,
          })}
          data-testid={props['data-testid']}
          {...props}
        />
      </label>
    </div>
  )
);
