import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Tooltip } from 'shared/common/components';
import { MIN_MEDIA_QUERIES, useWatchMediaQuery } from 'shared/common/hooks';

export const MenuDotsTrigger = ({ showLabel }: { showLabel?: boolean }) => {
  const { t } = useTranslation();
  const isMobile = !useWatchMediaQuery(MIN_MEDIA_QUERIES.LG);

  const isLabel = showLabel ?? isMobile;
  return (
    <Menu.Button as="button">
      <Tooltip
        content={t('More')}
        onHover
        as="span"
        classes={{ button: 'flex items-center space-x-1 font-medium' }}
      >
        <DotsVerticalIcon className="h-5 w-5" />
        {isLabel && <span>{t('More')}</span>}
      </Tooltip>
    </Menu.Button>
  );
};
