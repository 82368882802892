import i18next from 'i18next';

import { TimeInRangeState } from '../../types/time-in-range-state';

export const getTimeInRangeStateLabel = ({
  timeInRangeState,
}: {
  timeInRangeState: TimeInRangeState;
}) => {
  const map = {
    [TimeInRangeState.IN_RANGE]: i18next.t('within target range'),
    [TimeInRangeState.ABOVE_RANGE]: i18next.t('above target range'),
    [TimeInRangeState.BELOW_RANGE]: i18next.t('below target range'),
  };

  return map[timeInRangeState];
};
