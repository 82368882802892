import { useTranslation } from 'react-i18next';
import { TopBarOverlay } from 'shared/common/components';

export const LogrocketDebugModeOverlay: React.FC<{
  onDisableDebugMode: () => void;
}> = ({ children, onDisableDebugMode }) => {
  const { t } = useTranslation();
  return (
    <TopBarOverlay
      topBarContent={
        <p className="text-sm text-gray-700 dark:text-gray-200">
          {t(
            'Your session is being recorded. You can exit debug mode by clicking'
          )}{' '}
          <button
            className="inline-block underline"
            onClick={onDisableDebugMode}
          >
            {t('here')}
          </button>
        </p>
      }
    >
      {children}
    </TopBarOverlay>
  );
};
