import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { ReadingName } from 'domain/domain.models';

import { getDetailedReadingsLink } from './get-detailed-readings-link';

interface LinksState {
  getDetailedReadingsLink: (
    readingName: ReadingName,
    practiceId?: string,
    patientUserId?: string
  ) => string;
  getDashBoardReportLink: (practiceId?: string, userId?: string) => string;
  getDashBoardReadingsLink: (practiceId?: string, userId?: string) => string;
  getDashBoardGraphLink: (practiceId?: string, userId?: string) => string;
}

const LinksContext = createContext<LinksState | undefined>(undefined);

interface LinksProviderProps {
  children: ReactNode;
  detailedReadingsBaseRoutes: {
    readingNameDetails: (
      practiceId?: string,
      userId?: string,
      slug?: string
    ) => string;
    macrosDetails: (practiceId?: string, userId?: string) => string;
    bloodPressureDetails: (practiceId?: string, userId?: string) => string;
  };
  getDashBoardReportLink: (practiceId?: string, userId?: string) => string;
  getDashBoardReadingsLink: (practiceId?: string, userId?: string) => string;
  getDashBoardGraphLink: (practiceId?: string, userId?: string) => string;
  practiceId?: string;
  patientUserId?: string;
}

export const LinksProvider: React.FC<LinksProviderProps> = ({
  children,
  detailedReadingsBaseRoutes,
  getDashBoardReportLink,
  getDashBoardReadingsLink,
  getDashBoardGraphLink,
  practiceId,
  patientUserId,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const detailedReadingsLinkGetter = useCallback(
    getDetailedReadingsLink({
      baseRoutes: detailedReadingsBaseRoutes,
      practiceId,
      patientUserId,
    }),
    [patientUserId, practiceId]
  );

  const state = useMemo(
    () => ({
      getDetailedReadingsLink: detailedReadingsLinkGetter,
      getDashBoardReportLink,
      getDashBoardReadingsLink,
      getDashBoardGraphLink,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailedReadingsLinkGetter]
  );

  return (
    <LinksContext.Provider value={state}>{children}</LinksContext.Provider>
  );
};

export const useLinks = () => {
  const context = useContext(LinksContext);
  if (context === undefined) {
    throw new Error('useLinks must be used within a LinksProvider');
  }
  return context;
};
