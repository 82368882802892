import { FC } from 'react';
import clsx from 'clsx';

interface ModalFooterButtonsProps {
  className?: string;
}

export const ModalFooterButtons: FC<ModalFooterButtonsProps> = ({
  children,
  className,
}) => (
  <div className={clsx('grid grid-cols-2 gap-x-4', className)}>{children}</div>
);
