import { Chart as ReactChart } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DateFormat } from 'shared/common/utils';

import LineChartWithVerticalLine from 'components/charts/line-chart-with-vertical-line/LineChartWithVerticalLine';

import 'chartjs-adapter-date-fns';

Chart.defaults.font = {
  family: 'Inter',
  size: 13,
  lineHeight: 16.94 / 14,
  style: 'normal',
  weight: 'normal',
} as const;

(Chart.defaults.scale as any).time = (Chart.defaults.scale as any).time || {};
(Chart.defaults.scale as any).time.isoWeekday = true;
(Chart.defaults.scale as any).time.displayFormats = {
  hour: DateFormat.LOCALIZED_TIME,
  day: DateFormat.LOCALIZED_DATE,
  week: DateFormat.WEEK_YEAR,
  month: DateFormat.MONTH_YEAR,
  quarter: DateFormat.MONTH_YEAR,
  year: DateFormat.YEAR,
};

export const initializeChartJsPlugins = () => {
  ReactChart.register(annotationPlugin);
  Chart.register(LineChartWithVerticalLine);
};
