import React, { createContext, ReactNode, useContext } from 'react';

const TimezoneContext = createContext<string | undefined>(undefined);

interface TimezoneProviderProps {
  children: ReactNode;
  timezone: string;
}

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({
  children,
  timezone,
}) => (
  <TimezoneContext.Provider value={timezone}>
    {children}
  </TimezoneContext.Provider>
);

export const useTimezone = () => {
  const context = useContext(TimezoneContext);
  if (context === undefined) {
    throw new Error('useTimezone must be used within a TimezoneProvider');
  }
  return context;
};
