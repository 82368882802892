import {
  addDays,
  endOfDay,
  intervalToDuration,
  setMilliseconds,
  setSeconds,
  startOfDay,
  subDays,
  subHours,
  subMonths,
  subWeeks,
} from 'date-fns';

import { DateRangePeriod, StaticDateRangePeriodsType } from '../../types';

export const getDateRangeConfig = (period: StaticDateRangePeriodsType) => {
  const today = setSeconds(setMilliseconds(new Date(), 0), 0);
  let startDate = today;
  let endDate = today;

  if (period === DateRangePeriod.HOURS_1) {
    startDate = subHours(today, 1);
  }

  if (period === DateRangePeriod.HOURS_3) {
    startDate = subHours(today, 3);
  }

  if (period === DateRangePeriod.HOURS_6) {
    startDate = subHours(today, 6);
  }

  if (period === DateRangePeriod.HOURS_12) {
    startDate = subHours(today, 12);
  }

  if (period === DateRangePeriod.DAILY) {
    startDate = startOfDay(addDays(subDays(today, 1), 1));
    endDate = endOfDay(today);
  }

  if (period === DateRangePeriod.DAYS_7) {
    startDate = startOfDay(addDays(subDays(today, 7), 1));
    endDate = endOfDay(today);
  }

  if (period === DateRangePeriod.DAYS_28) {
    startDate = startOfDay(addDays(subDays(today, 28), 1));
    endDate = endOfDay(today);
  }

  if (period === DateRangePeriod.WEEKS_12) {
    startDate = startOfDay(addDays(subWeeks(today, 12), 1));
    endDate = endOfDay(today);
  }

  if (period === DateRangePeriod.MONTHS_12) {
    startDate = startOfDay(addDays(subMonths(today, 12), 1));
    endDate = endOfDay(today);
  }

  return {
    duration: intervalToDuration({
      start: startDate,
      end: endDate,
    }),
    startDate,
    endDate,
    period,
  };
};
