import {
  LocalStorageKey,
  VersionedLocalStorageKey,
} from 'consts/local-storage-key';

import { getItem, removeItem, setItem } from './shared-methods';

export const localStorage = {
  getItem: <T>(key: LocalStorageKey | VersionedLocalStorageKey): T | null =>
    getItem(window.localStorage, key),
  setItem(
    key: LocalStorageKey | VersionedLocalStorageKey,
    value: unknown
  ): void {
    setItem(window.localStorage, key, value);
  },
  removeItem(key: LocalStorageKey | VersionedLocalStorageKey): void {
    removeItem(window.localStorage, key);
  },
  clear(): void {
    window.localStorage.clear();
  },
};
