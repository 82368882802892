import { FC } from 'react';
import { Dialog } from '@headlessui/react';
import clsx from 'clsx';

interface ModalDescriptionProps {
  className?: string;
}

export const ModalDescription: FC<ModalDescriptionProps> = ({
  children,
  className,
}) => (
  <Dialog.Description
    className={clsx(
      'text-lg font-normal leading-5 text-gray-500 dark:text-gray-400',
      className
    )}
  >
    {children}
  </Dialog.Description>
);
