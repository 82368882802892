import { useQuery } from 'react-query';

import { practiceApi } from '../api';
import { practiceQueryKeys } from '../consts';
import { mapToPractice } from '../utils/map-to-practice';

const userPracticesQueryFactory =
  (dependancies: { practiceApi: typeof practiceApi }) =>
  ({ enabled }: { enabled?: boolean } = {}) => {
    const { practiceApi } = dependancies;

    const query = useQuery(
      practiceQueryKeys.list({ getMyParentPractices: true }),
      () => practiceApi.getPractices({ getMyParentPractices: true }),
      {
        enabled,
      }
    );

    return {
      ...query,
      data: query.data ? query.data.map(mapToPractice) : [],
    };
  };

/**
 * Practices where logged in user is patient
 */
export const useUserPracticesQuery = userPracticesQueryFactory({
  practiceApi,
});
