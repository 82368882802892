import { useEffect, useState } from 'react';

// Custom hook for creating a tooltip container
export const useTooltipContainer = () => {
  const [tooltipContainer] = useState(() => document.createElement('div'));
  useEffect(() => {
    document.body.appendChild(tooltipContainer);
    return () => {
      document.body.removeChild(tooltipContainer);
    };
  }, [tooltipContainer]);
  return tooltipContainer;
};

// Custom hook for closing tooltip when clicked outside
export const useOutsideClick = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  refs: (Node | null)[]
) => {
  useEffect(() => {
    const handleOutsideOptionsClick = (e: MouseEvent) => {
      if (refs.some((ref) => ref?.contains(e.target as Node))) return;
      setOpen(false);
    };

    window.addEventListener('mousedown', handleOutsideOptionsClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideOptionsClick);
    };
  }, [refs, setOpen]);
};
