import { format, isSameDay, isToday, isYesterday } from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';
import i18next from 'i18next';

import { DateFormat, WEEK_START_DAY } from '../date-utils/format-date';
import { getLocalTimezone } from '../get-local-timezone/get-local-timezone';
import { getUserLocale } from '../get-user-locale/get-user-locale';

export const formatDateRange = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const locale = getUserLocale();
  const formatOptions: Parameters<typeof format>[2] = {
    locale,
    weekStartsOn: WEEK_START_DAY,
  };

  const formattedStartDate = isToday(startDate)
    ? i18next.t('Today')
    : isYesterday(startDate)
    ? i18next.t('Yesterday')
    : format(startDate, DateFormat.LOCALIZED_DATE, formatOptions);

  const formattedEndDate = isToday(endDate)
    ? i18next.t('Today')
    : isYesterday(endDate)
    ? i18next.t('Yesterday')
    : format(endDate, DateFormat.LOCALIZED_DATE, formatOptions);

  if (isSameDay(startDate, endDate)) {
    return `${formattedStartDate} ${format(
      startDate,
      DateFormat.LOCALIZED_TIME,
      formatOptions
    )} - ${format(endDate, DateFormat.LOCALIZED_TIME, formatOptions)}`;
  }

  return `${formattedStartDate} ${format(
    startDate,
    DateFormat.LOCALIZED_TIME,
    formatOptions
  )} - ${formattedEndDate} ${format(
    endDate,
    DateFormat.LOCALIZED_TIME,
    formatOptions
  )}`;
};

export const formatZonedDateRange =
  (timeZone: string) =>
  ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    const locale = getUserLocale();
    const localTz = getLocalTimezone();

    const timezoneInfo = timeZone !== localTz ? ' OO' : '';

    const formatOptions: Parameters<typeof tzFormat>[2] = {
      locale,
      timeZone,
      weekStartsOn: WEEK_START_DAY,
    };

    const zonedStarDate = utcToZonedTime(startDate, timeZone);
    const zonedEndDate = utcToZonedTime(endDate, timeZone);

    const formattedStartDate = isToday(zonedStarDate)
      ? i18next.t('Today')
      : isYesterday(zonedStarDate)
      ? i18next.t('Yesterday')
      : tzFormat(zonedStarDate, DateFormat.LOCALIZED_DATE, formatOptions);

    const formattedEndDate = isToday(zonedEndDate)
      ? i18next.t('Today')
      : isYesterday(zonedEndDate)
      ? i18next.t('Yesterday')
      : tzFormat(zonedEndDate, DateFormat.LOCALIZED_DATE, formatOptions);

    if (isSameDay(zonedStarDate, zonedEndDate)) {
      return `${formattedStartDate} ${tzFormat(
        startDate,
        DateFormat.LOCALIZED_TIME,
        formatOptions
      )} - ${tzFormat(
        endDate,
        DateFormat.LOCALIZED_TIME + timezoneInfo,
        formatOptions
      )}`;
    }

    return `${formattedStartDate} ${tzFormat(
      startDate,
      DateFormat.LOCALIZED_TIME + timezoneInfo,
      formatOptions
    )} - ${formattedEndDate} ${tzFormat(
      endDate,
      DateFormat.LOCALIZED_TIME + timezoneInfo,
      formatOptions
    )}`;
  };
