export const userApiRoutes = {
  dexcomCredentials: 'users/dexcom/credentials',
  dexcomSync: 'users/dexcom/sync',
  partnerCredentials: (partnerName: string) =>
    `users/${partnerName}/credentials`,
  partnerSync: (partnerName: string) => `users/${partnerName}/sync`,
  partnerRevokeAccess: (partnerId: string) =>
    `partners/${partnerId}/oauth2_client_connection`,
  users: '/users',
  user: '/users/:id',
  oAuthScopes: '/oauth2client/:clientId',
  oAuthRevoke: '/oauth/revoke',
  oAuthToken: '/oauth/token',
} as const;
