import { AxiosError } from 'axios';
import { initializeLogRocket } from 'shared/common/services/logrocket';
import { ErrorResponseDto } from 'shared/common/types';
import {
  logrocketLogger,
  sessionStorage,
  SessionStorageKeys,
} from 'shared/common/utils';

import { rollbar } from 'app/initialization/initialize-rollbar';

export const errorLoggerInterceptor = async (
  error: AxiosError<ErrorResponseDto>
) => {
  rollbar.error(error);

  if (
    !error.response ||
    error.response?.status === 401 ||
    error.response?.status === 429
  ) {
    throw error;
  }

  initializeLogRocket();

  logrocketLogger.error(error, {
    extra: {
      source: 'interceptor',
      data: JSON.stringify(error.response?.data),
    },
  });

  sessionStorage.setItem(
    SessionStorageKeys.IS_LOG_ROCKET_SESSION_RECORDED,
    true
  );

  throw error;
};
