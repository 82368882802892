import { useRouteMatch } from 'react-router';

/**
 * Uses useRouteMatch to return value also outsie <Route />
 * */
export const useCurrentPracticeId = (): string | undefined => {
  let match = useRouteMatch<{ practiceId: string }>(
    '*/practices/:practiceId/*'
  );
  return match?.params.practiceId;
};
