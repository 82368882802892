import { ErrorBoundary } from 'react-error-boundary';
import { initializeLogRocket } from 'shared/common/services/logrocket';
import { logrocketLogger, SessionStorageKeys } from 'shared/common/utils';

import { rollbar } from 'app/initialization';
import { ErrorFallbackGlobal } from 'components/ErrorFallback/ErrorFallbackGlobal';

export const AppErroBoundary: React.FC = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={ErrorFallbackGlobal}
    onError={(error) => {
      rollbar.error(error);

      initializeLogRocket();
      logrocketLogger.error(error, {
        extra: {
          source: 'error_boundary',
        },
      });
      sessionStorage.setItem(
        SessionStorageKeys.IS_LOG_ROCKET_SESSION_RECORDED,
        'true'
      );
    }}
  >
    {children}
  </ErrorBoundary>
);
