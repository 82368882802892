import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { config } from 'shared/common/config';
import useLocalStorageState from 'use-local-storage-state';

import { LocalStorageKey } from 'consts';

const analyticsGtagId = config.getGaMeasurementId();

export const Analytics = () => {
  const [thirdPartyCookieConsent] = useLocalStorageState(
    LocalStorageKey.COOKIE_CONSENT
  );

  useEffect(() => {
    // disable analytics unless user has agreed to it
    (window as any)[`ga-disable-${analyticsGtagId}`] = !thirdPartyCookieConsent;
  }, [thirdPartyCookieConsent]);

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${analyticsGtagId}`}
      />
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
      
            gtag('config', '${analyticsGtagId}', {
              send_page_view: false
             });
          `}
      </script>
    </Helmet>
  );
};
